import { routes } from 'config/routes'
import { List, TableColumnProps, Modal, Drawer, message, Form, Select, Spin, Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/page-header'
import {
   Button,
   CardBody,
   CardHeader,
   Chip,
   Card,
   Checkbox,
   SelectItem,
   Input,
   Popover,
   Tooltip,
   Snippet
} from '@nextui-org/react'
import { Link, useNavigate } from 'react-router-dom'
import useFetch from 'hooks/use-fetch'
import { useEffect, useState } from 'react'
import ActionButton from './ActionButton'
import moment from 'moment'
import {
   OFFER_MANAGEMENT_LIST,
   OFFER_MANAGEMENT_LIST_URL,
   OFFER_MANAGEMENT_SHOW,
   OFFER_MANAGEMENT_SHOW_URL,
   OFFER_MANAGEMENT_STORE
} from 'api/offer-management'
import SearchInput from 'components/Input'
import TableComponents from 'components/Table'
import {
   IconBox,
   IconBrandWhatsapp,
   IconDots,
   IconEdit,
   IconFileInvoice,
   IconFilePlus,
   IconMailBolt,
   IconPdf,
   IconPhone,
   IconWebhook
} from '@tabler/icons-react'
import { useDispatch, useSelector } from 'react-redux'
import offer, { setContactId, setOfferNumber, setProduct } from 'features/user/offer'
import { RootState } from 'store'
import { setLoading } from 'features/user/app'
import SuccessModal from 'components/Success'
import { USER_SHOW } from 'api/user'
import { offerAdminStatus } from 'utils/offer'
import UserFilter from 'components/FilterContainer/User'
import Preview from '../offers-management/Preview'
import { Alert } from 'rizzui'
import Pagination from 'components/ui/pagination'
import CardComponents from 'components/CardData'

export default function RequestList({
   customer_id,
   user_id
}: {
   customer_id?: string | number
   user_id?: string | number
}) {
   const { t } = useTranslation()
   const pageHeader = {
      title: 'Talepler Yönetimi',
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.dashboard,
            name: 'Talep Yönetimi'
         }
      ]
   }

   const app = useSelector((state: RootState) => state.app)
   const user = JSON.parse(localStorage.getItem('user') as any)
   const [form] = Form.useForm()
   const navigate = useNavigate()
   const dispatch = useDispatch()
   const [offerManagementQuery, { data, loading }] = useFetch<any>()
   const [getOfferLastIdQuery, { data: lastIdData, loading: lastIdLoading }] = useFetch<any>()
   const [getOffer, { data: offerData, loading: offerLoading }] = useFetch<any>()
   const [getOffersFromOfferGroupQuery, { data: offersFromOfferGroupData, loading: offersFromOfferGroupLoading }] =
      useFetch<any>()
   const [convertOfferToOrderMutation, { data: convertOfferToOrderData, loading: convertOfferToOrderLoading }] =
      useFetch<any>()

   const [usersQuery, { data: usersData, loading: usersLoading }] = useFetch<any>()
   const [groupByAssignedFrom, { data: groupByAssignedFromData, loading: groupByAssignedFromLoading }] = useFetch<any>()

   const [page, setPage] = useState<number>(1)
   const [search, setSearch] = useState<string>('')
   const [offerStatus, setOfferStatus] = useState<any>(null)
   const [offerType, setOfferType] = useState<any>(null)
   const [offerListModal, setOfferListModal] = useState<boolean>(false)
   const [previewItem, setPreviewItem] = useState<any>({})
   const [previewDrawer, setPreviewDrawer] = useState<boolean>(false)
   const [successModal, setSuccessModal] = useState<boolean>(false)
   const [isMobile, setIsMobile] = useState<boolean>(false)

   const onFilter = (values: any) => {
      let object: any = {}

      // page,
      // search,
      // offerStatus,
      // offerType
      object.page = page
      object.customer_id = customer_id ?? null
      object.user_id = user_id ?? null
      object.who_assigned = values?.who_assigned ?? null

      const result = '?' + new URLSearchParams(object).toString()

      //url = '/offers?page=' + page + '&search=' + search + '&offerStatus=' + offerStatus

      let url = 'offers/requests/' + user?.role + result

      //@ts-ignore
      offerManagementQuery(OFFER_MANAGEMENT_STORE, {
         endPoint: url,
         body: values
      })
   }

   const setHide = (id: number) => {
      //@ts-ignore
      offerManagementQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: '/offers/requests/' + user?.role
      })
   }

   const columns: TableColumnProps<any>[] = [
      {
         title: 'Teklif Grup No',
         dataIndex: 'no',
         key: 'no',
         render: (no: any, record) => {
            return <div className="flex items-center gap-3">{record.id}</div>
         }
      },
      {
         title: 'Müşteri',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const parse = JSON.parse(record?.offer?.customer_json ?? '{}')

            let name: any = ''

            if (parse?.name) {
               name = parse?.name
            } else if (record?.offers?.contact?.name) {
               name = record?.offers?.contact?.name
            } else if (record?.offer?.contact?.name) {
               name = record?.offer?.contact?.name
            }

            return <div className="flex items-center gap-3">{name ?? 'Bilinmiyor'}</div>
         }
      },
      {
         title: 'E-posta Adresi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const parse = JSON.parse(record?.offer?.customer_json ?? '{}')

            let email: any = ''

            if (parse?.email) {
               email = parse?.email
            } else if (record?.offers?.contact?.email) {
               email = record?.offers?.contact?.email
            } else if (record?.offer?.contact?.email) {
               email = record?.offer?.contact?.email
            }

            return <div className="flex items-center gap-3">{email ?? 'Bilinmiyor'}</div>
         }
      },
      {
         title: 'Durumu',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const currentStatus = offerAdminStatus(record)
            return (
               <div className="items-center gap-3">
                  {/* <Chip color={record.status === 1 ? 'primary' : 'danger'}>{record.status === 1 ? 'Aktif' : 'Pasif'}</Chip> */}
                  <Chip color={currentStatus.color}>{record.durum}</Chip>
               </div>
            )
         }
      },
      {
         title: 'Talep Kaynağı',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const color = record.is_ordered === 1 ? 'text-green-500' : 'text-red-500'
            const background = record.is_ordered === 1 ? 'bg-green-500' : 'bg-red-100'
            return (
               <div className="items-center gap-3">
                  {/* <Chip color={record.status === 1 ? 'primary' : 'danger'}>{record.status === 1 ? 'Aktif' : 'Pasif'}</Chip> */}
                  {record?.offer_source === 'Web' ? (
                     <div className="flex gap-5 items-center" color="primary">
                        <IconWebhook />
                        Web
                     </div>
                  ) : record?.offer_source === 'Email' ? (
                     <div className="flex gap-5 items-center" color="secondary">
                        <IconMailBolt />
                        E-Posta
                     </div>
                  ) : record?.offer_source === 'Phone' ? (
                     <div className="flex gap-5 items-center" color="warning">
                        <IconPhone />
                        Telefon
                     </div>
                  ) : record?.offer_source === 'Whatsapp' ? (
                     <div className="flex gap-5 items-center" color="success">
                        <IconBrandWhatsapp />
                        Whatsapp
                     </div>
                  ) : (
                     <div className="flex gap-5 items-center" color="secondary">
                        <IconDots />
                        Diğer
                     </div>
                  )}
               </div>
            )
         }
      },
      {
         title: 'Atanan Kullanıcı',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const color = record.is_ordered === 1 ? 'text-green-500' : 'text-red-500'
            const background = record.is_ordered === 1 ? 'bg-green-500' : 'bg-red-100'
            return (
               <div className="items-center gap-3">
                  {/* <Chip color={record.status === 1 ? 'primary' : 'danger'}>{record.status === 1 ? 'Aktif' : 'Pasif'}</Chip> */}
                  <Chip color={record?.is_assigned ? 'success' : 'danger'}>
                     {record?.is_assigned ? record?.user : 'Atanmamış'}
                  </Chip>
               </div>
            )
         }
      },

      {
         title: 'Talep Tarihi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.created_at).format('LL')} */}
                  {moment(record?.created_at).format('LLLL')}
               </div>
            )
         }
      },

      {
         title: t('common.actions'),
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  <ActionButton
                     setHide={setHide}
                     users={usersData}
                     row={record?.offer}
                     isAssigned={record?.is_assigned}
                     editButtonClick={() => {
                        handleGetOffer(record.id)
                     }}
                     viewButtonClick={() => {
                        handleGetOffersFromOfferGroup(record.id)
                     }}
                     deleteButtonClick={() => {}}
                  />

                  <Button
                     color="secondary"
                     variant="shadow"
                     onClick={() => handlePreviewOffer(record?.offer)}
                     startContent={<IconBox />}
                  >
                     Ürünler
                  </Button>
               </div>
            )
         }
      }
   ]

   useEffect(() => {
      //@ts-ignore
      onFilter()
   }, [page])

   const handleGetlastId = () => {
      //@ts-ignore
      getOfferLastIdQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: '/offers/getLastOfferId'
      })
   }

   const handleGetOffer = (id: number) => {
      //@ts-ignore
      getOffer(OFFER_MANAGEMENT_SHOW, {
         endPoint: OFFER_MANAGEMENT_SHOW_URL(id)
      })
   }

   const handlePreviewOffer = (item: any) => {
      setPreviewItem(item)
      setPreviewDrawer(true)
   }

   const handleGetOffersFromOfferGroup = (id: number) => {
      //@ts-ignore
      getOffersFromOfferGroupQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: '/offers/' + id + '/edit'
      })
   }

   useEffect(() => {
      if (lastIdData) {
         dispatch(setOfferNumber(lastIdData.data))
         navigate(routes.offerManagement.add)
      }
   }, [lastIdData])

   useEffect(() => {
      dispatch(
         setLoading(
            (offerLoading as boolean) ||
               (offersFromOfferGroupLoading as boolean) ||
               (convertOfferToOrderLoading as boolean)
         )
      )
   }, [offerLoading, offersFromOfferGroupLoading, convertOfferToOrderLoading])

   useEffect(() => {
      if (window.innerWidth < 1024) {
         setIsMobile(true)
      }
   }, [window.innerWidth])

   useEffect(() => {
      if (offerData) {
         const { data } = offerData
         dispatch(setOfferNumber(data?.no))
         dispatch(setContactId(data?.customer_contact_id))

         const products = data?.products.map((product: any, index: number) => {
            const description = JSON.parse(product.product_json)
            return {
               id: product.product_id,
               name: description.name,
               price: description.price,
               stock: product.product_qty,
               product_qty: description.stock,
               delivery_date: product.product_delivery_date,
               commission: product.product_markup,
               commission_type: product.product_markup_type,
               description: product.product_description,
               services: product.services.map((service: any) => {
                  return {
                     ...service.service,
                     commission: service.service_markup,
                     commission_type: service.service_markup_type,
                     description: service.service_description
                  }
               })
            }
         })
         // console.log(products)
         dispatch(setProduct(products))
         navigate(routes.offerManagement.view(data?.offer_group_id))
      }
   }, [offerData])

   useEffect(() => {
      if (offersFromOfferGroupData) {
         setOfferListModal(true)
      }
   }, [offersFromOfferGroupData])

   useEffect(() => {
      if (convertOfferToOrderData) {
         message.success('Siparişe dönüştürüldü!')
         setOfferListModal(false)
         onFilter(form.getFieldsValue())
         setSuccessModal(true)
      }
   }, [convertOfferToOrderData])

   useEffect(() => {
      //@ts-ignore
      usersQuery(USER_SHOW, {
         endPoint: '/users-all'
      })
      //@ts-ignore
      groupByAssignedFrom(USER_SHOW, {
         endPoint: '/groupByAssignedFrom'
      })
   }, [])

   const RenderDesktop = () => {
      return (
         <TableComponents
            columns={columns}
            data={data?.data?.data || []}
            pagination={{
               total: data?.data?.total,
               current: page,
               pageSize: 20,
               onChange: (page: any) => {
                  setPage(page)
               }
            }}
            //hasDetailPopup
            detailContent={'offer.products'}
            loading={loading}
         />
      )
   }

   const RenderMobile = () => {
      if (loading) {
         return (
            <div className="flex justify-center items-center min-h-screen">
               <Spin size="large" />
            </div>
         )
      }

      if (data?.data?.data?.length === 0) {
         return (
            <div className="text-center">
               <Empty />
               <h5 className="text-gray-500 dark:text-gray-400">Kayıt bulunamadı</h5>
            </div>
         )
      }

      return (
         <div className="space-y-4">
            {data?.data?.data.map((item: any, index: number) => {
               const customerName = item.offer?.contact?.name || item?.offer?.contact?.name || 'Bilinmiyor'
               const email = item.offer?.contact?.email || item?.offer?.contact?.email || 'Bilinmiyor'
               const status = offerAdminStatus(item)
               const offerSource = item?.offer_source || 'Diğer'
               const assignedUser = item.user || 'Atanmamış'
               const createdAt = moment(item?.created_at).format('LLLL')

               return (
                  <div key={index} className="border rounded-lg shadow-sm p-4 bg-white dark:bg-gray-800">
                     <div className="mb-2">
                        <div className="text-sm text-gray-500">Teklif Grup No</div>
                        <div className="font-semibold">{item.no}</div>
                     </div>
                     <div className="mb-2">
                        <div className="text-sm text-gray-500">Müşteri</div>
                        <div className="font-semibold">{customerName}</div>
                     </div>
                     <div className="mb-2">
                        <div className="text-sm text-gray-500">E-posta Adresi</div>
                        <div className="font-semibold">{email}</div>
                     </div>
                     <div className="mb-2">
                        <div className="text-sm text-gray-500">Durumu</div>
                        <div className="font-semibold">
                           <span className={`chip ${status.color}`}>{item.durum}</span>
                        </div>
                     </div>
                     <div className="mb-2">
                        <div className="text-sm text-gray-500">Talep Kaynağı</div>
                        <div className="font-semibold">{offerSource}</div>
                     </div>
                     <div className="mb-2">
                        <div className="text-sm text-gray-500">Atanan Kullanıcı</div>
                        <div className="font-semibold">{assignedUser}</div>
                     </div>
                     <div className="mb-2">
                        <div className="text-sm text-gray-500">Talep Tarihi</div>
                        <div className="font-semibold">{createdAt}</div>
                     </div>
                     <div className="mb-2">
                        <div className="flex  items-center gap-3">
                           <ActionButton
                              isAssigned={data?.is_assigned}
                              setHide={setHide}
                              users={usersData}
                              row={data?.offer}
                              editButtonClick={() => {
                                 handleGetOffer(data.id)
                              }}
                              viewButtonClick={() => {
                                 handleGetOffersFromOfferGroup(data.id)
                              }}
                              deleteButtonClick={() => {}}
                           />

                           <Button
                              color="secondary"
                              variant="shadow"
                              onClick={() => handlePreviewOffer(data?.offers_current_status?.offer)}
                              startContent={<IconBox />}
                           >
                              Ürünler
                           </Button>
                        </div>
                     </div>
                  </div>
               )
            })}

            <Pagination
               total={data?.data?.total}
               current={page}
               pageSize={20}
               onChange={(page: any) => {
                  setPage(page)
               }}
            />
         </div>
      )
   }

   return (
      <>
         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            <div className="mt-4 flex items-center gap-3 @lg:mt-0">
               <Button
                  isLoading={lastIdLoading}
                  onClick={() => handleGetlastId()}
                  startContent={<IconFilePlus />}
                  color="primary"
               ></Button>
            </div>
         </PageHeader>

         <div className="@container">
            <Card
               title="Filter"
               className="mb-5"
               style={{
                  display: user?.role == 'admin' ? 'block' : 'none'
               }}
            >
               <CardHeader>
                  <h3 className="text-lg font-semibold">{t('common.filterTable')}</h3>
               </CardHeader>
               <CardBody className="">
                  <Form
                     className="grid lg:grid-cols-4 gap-5 items-center  w-full"
                     layout="vertical"
                     form={form}
                     onFinish={onFilter}
                  >
                     <Form.Item label="Satışçılar" name="user_ids">
                        <Select
                           placeholder="Satışçılar"
                           mode="multiple"
                           showSearch
                           optionFilterProp="children"
                           filterOption={(input, option: any) =>
                              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           }
                        >
                           {usersData?.result?.map((user: any) => (
                              <Select.Option key={user?.id} value={user?.id}>
                                 {user?.name}
                              </Select.Option>
                           ))}
                        </Select>
                     </Form.Item>

                     <Form.Item label="Teklif Kaynağı" name="offer_source">
                        <Select placeholder="Teklif Kaynağı">
                           <Select.Option value="email">E-Posta</Select.Option>
                           <Select.Option value="web">Web</Select.Option>
                           <Select.Option value="phone">Telefon</Select.Option>
                           <Select.Option value="whatsapp">Whatsapp</Select.Option>
                           <Select.Option value="other">Diğer</Select.Option>
                        </Select>
                     </Form.Item>
                     <Form.Item label="Talebi Kim Atadı" name="who_assigned">
                        <Select placeholder="Talebi Kim Atadı">
                           {groupByAssignedFromData?.data?.map((item: any) => (
                              <Select.Option value={item?.id}>{item?.name}</Select.Option>
                           ))}
                        </Select>
                     </Form.Item>

                     <Form.Item label="Talep Durumu" name="offer_status">
                        <Select placeholder="Talep Durumu">
                           <Select.Option value="1">Atanmış olup teklif bekleyen talepler</Select.Option>
                           <Select.Option value="2">Atanmış olup teklif gönderilen talepler</Select.Option>
                           <Select.Option value="3">Atanmamış Talepler</Select.Option>
                        </Select>
                     </Form.Item>

                     <Button isLoading={loading} type="submit" color="primary" className="">
                        Filtrele
                     </Button>
                  </Form>
               </CardBody>
            </Card>
            {isMobile ? (
               <RenderMobile />
            ) : (
               <Card title={'Teklif Yönetimi'}>
                  <RenderDesktop />
               </Card>
            )}
            <Drawer
               title="Teklif Önizleme"
               placement="bottom"
               height={window.innerHeight}
               onClose={() => setPreviewDrawer(false)}
               open={previewDrawer}
            >
               {previewItem && <Preview item={previewItem} />}
            </Drawer>
         </div>
      </>
   )
}
