import React, { useEffect } from 'react'
import SummaryWidget from './summary-widget'
import TotalAppointment from './total-appointment'
import RecentActivities from './Times'
import useFetch from 'hooks/use-fetch'
import { OFFER_MANAGEMENT_LIST, OFFER_MANAGEMENT_LIST_URL, OFFER_MANAGEMENT_SHOW } from 'api/offer-management'
import { Badge, Title } from 'rizzui'
import { TableColumnProps } from 'antd'
import moment from 'moment'
import TableComponents from 'components/Table'
import { Button, Card, CardBody, CardHeader, Divider, Tooltip } from '@nextui-org/react'
import Lottie from 'react-lottie'
import { getOrderStatusWithClass, getOrderWithAnimation, orderStatusText } from 'utils/get-order-status'
import cn from 'utils/class-names'
import { MoneyFormatTR } from 'hooks/currency'
import PersonalDashboad from './PersonalDashboad'

const Dashboard = () => {
   const [fetchDashboard, { data: dashboardData, loading }] = useFetch<any>()
   const [offerManagementQuery, { data, loading: offerManagementLoading }] = useFetch<any>()

   React.useEffect(() => {
      //@ts-ignore
      fetchDashboard(OFFER_MANAGEMENT_SHOW, {
         endPoint: 'dashboard'
      })
   }, [])

   useEffect(() => {
      //@ts-ignore
      offerManagementQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: OFFER_MANAGEMENT_LIST_URL(1, '') + '&offerType=1'
      })
   }, [])

   const columns: TableColumnProps<any>[] = [
      {
         title: 'Teklif Grup No',
         dataIndex: 'no',
         key: 'no',
         render: (no: any, record) => {
            return <div className="flex items-center gap-3">{record.no}</div>
         }
      },
      {
         title: 'Müşteri',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const parse = JSON.parse(record?.customer_json)
            if (!parse && record?.contact)
               return (
                  <div className="flex items-center gap-3">
                     {record?.contact?.name} ({record.contact?.customer?.company_name})
                  </div>
               )
            return <div className="flex items-center gap-3">{parse?.name}</div>
         }
      },
      {
         title: 'Durumu',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const color = record.is_ordered === 1 ? 'text-green-500' : 'text-red-500'
            const background = record.is_ordered === 1 ? 'bg-green-500' : 'bg-red-100'
            return (
               <div className="items-center gap-3">
                  {/* <Chip color={record.status === 1 ? 'primary' : 'danger'}>{record.status === 1 ? 'Aktif' : 'Pasif'}</Chip> */}
                  <div
                     className={`flex items-center gap-2 rounded-md px-2 py-1 ${background} ${color}`}
                     style={{
                        background: record.is_ordered === 1 ? '#e6ffed' : '#fff5f5'
                     }}
                  >
                     <span>{record.is_ordered === 1 ? 'Siparişe Dönüştürüldü' : 'Bekleniyor'}</span>
                  </div>
               </div>
            )
         }
      },

      {
         title: 'Eklenme Tarihi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.created_at).format('LL')} */}
                  {moment(record?.created_at).format('LL')}
               </div>
            )
         }
      },

      {
         title: 'Ürünler',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.updated_at).format('LL')} */}
                  <Tooltip
                     content={
                        <div className="px-1 py-2">
                           {record?.products.map((product: any, index: any) => {
                              var parse = JSON.parse(product.product_json || '{}')
                              return (
                                 <div key={index} className="flex justify-between gap-10 w-full">
                                    <div className="text-left">{parse?.name}</div>
                                    <div className="text-right">{parse?.price}₺</div>
                                 </div>
                              )
                           })}
                        </div>
                     }
                  >
                     <Button variant="bordered">Ürünler</Button>
                  </Tooltip>
               </div>
            )
         }
      }
   ]

   const ordersColumns: TableColumnProps<any>[] = [
      {
         title: 'Teklif Grup No',
         dataIndex: 'no',
         key: 'no',
         render: (no: any, record) => {
            return <div className="flex items-center gap-3">{record.offer.no}</div>
         }
      },

      {
         title: 'Müşteri seçiniz (Firma)',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {record.contact.name} ({record.contact.customer.company_name}) */}
                  {record?.offer.contact?.name}
               </div>
            )
         }
      },
      {
         title: 'Durumu',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const color = record.is_ordered === 1 ? 'text-green-500' : 'text-red-500'
            const background = record.is_ordered === 1 ? 'bg-green-500' : 'bg-red-100'

            return (
               <div
                  className={cn(
                     getOrderStatusWithClass(record.offer.offer_status),
                     'flex justify-between items-center gap-2'
                  )}
               >
                  <Lottie
                     options={{
                        loop: true,
                        autoplay: true,
                        animationData: getOrderWithAnimation(record.offer.offer_status)
                     }}
                     height={30}
                     width={30}
                  />
                  {orderStatusText[record.offer.offer_status]}
               </div>
            )
         }
      },
      {
         title: 'Sipariş Tarihi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const color = record.is_ordered === 1 ? 'text-green-500' : 'text-red-500'
            const background = record.is_ordered === 1 ? 'bg-green-500' : 'bg-red-100'

            return (
               <div className="flex-col items-center gap-3">
                  {/* <Chip color={record.status === 1 ? 'primary' : 'danger'}>{record.status === 1 ? 'Aktif' : 'Pasif'}</Chip> */}
                  <div
                     className={`flex items-center gap-2 rounded-md px-2 py-1 ${background} ${color}`}
                     style={{
                        background: record.is_ordered === 1 ? '#e6ffed' : '#fff5f5'
                     }}
                  >
                     {record.is_ordered === 1 && <div>{moment(record.ordered_at).format('LL')}</div>}
                  </div>
               </div>
            )
         }
      },
      {
         title: 'Eklenme Tarihi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.created_at).format('LL')} */}
                  {moment(record.offer.created_at).format('LL')}
               </div>
            )
         }
      },
      {
         title: 'Toplam Tutar',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.updated_at).format('LL')} */}
                  {MoneyFormatTR(record.offer.total)}
               </div>
            )
         }
      },
      {
         title: 'Toplam Kâr',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.updated_at).format('LL')} */}
                  {MoneyFormatTR(record.offer.profit)}
               </div>
            )
         }
      },
      {
         title: 'Key Account Manager',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {record.contact.name} ({record.contact.customer.company_name}) */}
                  {record.offer.user?.name}
               </div>
            )
         }
      }
   ]

   const user = JSON.parse(localStorage.getItem('user') || '{}')
   if (user.role !== 'admin') {
      return <PersonalDashboad />
   }
   return (
      <div>
         <div className="grid  lg:grid-cols-2 md:grid-cols-1 gap-6">
            {/* {user?.role == "admin" && (
          <SummaryWidget />
        )} */}
            <SummaryWidget />
            <Card className="">
               <TotalAppointment />
            </Card>
         </div>

         <div>
            <div className="mt-5">
               <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-2">
                  <Card>
                     <CardHeader>
                        <Title as="h3" className="text-lg font-semibold text-gray-900 xl:text-xl 2xl:mb-5">
                           Atanmayı Bekleyen Talepler
                        </Title>
                     </CardHeader>
                     <Divider />
                     <CardBody>
                        <TableComponents
                           columns={columns}
                           data={dashboardData?.waiting_requests ?? []}
                           loading={loading}
                           pagination={false}
                        />
                     </CardBody>
                  </Card>
                  <Card>
                     <CardHeader>
                        <Title as="h3" className="text-lg font-semibold text-gray-900 xl:text-xl 2xl:mb-5">
                           Vadesi Yaklaşan Siparişler
                        </Title>
                     </CardHeader>
                     <Divider />
                     <CardBody>
                        <TableComponents
                           columns={columns
                              .concat({
                                 title: 'Vadenin Dolmasına Kalan Gün',
                                 dataIndex: 'id',
                                 key: 'id',
                                 render: (id: any, record: any) => {
                                    return (
                                       <div className="flex items-center gap-3">
                                          <Badge color="warning">{record.term_time}</Badge>
                                       </div>
                                    )
                                 }
                              })
                              .concat({
                                 title: 'Toplam Tutar',
                                 dataIndex: 'id',
                                 key: 'id',
                                 render: (id: any, record: any) => {
                                    return <div className="flex items-center gap-3">{MoneyFormatTR(record.total)}</div>
                                 }
                              })
                              .concat({
                                 title: 'Toplam Kâr',
                                 dataIndex: 'id',
                                 key: 'id',
                                 render: (id: any, record: any) => {
                                    return <div className="flex items-center gap-3">{MoneyFormatTR(record.profit)}</div>
                                 }
                              })}
                           data={dashboardData?.close_term_offers ?? []}
                           loading={loading}
                           pagination={false}
                        />
                     </CardBody>
                  </Card>
               </div>
               <Card className="mt-5">
                  <CardHeader>
                     <Title as="h3" className="text-lg font-semibold text-gray-900 xl:text-xl 2xl:mb-5">
                        Son Siparişler
                     </Title>
                  </CardHeader>
                  <Divider />
                  <CardBody>
                     <TableComponents
                        columns={ordersColumns}
                        data={data?.data?.data || []}
                        pagination={{
                           total: data?.data?.total,
                           current: 1,
                           pageSize: 10
                        }}
                        loading={loading}
                     />
                  </CardBody>
               </Card>
            </div>
         </div>
      </div>
   )
}

export default Dashboard
