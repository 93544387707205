import { Pagination, Spin } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Loader } from 'rizzui'
import cn from 'utils/class-names'
import Empty from './Empty'
import { Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react'

interface TableProps {
   columns: any
   data: any
   pagination:
   | {
      total: number
      current: number
      pageSize: number
      onChange: any
   }
   | any
   loading: boolean | undefined
   hasDetailPopup?: boolean
   detailContent?: any
}

export default function TableComponents(props: TableProps) {
   const { columns, data, pagination } = props
   const { t } = useTranslation()
   return (
      <div>
         <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 px-10">
            <thead className="text-md text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
               <tr className={''}>
                  {columns.map((column: any, index: any) => {
                     return (
                        <th key={column.uid} className={cn('px-2 py-2 font-semibold')}>
                           <div className={cn('', column.className)}>{column.title}</div>
                        </th>
                     )
                  })}
               </tr>
            </thead>
            <tbody>
               {props.loading && (
                  <tr className="absolute w-full h-full bg-white opacity-50 flex items-center justify-center">
                     <td width={200} height={30} className="text-center bg-white">
                        <Spin />
                     </td>
                  </tr>
               )}
               {data.map((row: any, index: number) => {
                  return (
                     <tr
                        key={index}
                        className="border-b border-gray-100 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
                     >
                        {columns.map((column: any, index: any) => {
                           let products: any = []
                           if (props.hasDetailPopup) {
                              products = row?.offers[0]?.products;
                              return (
                                 <td key={column.uid} className={cn('px-[16px] py-[16px]', column.className)}>
                                    <Popover placement="top">
                                       <PopoverTrigger>

                                          <div className={cn('font-semibold', column.className)}>
                                             {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                                          </div>

                                       </PopoverTrigger>
                                       <PopoverContent>
                                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 px-10">
                                             <thead>
                                                <tr>
                                                   <th className={cn('px-2 py-2 font-semibold')}>
                                                      <div className={cn('', column.className)}>Ürün Adı</div>
                                                   </th>
                                                   <th className={cn('px-2 py-2 font-semibold')}>
                                                      <div className={cn('', column.className)}>Talep Edilen Adet</div>
                                                   </th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                {products?.map((product: any, index: number) => {
                                                   var parse = JSON.parse(product.product_json || '{}');
                                                   return (
                                                      <tr
                                                         key={index}
                                                         className="border-b border-gray-100 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
                                                      >
                                                         <td className={cn('px-[16px] py-[16px]', column.className)}>
                                                            <div className={cn('font-semibold', column.className)}>
                                                               {parse?.name}
                                                            </div>
                                                         </td>
                                                         <td className={cn('px-[16px] py-[16px]', column.className)}>
                                                            {/* {
                                             products.map((product: any, index: any) => {
                                                var parse = JSON.parse(product.product_json || '{}');
                                                return (
                                                   <div key={index} className="flex justify-between gap-10 w-full">
                                                      <div className='text-left'>{parse?.name}</div>
                                                      <div className='text-right'>{parse?.price}₺</div>
                                                   </div>
                                                )
                                             })
                                          } */}
                                                            <div className={cn('font-semibold', column.className)}>
                                                               {parse?.quantity}
                                                            </div>
                                                         </td>
                                                      </tr>
                                                   )
                                                })}
                                             </tbody>
                                          </table>

                                       </PopoverContent>
                                    </Popover>
                                 </td>
                              )
                           }
                           return (
                              <td key={column.uid} className={cn('px-[16px] py-[16px]', column.className)}>
                                 <div className={cn('font-semibold', column.className)}>
                                    {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                                 </div>
                              </td>
                           )
                        })}
                     </tr>
                  )
               })}
               {data.length === 0 && (
                  <tr>
                     <td colSpan={columns.length} className="text-center">
                        <Empty />
                        <h5 className="text-gray-500 dark:text-gray-400">
                           Kayıt bulunamadı
                        </h5>
                     </td>
                  </tr>
               )}
            </tbody>
         </table>
         {props.pagination && (
            <Pagination
               className="mt-4"
               total={pagination.total}
               current={pagination.current}
               pageSize={pagination.pageSize}
               onChange={pagination.onChange}
            />
         )}
      </div>
   )
}
