import React, { useEffect } from 'react'
import { Button, Card, CardBody, CardHeader, Chip, Listbox, ListboxItem, Select, SelectItem } from '@nextui-org/react'
import Sidebar from './Sidebar'
import HamburgerButton from './HamerburgerButton'
import { useIsMounted } from 'hooks/use-is-mounted'
import useWindowScroll from 'react-use/lib/useWindowScroll'
import cn from 'utils/class-names'
import { Link, useNavigate } from 'react-router-dom'

import ProfileMenu from './profile-menu'
import { useDispatch, useSelector } from 'react-redux'
import { MoneyFormat, MoneyFormatTR } from 'hooks/currency'
import pusherJs from 'pusher-js'
import LanguageList from './Language'
import MessagesDropdown from './messages-dropdown'
import { FaCircleQuestion } from 'react-icons/fa6'

import {
   IconBrandWhatsapp,
   IconChevronDown,
   IconDots,
   IconMail,
   IconMessageCircleQuestion,
   IconPhone,
   IconPlus,
   IconQuestionMark,
   IconShoppingBag,
   IconWorld
} from '@tabler/icons-react'
import { PiPlusCircle } from 'react-icons/pi'
import { Avatar, Drawer, Empty, Form, Input, List, Modal, message, notification } from 'antd'
import { ActionIcon, Alert, Badge, EmptyProductBoxIcon, Switch, Title } from 'rizzui'
import useFetch from 'hooks/use-fetch'
import { USER_SHOW, USER_STORE, USER_STORE_URL } from 'api/user'
import { Select as SelectBox } from 'antd'
import { setContactDetail, setProduct } from 'features/user/offer'
import { CUSTOMER_MANAGEMENT_LIST, CUSTOMER_MANAGEMENT_STORE } from 'api/customer-management'
import { RootState } from 'store'
import { removeCart, setCart } from 'features/user/basket'
import { routes } from 'config/routes'
import _ from 'lodash'
import { Popover, PopoverTrigger, PopoverContent } from '@nextui-org/popover'

interface Props {
   toggleSidebar: any
}

function HeaderMenuRight() {
   const carts = useSelector((state: RootState) => state.basket.carts)
   const navigate = useNavigate()
   const [cartDrawer, setCartDrawer] = React.useState(false)
   const user = useSelector((state: any) => state.user)
   const dispatch = useDispatch()
   const [open, setOpen] = React.useState(false)
   const [openDrawer, setOpenDrawer] = React.useState(false)
   const [form] = Form.useForm()
   const [contactForm] = Form.useForm()
   const [contactModal, setContactModal] = React.useState(false)
   const [contactsQuery, { data: contactsData, loading: contactsLoading, error: contactsError }] = useFetch<any>()
   const [usersQuery, { data: usersData, loading, error }] = useFetch<any>()
   const [offerRequestMutation, { data: offerRequestData, loading: offerRequestLoading, error: offerRequestError }] =
      useFetch<any>()
   const [contactMutation, { data: contactData, loading: contactLoading, error: contactError }] = useFetch<any>()
   const [contact_id, setContact_id] = React.useState<any>(null)
   const [sendWithProduct, setSendWithProduct] = React.useState(false)

   const [isMobile, setIsMobile] = React.useState(false)

   useEffect(() => {
      if (window.innerWidth < 1024) {
         setIsMobile(true)
      } else {
         setIsMobile(false)
      }
   }, [window.innerWidth])

   const [gimel, { data: gimelData }] = useFetch<any>()

   useEffect(() => {
      //@ts-ignore
      gimel(USER_SHOW, {
         endPoint: '/total-gimel-stock'
      })
   }, [])

   useEffect(() => {
      //@ts-ignore
      usersQuery(USER_SHOW, {
         endPoint: '/users-with-offer-current-status'
      })
   }, [])

   const handleCreateRequest = (values: any) => {
      console.log('values', values)
      //@ts-ignore8
      offerRequestMutation(USER_STORE, {
         endPoint: '/offer-requests-create',

         body: {
            ...values,
            carts: values.send_with_product ? carts : []
         }
      })
   }

   useEffect(() => {
      if (offerRequestData) {
         notification.success({
            message: 'Başarılı',
            description: 'Talep oluşturuldu.'
         })
         setOpen(false)
      }
   }, [offerRequestData])

   useEffect(() => {
      if (contactData) {
         message.success('Müşteri başarıyla eklendi!')

         setContactModal(false)
         setContact_id(contactData.contact_id)
         //@ts-ignore
         contactsQuery(CUSTOMER_MANAGEMENT_LIST, {
            endPoint: '/customer-with-contacts'
         })
      }
   }, [contactData])

   useEffect(() => {
      //@ts-ignore
      contactsQuery(CUSTOMER_MANAGEMENT_LIST, {
         endPoint: '/customer-with-contacts'
      })
   }, [])

   const handleSubmitContact = (values: any) => {
      console.log('values', values)
      //@ts-ignore
      contactMutation(CUSTOMER_MANAGEMENT_STORE, {
         endPoint: 'customers/addContactOffer',
         body: {
            ...values
         }
      })
   }

   const HeaderAction = ({ className }: any) => (
      <div className={className}>
         <Popover backdrop="opaque" placement="bottom" showArrow={true}>
            <PopoverTrigger>
               <Button isIconOnly color="warning" variant="ghost">
                  <FaCircleQuestion size={20} />
               </Button>
            </PopoverTrigger>
            <PopoverContent>
               <Title as="h4">Uyarılar</Title>
               <div className="w-full w-[260px] border-small px-1 py-2 rounded-small  ">
                  <div>
                     {/* <ListboxItem key="new">New file</ListboxItem>
               <ListboxItem key="copy">Copy link</ListboxItem>
               <ListboxItem key="edit">Edit file</ListboxItem>
               <ListboxItem key="delete" className="text-danger" color="danger">
                  Delete file
               </ListboxItem> */}
                     <div className="p-2 flex items-center gap-2">
                        <div className="w-5 h-5 rounded-full bg-[#c0392b]"></div>
                        <span className="font-medium text-md">Kırmızı - İptal</span>
                     </div>
                     <div className="p-2 flex items-center gap-2 mt-2">
                        <div className="w-5 h-5 rounded-full bg-[#ff9800]"></div>
                        <span className="font-medium text-md">Turuncu - Revize</span>
                     </div>
                     <div className="p-2 flex items-center gap-2 mt-2">
                        <div className="w-5 h-5 rounded-full bg-[#3cb371]"></div>
                        <span className="font-medium text-md">Yeşil - Tamamlanmış</span>
                     </div>
                  </div>
               </div>
            </PopoverContent>
         </Popover>

         <Button
            style={{
               display: JSON.parse(localStorage.getItem('user') as string).role === 'admin' ? 'block' : 'none'
            }}
            color="danger"
            variant="shadow"
            onClick={() => {
               setOpenDrawer(false)
               setOpen(true)
            }}
         >
            Hızlı Teklif Oluştur
         </Button>
         <Button color="default" variant="bordered">
            Toplam Gimel Stoğu <Badge color="success">{gimelData?.data?.stock}</Badge>
         </Button>
         <Button color="default" variant="bordered">
            Toplam Gimel Stok Fiyatı <Badge color="success">{MoneyFormatTR(gimelData?.data?.price)}</Badge>
         </Button>
         <Button
            color="default"
            startContent={<IconShoppingBag size={20} />}
            variant="bordered"
            endContent={<Badge color="success">{carts.length}</Badge>}
            onClick={() => {
               setOpenDrawer(false)
               setCartDrawer(true)
            }}
         >
            Sepet
         </Button>
         <MessagesDropdown />
      </div>
   )

   return (
      <div className="ms-auto flex shrink-0 items-center gap-2 text-gray-700 xs:gap-3 xl:gap-4">
         <Drawer className="" open={cartDrawer} onClose={() => setCartDrawer(false)}>
            {carts.length === 0 && (
               <div className="grid h-full place-content-center">
                  <EmptyProductBoxIcon className="mx-auto h-auto w-52 text-gray-400" />
                  <Title as="h5" className="mt-6 text-center">
                     Sepet Boş!
                  </Title>
               </div>
            )}

            <List
               itemLayout="horizontal"
               dataSource={carts}
               className="p-5"
               renderItem={(item: any, index: number) => (
                  <List.Item
                     actions={[
                        <Button
                           onClick={() => {
                              Modal.confirm({
                                 className: 'z-[10000]',
                                 title: 'Ürünü silmek istediğinize emin misiniz?',
                                 icon: <IconQuestionMark />,
                                 content: 'Bu işlem geri alınamaz',
                                 okText: 'Evet',
                                 okType: 'danger',
                                 cancelText: 'Hayır',
                                 closable: true,
                                 onOk() {
                                    dispatch(removeCart(index))
                                 }
                              })
                           }}
                           color="danger"
                        >
                           Sil
                        </Button>
                     ]}
                  >
                     <List.Item.Meta avatar={<Avatar src={item.thumb} />} title={item.name} description={item.model} />
                  </List.Item>
               )}
            />

            <div className="p-5">
               <Button
                  onClick={() => {
                     const user_type = JSON.parse(localStorage.getItem('user') as string).role
                     if (user_type === 'admin') {
                        setCartDrawer(false)
                        const products = carts.map((product: any) => {
                           let price = product.price.split(' ')[0]
                           price = price.replace(',', '.')
                           price = parseFloat(price).toFixed(2)
                           let productArray = {
                              id: product.product_id,
                              name: product.name,
                              model: product.model,
                              price: price,
                              stock: product.stock,
                              product_qty: product.stock,
                              delivery_date: '',
                              commission: 0,
                              commission_type: '3',
                              description: product.description,
                              services: [],
                              isReserve: false,
                              reserveEndDate: '',
                              supplier: product.supplier
                           }
                           return productArray
                        })
                        dispatch(setProduct(products))
                        form.setFieldValue('send_with_product', true)
                        setSendWithProduct(true)
                        setOpen(true)
                     } else {
                        navigate(routes.offerManagement.add)
                     }
                  }}
                  color="primary"
                  className="w-full"
               >
                  Sepetteki ürünlerle teklif oluştur
               </Button>
            </div>
         </Drawer>
         <Modal open={contactModal} footer={null} onCancel={() => setContactModal(false)} title="Yeni Müşteri Ekle">
            <Form form={contactForm} layout="vertical" onFinish={handleSubmitContact}>
               <Form.Item
                  label="Firma Adı"
                  name="company_name"
                  rules={[{ required: true, message: 'Lütfen firma adı giriniz!' }]}
               >
                  <Input placeholder="Firma adı giriniz" />
               </Form.Item>
               <Form.Item
                  label="İlgili müşteri"
                  name="contact_name"
                  rules={[{ required: true, message: 'Lütfen ilgili müşteri adı giriniz!' }]}
               >
                  <Input placeholder="İlgili müşteri adı giriniz" />
               </Form.Item>
               <Form.Item
                  label="Telefon"
                  name="phone"
                  rules={[{ required: true, message: 'Lütfen telefon numarası giriniz!' }]}
               >
                  <Input placeholder="Telefon numarası giriniz" />
               </Form.Item>
               <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Lütfen email adresi giriniz!' }]}
               >
                  <Input placeholder="Email adresi giriniz" />
               </Form.Item>
               <Button isLoading={contactLoading} type="submit" color="primary">
                  Kaydet
               </Button>
            </Form>
         </Modal>
         <Modal
            width={1000}
            open={open}
            onCancel={() => {
               setOpen(false)
            }}
            footer={null}
         >
            <Title as="h5">Hızlı Talep Oluştur</Title>
            <Form layout="vertical" name="basic" form={form} onFinish={handleCreateRequest}>
               <Alert color="info">
                  <p>
                     Kullanıcıların solundaki numaralar elindeki var olan işlem bekleyen taleplerin sayısını
                     göstermektedir.
                  </p>
               </Alert>
               <Form.Item
                  label="Müşteri seçiniz"
                  name="contact_id"
                  rules={[{ required: true, message: 'Lütfen bir müşteri seçiniz!' }]}
               >
                  <SelectBox
                     onChange={(value: any) => {
                        const find = contactsData?.data?.find((item: any) =>
                           item.contacts.some((contact: any) => contact.id === value)
                        )
                        if (find) {
                           const contactDetail = find.contacts.find((contact: any) => contact.id === value)
                           dispatch(setContactDetail(contactDetail))
                        }
                     }}
                     options={contactsData?.data
                        .filter((item: any) => item.contacts.length > 0)
                        .map((item: any) => {
                           return {
                              label: item.company_name,
                              options: item.contacts.map((contact: any) => {
                                 return {
                                    label: contact.name,
                                    value: contact.id
                                 }
                              })
                           }
                        })}
                     filterOption={(input: any, option: any) =>
                        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option?.options?.some((item: any) => item.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                     }
                     showSearch
                     notFoundContent={
                        <div className="flex flex-col items-center justify-center">
                           <Empty description="Müşteri seçiniz bulunamadı" />
                           <Button color="primary" onClick={() => setContactModal(true)}>
                              Yeni Müşteri Ekle
                           </Button>
                        </div>
                     }
                     placeholder="müşteri seçiniz"
                     suffixIcon={<IconChevronDown />}
                  ></SelectBox>
               </Form.Item>
               <Form.Item
                  label="Atanacak Kullanıcı"
                  name="user_id"
                  rules={[{ required: true, message: 'Lütfen atanacak kullanıcıyı seçiniz!' }]}
               >
                  <Select size="sm" label="Kullanıcı Seç" placeholder="Kullanıcı">
                     {usersData &&
                        usersData?.result
                           ?.sort((a: any, b: any) => a.total - b.total)
                           .map((item: any) => (
                              <SelectItem
                                 startContent={<Badge color="primary">{item.total}</Badge>}
                                 key={item.id}
                                 value={item.id}
                              >
                                 {item?.name}
                              </SelectItem>
                           ))}
                  </Select>
               </Form.Item>

               <Form.Item name="offer_source" label="Talep Kaynağı">
                  <Select size="sm" label="Talep Kaynağı Seç" placeholder="Talep Kaynağı">
                     <SelectItem startContent={<IconWorld size={20} />} key={'web'} value="web">
                        Web
                     </SelectItem>
                     <SelectItem startContent={<IconMail size={20} />} key={'email'} value="email">
                        E-posta
                     </SelectItem>
                     <SelectItem startContent={<IconBrandWhatsapp size={20} />} key={'whatsapp'} value="whatsapp">
                        Whatsapp
                     </SelectItem>
                     <SelectItem startContent={<IconPhone size={20} />} key={'phone'} value="phone">
                        Telefon
                     </SelectItem>
                     <SelectItem startContent={<IconDots size={20} />} key={'other'} value="other">
                        Diğer
                     </SelectItem>
                  </Select>
               </Form.Item>
               <Form.Item name="note" label="Not">
                  <Input.TextArea style={{ height: 100 }} />
               </Form.Item>
               <Form.Item name="send_with_product" valuePropName="checked">
                  <Switch
                     onChange={value => {
                        console.log(value)
                        form.setFieldsValue({ send_with_product: value.target.checked })
                        setSendWithProduct(value.target.checked)
                     }}
                     size="xl"
                     label="Oluşturduğum Sepetle Gönder"
                     labelClassName="font-medium"
                  />
               </Form.Item>
               {sendWithProduct && carts.length > 0 && (
                  <Card className="p-4">
                     <Title as="h5">Sepetteki Ürünler</Title>
                     <List
                        itemLayout="horizontal"
                        dataSource={carts}
                        renderItem={(item: any) => (
                           <List.Item>
                              <List.Item.Meta
                                 avatar={<Avatar src={item.thumb} />}
                                 title={item.name}
                                 description={item.model}
                              />

                              <div className="flex items-center gap-2">
                                 <Chip color="success">{MoneyFormat(item.price, 'TRY')}</Chip>
                                 <input
                                    className="w-20 p-1 border border-gray-300 rounded-md"
                                    type="number"
                                    onChange={e => {
                                       const currentProducts = _.cloneDeep(carts)
                                       const product = currentProducts.find(
                                          (product: any) => product.model === item.model
                                       )
                                       product.stock = e.target.value
                                       dispatch(setCart(currentProducts))
                                    }}
                                    value={item.stock}
                                 />
                              </div>
                           </List.Item>
                        )}
                     />
                  </Card>
               )}

               <Form.Item className="flex gap-5 items-center mt-2">
                  <Button isLoading={offerRequestLoading as boolean} type="submit" color="primary">
                     Talep Oluştur
                  </Button>
                  <Button
                     color="secondary"
                     className="ml-5"
                     onClick={() => {
                        setOpen(false)
                     }}
                  >
                     İptal
                  </Button>
               </Form.Item>
            </Form>
         </Modal>
         {!isMobile && <HeaderAction className="hidden lg:flex xl:flex md:hidden sm:hidden gap-2 items-center" />}
         {isMobile && (
            <div>
               <Drawer
                  open={openDrawer}
                  onClose={() => {
                     setOpenDrawer(false)
                  }}
                  placement="bottom"
               >
                  <HeaderAction className="flex flex-col gap-2" />
               </Drawer>
               <Button onClick={() => setOpenDrawer(true)} color="primary" variant="shadow">
                  <PiPlusCircle size={20} /> Diğer İşlemler
               </Button>
            </div>
         )}
         <ProfileMenu
            buttonClassName="w-auto sm:w-auto p-1 border border-gray-300"
            avatarClassName="!w-7 !h-7 sm:!h-8 sm:!w-8"
         />
      </div>
   )
}

export default function Header() {
   const isMounted = useIsMounted()
   const windowScroll = useWindowScroll()
   const [open, setOpen] = React.useState(false)
   const [openDrawer, setOpenDrawer] = React.useState(false)
   const [foreigns, setForeigns] = React.useState<any>([])

   return (
      <header
         className={cn(
            'sticky top-0 z-50 flex items-center justify-between bg-gray-0/80 px-4 py-4 backdrop-blur-xl dark:bg-gray-50/50 md:px-5 lg:px-6 2xl:py-5 2xl:pl-6 3xl:px-8',
            ((isMounted && windowScroll.y) as number) > 2 ? 'card-shadow' : ''
         )}
      >
         <div className="flex w-full max-w-2xl items-center xl:w-auto">
            <HamburgerButton view={<Sidebar className="static w-full 2xl:w-full" />} />
            <Link aria-label="Site Logo" to={'/'} className="me-4 w-9 shrink-0 text-gray-900 lg:me-5 xl:hidden">
               {/* <Logo iconOnly={true} /> */}
            </Link>
            {/* <SearchWidget
              icon={<PiMagnifyingGlassDuotone className="h-[20px] w-[20px]" />}
              className={cn(
                'text-gray-700 hover:text-gray-900 focus-visible:outline-0 active:translate-y-0 xl:border-0 xl:p-0 xl:shadow-none xl:backdrop-blur-none xl:hover:border-0 xl:hover:outline-0 xl:focus:outline-0 xl:focus-visible:outline-0 [&_.magnifying-glass]:me-0 [&_.placeholder-text]:hidden [&_.search-command]:ms-2 [&_.search-command]:hidden [&_.search-command]:lg:text-gray-0'
              )}
            /> */}
         </div>

         <div className="hidden items-center gap-3 xl:flex">
            <Link
               aria-label="Site Logo"
               to={'/'}
               className="me-4 hidden w-[155px] shrink-0 text-gray-900 lg:me-5 xl:block"
            >
               {/* <Logo className="max-w-[155px]" /> */}
            </Link>
            {/* <HeaderMenusLeft /> */}
         </div>
         <div className="flex w-full items-center gap-5 xl:w-auto 3xl:gap-6">
            <HeaderMenuRight />
         </div>
      </header>
   )
}
