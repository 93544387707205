import { routes } from 'config/routes'
import { Drawer, Form, Modal, TableColumnProps, UploadProps, message, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/page-header'
import { Button, CardBody, CardHeader, Chip, Card, select } from '@nextui-org/react'
import { Link, useNavigate } from 'react-router-dom'
import useFetch from 'hooks/use-fetch'
import { useEffect, useState } from 'react'
import ActionButton from './ActionButton'
import moment from 'moment'
import {
   ADD_CONTACT,
   ADD_CONTACT_URL,
   CUSTOMER_MANAGEMENT_LIST,
   CUSTOMER_MANAGEMENT_LIST_URL,
   EDIT_CONTACT,
   EDIT_CONTACT_URL
} from 'api/customer-management'
import SearchInput from 'components/Input'
import TableComponents from 'components/Table'
import { IconChecklist, IconFilePlus } from '@tabler/icons-react'
import ContactCard from './ContactCard'
import ContactForm from './ContactForm'
import Empty from 'components/Empty'
import { Title } from 'rizzui'
import Dragger from 'antd/es/upload/Dragger'
import { RiFileExcel2Line } from 'react-icons/ri'
import { apiUrl } from 'api'
import axios from 'axios'
import CustomerOffer from './CustomerOffer'
import CustomerProfile from './CustomerProfile'

export default function CustomerManagementList() {
   const { t } = useTranslation()
   const pageHeader = {
      title: 'Müşteri Yönetimi',
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.dashboard,
            name: 'Müşteri Yönetimi'
         }
      ]
   }

   const navigate = useNavigate()

   const [offerManagementQuery, { data, loading }] = useFetch<any>()
   const [addContactMutation, { data: addContactData, loading: addContactLoading, error: addContactError }] =
      useFetch<any>()
   const [editContactMutation, { data: editContactData, loading: editContactLoading }] = useFetch<any>()
   const [seeAllOfferDrawer, setSeeAllOfferDrawer] = useState<boolean>(false)
   const [selectedContactId, setSelectedContactId] = useState<number | null>(null)
   const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(null)

   const [page, setPage] = useState<number>(1)
   const [search, setSearch] = useState<string>('')
   const [selectedRow, setSelectedRow] = useState<any>(null)
   const [selectedContact, setSelectedContact] = useState<any>(null)
   const [openContactsDrawer, setOpenContactsDrawer] = useState<boolean>(false)
   const [addContactDrawer, setAddContactDrawer] = useState<boolean>(false)
   const [editContactDrawer, setEditContactDrawer] = useState<boolean>(false)
   const [addContactForm] = Form.useForm()
   const [editContactForm] = Form.useForm()
   const [excelModal, setExcelModal] = useState<boolean>(false)
   const [customerProfileDrawer, setCustomerProfileDrawer] = useState<boolean>(false)

   const onFilter = () => {
      //@ts-ignore
      offerManagementQuery(CUSTOMER_MANAGEMENT_LIST, {
         endPoint: CUSTOMER_MANAGEMENT_LIST_URL(page, search)
      })
   }

   const addNewCustomer = () => {
      Modal.confirm({
         title: 'Yeni Müşteri Ekle',
         content: 'Nasıl müşteri ekleyeceğinizi seçin',
         okText: 'Manuel Ekle',
         cancelText: 'Excel İle Ekle',
         onOk: () => {
            navigate(routes.customerManagement.add)
         },
         onCancel: () => {
            setExcelModal(true)
         }
      })
   }

   const handleAddContact = async (values: any) => {
      //@ts-ignore
      addContactMutation(ADD_CONTACT, {
         endPoint: ADD_CONTACT_URL(selectedRow.id),
         body: values
      })
   }

   const handleEditContact = async (values: any) => {
      console.log(selectedContact)
      //@ts-ignore
      editContactMutation(EDIT_CONTACT, {
         endPoint: EDIT_CONTACT_URL(selectedRow.id, selectedContact.id),
         body: values
      })
   }

   useEffect(() => {
      if (addContactData) {
         console.log(addContactData)
         if (addContactData.status) {
            message.success('Müşteri seçiniz başarıyla eklendi')
            setAddContactDrawer(false)
            const newContacts = [...selectedRow.contacts, addContactData.data]
            setSelectedRow({ ...selectedRow, contacts: newContacts })
         }
      }
   }, [addContactData])

   useEffect(() => {
      if (editContactData) {
         if (editContactData.status) {
            message.success('Müşteri seçiniz başarıyla güncellendi')
            setEditContactDrawer(false)
            // const newContacts = [...selectedRow.contacts.filter((item: any) => item.id !== editContactData.data.id), editContactData.data];
            // setSelectedRow({ ...selectedRow, contacts: newContacts })

            const contacts = selectedRow.contacts.map((item: any) => {
               if (item.id === editContactData.data.id) {
                  return editContactData.data
               }
               return item
            })

            setSelectedRow({ ...selectedRow, contacts: contacts })
         }
      }
   }, [editContactData])

   useEffect(() => {
      if (addContactError) {
         console.log(addContactError)

         notification.error({
            message: addContactError?.response.data.message || 'Bir hata oluştu'
         })
      }
   }, [addContactError])

   const columns: TableColumnProps<any>[] = [
      {
         title: 'Firma Adı',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return <div className="flex items-center gap-3">{record.company_name}</div>
         }
      },
      {
         title: 'E-posta Adresi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {record?.contacts?.length > 0 ? record.contacts[0].email : 'E-posta adresi bulunamadı'}
               </div>
            )
         }
      },

      {
         title: 'Firmaya ait Kişiler',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  <Button
                     color="primary"
                     variant="ghost"
                     onClick={() => {
                        setSelectedRow(record)
                        setOpenContactsDrawer(true)
                     }}
                  >
                     Görüntüle
                  </Button>
               </div>
            )
         }
      },
      {
         title: 'Eklenme Tarihi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return <div className="flex items-center gap-3">{moment(record.created_at).format('LL')}</div>
         }
      },

      {
         title: 'Durum',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  <Chip color={record.status ? 'success' : 'danger'} size="sm">
                     {record.status ? 'Aktif' : 'Pasif'}
                  </Chip>
               </div>
            )
         }
      },

      {
         title: t('common.actions'),
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  <ActionButton
                     editButtonClick={() => {
                        navigate(routes.customerManagement.view(record.id))
                     }}
                     deleteButtonClick={() => {
                        //@ts-ignore
                        // Modal.confirm({
                        //    title: t('messages.delete_confirm'),
                        //    okText: t('buttons.delete'),
                        //    cancelText: t('buttons.cancel'),
                        //    onOk: () => {
                        //       //@ts-ignore
                        //       deleteReferralsMutation(REFERRALS_DELETE, {
                        //          endPoint: REFERRALS_UPDATE_URL + '/' + id
                        //       })
                        //    }
                        // })
                     }}
                  />
               </div>
            )
         }
      },
      {
         title: t('common.actions'),
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  <Button
                     onClick={() => {
                        setSelectedContactId(record.id)
                        setCustomerProfileDrawer(true)
                     }}
                     color="primary"
                     variant="ghost"
                     startContent={<IconChecklist size={20} />}
                  >
                     Müşteri Profili
                  </Button>
               </div>
            )
         }
      }
   ]

   useEffect(() => {
      //@ts-ignore
      offerManagementQuery(CUSTOMER_MANAGEMENT_LIST, {
         endPoint: CUSTOMER_MANAGEMENT_LIST_URL(page)
      })
   }, [page])

   const handleUpload = async (options: any) => {
      const { file, onSuccess, onError } = options

      try {
         const formData = new FormData()
         formData.append('file', file)
         formData.append('type', 'customer')

         const response = await axios.post('/uploadFileTask', formData, {
            headers: {
               'Content-Type': 'multipart/form-data',
               Authorization: 'Bearer ' + localStorage.getItem('token') || ''
            }
         })

         onSuccess(response.data)
         Modal.success({
            title: 'Başarılı',
            content: 'İşlem tamamlanıca mail adresinize bilgilendirme yapılacaktır',
            onCancel: () => {
               setExcelModal(false)
            },
            onOk: () => {
               setExcelModal(false)
            },
            okText: 'Tamam',
            cancelText: 'Kapat'
         })
      } catch (error) {
         onError(error)
         message.error('Dosya yüklenirken bir hata oluştu')
      }
   }

   return (
      <>
         <Drawer
            closable
            onClose={() => setCustomerProfileDrawer(false)}
            height="95%"
            open={customerProfileDrawer}
            placement="bottom"
            title={data?.data?.data.find((item: any) => item.id === selectedContactId)?.company_name + ' Kayıtları'}
         >
            {selectedContactId && <CustomerProfile id={selectedContactId} />}
         </Drawer>
         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            <div className="mt-4 flex items-center gap-3 @lg:mt-0">
               <Button startContent={<IconFilePlus />} color="primary" onClick={addNewCustomer}>
                  Yeni Müşteri Ekle
               </Button>
            </div>
         </PageHeader>
         <Modal footer={null} title="Excel İle Yükle" open={excelModal} closable onCancel={() => setExcelModal(false)}>
            <Dragger customRequest={handleUpload} multiple={false} accept=".xlsx, .xls">
               <p className="ant-upload-drag-icon text-center w-full flex items-center justify-center">
                  <RiFileExcel2Line size={30} />
               </p>
               <p className="ant-upload-text">
                  Dosyayı sürükleyip bırakın ya da tıklayın Örnek dosyayı indirmek için <a href="#">buraya tıklayın</a>
               </p>
               <p className="ant-upload-hint">Dosyayı yüklemek için buraya sürükleyin</p>
            </Dragger>
            <Button color="primary" variant="solid" className="mt-5 w-20">
               Yükle
            </Button>
         </Modal>
         <div className="@container">
            <Card title="Filter" className="mb-5">
               <CardHeader>
                  <h3 className="text-lg font-semibold">{t('common.filterTable')}</h3>
               </CardHeader>
               <CardBody className="grid grid-cols-4 gap-2 items-center">
                  <SearchInput onChange={(e: any) => setSearch(e.target.value)} value={search} />

                  <Button color="primary" variant="solid" onClick={() => onFilter()} className="mt-5 w-20">
                     {t('common.filter')}
                  </Button>
               </CardBody>
            </Card>
            <Card title={'Müşteri Yönetimi'}>
               <TableComponents
                  columns={columns}
                  data={data?.data?.data || []}
                  pagination={{
                     total: data?.data?.total,
                     current: page,
                     pageSize: 10,
                     onChange: (page: any) => {
                        setPage(page)
                     }
                  }}
                  loading={loading}
               />
            </Card>
         </div>
         <Drawer
            extra={
               <Button onClick={() => setAddContactDrawer(true)} startContent={<IconFilePlus />} color="primary">
                  Yeni Müşteri seçiniz Ekle
               </Button>
            }
            title="Müşteri seçinizlar"
            width={500}
            onClose={() => {
               setSelectedRow(null)
               setOpenContactsDrawer(false)
            }}
            open={openContactsDrawer}
         >
            {selectedRow && (
               <div className="flex flex-col space-y-2">
                  {selectedRow?.contacts?.map((item: any) => (
                     <ContactCard
                        handleSeePast={() => {
                           setSelectedContactId(item.id)
                           setSeeAllOfferDrawer(true)
                        }}
                        handleEditClick={() => {
                           editContactForm.resetFields()
                           editContactForm.setFieldsValue({
                              name: item.name,
                              phone: item.phone,
                              email: item.email,
                              user_id: item.user_id
                           })

                           console.log(item)
                           setSelectedContact(item)
                           setEditContactDrawer(true)
                        }}
                        key={item.id}
                        item={item}
                     />
                  ))}
                  {selectedRow?.contacts?.length === 0 && (
                     <>
                        <Empty />
                        <Title as="h3" className="text-center">
                           Henüz bir kontakt eklenmemiş
                        </Title>
                     </>
                  )}
               </div>
            )}
            <Drawer
               open={addContactDrawer}
               onClose={() => setAddContactDrawer(false)}
               title="Yeni Müşteri seçiniz Ekle"
               width={500}
            >
               <Form form={addContactForm} layout="vertical" onFinish={handleAddContact}>
                  <ContactForm form={addContactForm} />
                  <Button variant="bordered" color="primary" type="submit" isLoading={addContactLoading}>
                     Kaydet
                  </Button>
               </Form>
            </Drawer>
            <Drawer
               open={editContactDrawer}
               onClose={() => {
                  setSelectedContact(null)
                  setEditContactDrawer(false)
               }}
               title="Müşteri seçiniz Düzenle"
               width={500}
            >
               <Form
                  initialValues={{
                     name: selectedContact?.name,
                     phone: selectedContact?.phone,
                     email: selectedContact?.email,
                     user_id: selectedContact?.user_id
                  }}
                  form={editContactForm}
                  layout="vertical"
                  onFinish={handleEditContact}
               >
                  <ContactForm form={editContactForm} />
                  <Button variant="bordered" color="primary" type="submit" isLoading={editContactLoading}>
                     Kaydet
                  </Button>
               </Form>
            </Drawer>
            <Drawer
               placement="bottom"
               open={seeAllOfferDrawer}
               onClose={() => {
                  setSeeAllOfferDrawer(false)
                  setSelectedContactId(null)
               }}
               title="Teklifler"
               height={'95%'}
            >
               {selectedContactId && <CustomerOffer id={selectedContactId} />}
            </Drawer>
         </Drawer>
      </>
   )
}
