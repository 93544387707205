import {
   IconAlignBoxBottomCenter,
   IconBrandProducthunt,
   IconChartAreaLineFilled,
   IconGitPullRequest,
   IconInbox,
   IconMailBolt,
   IconReportAnalytics,
   IconReportMedical
} from '@tabler/icons-react'
import {
   IconAppWindow,
   IconCreditCard,
   IconHome,
   IconHtml,
   IconNotification,
   IconPackageExport,
   IconUserExclamation,
   IconWebhook,
   IconUsers,
   IconChartArea,
   IconDiscountCheck,
   IconList,
   IconChecklist,
   IconClock,
   IconLanguage,
   IconSettings,
   IconBuildingStore,
   IconTag,
   IconUserCog,
   IconTableAlias,
   IconRollerSkating,
   IconForbid2,
   IconForbid,
   IconRewindForward40,
   IconUserCancel,
   IconUserShield,
   IconAccessPoint,
   IconApps,
   IconReport
} from '@tabler/icons-react'
import { routes } from 'config/routes'

// Note: do not add href in the label object, it is rendering as label
export const menuItems = [
   // label start
   {
      name: 'Panel'
   },
   {
      name: 'Anasayfa',

      href: routes.index,
      icon: <IconHome />
   },
   {
      name: 'Modüller'
   },
   {
      name: 'Siparişler',
      href: routes.orders,
      icon: <IconAppWindow />
   },
   {
      name: 'Teklif Yönetimi',
      href: routes.offerManagement.list,
      icon: <IconApps />
   },
   {
      name: 'Talepler',
      href: routes.requests.list,
      icon: <IconWebhook />
   },
   {
      name: 'Müşteri Yönetimi',
      href: routes.customerManagement.list,
      icon: <IconUsers />
   },
   {
      name: 'Tedarikçi Yönetimi',
      href: routes.supplierManagement.list,
      icon: <IconBuildingStore />
   },
   {
      name: 'Personeller',
      href: routes.users.list,
      icon: <IconUsers />
   },

   {
      name: 'Raporlar',
      href: routes.reports,
      icon: <IconChartAreaLineFilled />
   },
   {
      name: 'Ürünler & Stok Kontrolü',
      href: routes.products,
      icon: <IconBrandProducthunt />
   },
   {
      name: 'Mailler',
      href: routes.mailbox,
      icon: <IconInbox />
   },
   {
      name: 'Mail Listem',
      href: routes.myMail,
      icon: <IconInbox />
   }

   // {
   //    name: 'Kullanıcılar',
   //    href: routes.users.list,
   //    icon: <IconUserCog />
   // },
   // {
   //    name: 'Roller',
   //    href: routes.supplierManagement.list,
   //    icon: <IconUserShield />
   // },
   // {
   //    name: 'Erişim İzinleri',
   //    href: routes.supplierManagement.list,
   //    icon: <IconAccessPoint />
   // },
]

export const userMenuItems = [
   {
      name: 'Panel'
   },
   {
      name: 'Anasayfa',

      href: routes.index,
      icon: <IconHome />
   },
   {
      name: 'Modüller'
   },
   {
      name: 'Siparişler',
      href: routes.orders,
      icon: <IconAppWindow />
   },
   {
      name: 'Teklif Yönetimi',
      href: routes.offerManagement.list,
      icon: <IconApps />
   },
   {
      name: 'Talepler',
      href: routes.requests.list,
      icon: <IconWebhook />
   },
   {
      name: 'Müşteri Yönetimi',
      href: routes.customerManagement.list,
      icon: <IconUsers />
   },
   {
      name: 'Tedarikçi Yönetimi',
      href: routes.supplierManagement.list,
      icon: <IconBuildingStore />
   },

   {
      name: 'Raporlar',
      href: routes.reports,
      icon: <IconChartAreaLineFilled />
   },
   {
      name: 'Ürünler & Stok Kontrolü',
      href: routes.products,
      icon: <IconBrandProducthunt />
   },
   {
      name: 'Maillerim',
      href: routes.mailbox,
      icon: <IconMailBolt />
   },
   {
      name: 'Mail Listem',
      href: routes.myMail,
      icon: <IconGitPullRequest />
   }
]
