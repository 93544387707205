import { routes } from 'config/routes'
import { List, TableColumnProps, Modal, Drawer, message } from 'antd'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/page-header'
import { Button, CardBody, CardHeader, Chip, Card, Checkbox, Select, SelectItem, Input } from '@nextui-org/react'
import { Link, useNavigate } from 'react-router-dom'
import useFetch from 'hooks/use-fetch'
import { useEffect, useState } from 'react'
import ActionButton from './ActionButton'
import moment from 'moment'
import {
   OFFER_MANAGEMENT_LIST,
   OFFER_MANAGEMENT_LIST_URL,
   OFFER_MANAGEMENT_SHOW,
   OFFER_MANAGEMENT_SHOW_URL,
   OFFER_MANAGEMENT_STORE
} from 'api/offer-management'
import SearchInput from 'components/Input'
import TableComponents from 'components/Table'
import { IconEdit, IconFileInvoice, IconFilePlus, IconPdf } from '@tabler/icons-react'
import { useDispatch, useSelector } from 'react-redux'
import offer, { setContactId, setOfferNumber, setProduct } from 'features/user/offer'
import { RootState } from 'store'
import { setLoading } from 'features/user/app'
import Preview from './Preview'
import SuccessModal from 'components/Success'
import { MoneyFormatTR } from 'hooks/currency'

interface Props {
   customer_id?: any
}

export default function OfferContactList(props: Props) {
   const { t } = useTranslation()
   const pageHeader = {
      title: 'Müşteri Teklifleri',
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.dashboard,
            name: 'Müşteri Teklifleri'
         }
      ]
   }

   const app = useSelector((state: RootState) => state.app)
   const navigate = useNavigate()
   const dispatch = useDispatch()
   const [offerManagementQuery, { data, loading }] = useFetch<any>()
   const [getOfferLastIdQuery, { data: lastIdData, loading: lastIdLoading }] = useFetch<any>()
   const [getOffer, { data: offerData, loading: offerLoading }] = useFetch<any>()
   const [getOffersFromOfferGroupQuery, { data: offersFromOfferGroupData, loading: offersFromOfferGroupLoading }] =
      useFetch<any>()
   const [convertOfferToOrderMutation, { data: convertOfferToOrderData, loading: convertOfferToOrderLoading }] =
      useFetch<any>()

   const [page, setPage] = useState<number>(1)
   const [search, setSearch] = useState<string>('')
   const [offerType, setOfferType] = useState<any>(null)
   const [offerListModal, setOfferListModal] = useState<boolean>(false)
   const [previewItem, setPreviewItem] = useState<any>({})
   const [previewDrawer, setPreviewDrawer] = useState<boolean>(false)
   const [successModal, setSuccessModal] = useState<boolean>(false)

   const onFilter = () => {
      let object: any = {
         page,
         search,
         offerType
      }

      const result = '?' + new URLSearchParams(object).toString()

      //url = '/offers?page=' + page + '&search=' + search + '&offerType=' + offerType

      let url = '/offers/getOffersFromCustomer/' + props.customer_id + result

      //@ts-ignore
      offerManagementQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: url
      })
   }

   const columns: TableColumnProps<any>[] = [
      {
         title: 'Teklif Grup No',
         dataIndex: 'no',
         key: 'no',
         render: (no: any, record) => {
            return <div className="flex items-center gap-3">{record.no}</div>
         }
      },
      {
         title: 'Müşteri seçiniz (Firma)',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {record.contact.name} ({record.contact.customer.company_name}) */}
                  {record.offers[0]?.contact?.name} ({record.offers[0]?.contact?.customer?.company_name})
               </div>
            )
         }
      },
      {
         title: 'Durumu',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const color = record.is_ordered === 1 ? 'text-green-500' : 'text-red-500'
            const background = record.is_ordered === 1 ? 'bg-green-500' : 'bg-red-100'
            return (
               <div className="items-center gap-3">
                  {/* <Chip color={record.status === 1 ? 'primary' : 'danger'}>{record.status === 1 ? 'Aktif' : 'Pasif'}</Chip> */}
                  <div
                     className={`flex items-center gap-2 rounded-md px-2 py-1 ${background} ${color}`}
                     style={{
                        background: record.is_ordered === 1 ? '#e6ffed' : '#fff5f5'
                     }}
                  >
                     <span>{record.is_ordered === 1 ? 'Siparişe Dönüştürüldü' : 'Bekleniyor'}</span>
                  </div>
               </div>
            )
         }
      },
      {
         title: 'Sipariş Tarihi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const color = record.is_ordered === 1 ? 'text-green-500' : 'text-red-500'
            const background = record.is_ordered === 1 ? 'bg-green-500' : 'bg-red-100'
            return (
               <div className="flex-col items-center gap-3">
                  {/* <Chip color={record.status === 1 ? 'primary' : 'danger'}>{record.status === 1 ? 'Aktif' : 'Pasif'}</Chip> */}
                  <div
                     className={`flex items-center gap-2 rounded-md px-2 py-1 ${background} ${color}`}
                     style={{
                        background: record.is_ordered === 1 ? '#e6ffed' : '#fff5f5'
                     }}
                  >
                     {record.is_ordered === 1 && <div>{moment(record.ordered_at).format('LL')}</div>}
                  </div>
               </div>
            )
         }
      },
      {
         title: 'Eklenme Tarihi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.created_at).format('LL')} */}
                  {moment(record.offers[0]?.created_at).format('LL')}
               </div>
            )
         }
      },
      {
         title: 'Toplam Ciro',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.updated_at).format('LL')} */}
                  {MoneyFormatTR(record.offers[0]?.total)}
               </div>
            )
         }
      },
      {
         title: 'Toplam Kâr',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.updated_at).format('LL')} */}
                  {MoneyFormatTR(record.offers[0]?.profit)}
               </div>
            )
         }
      },

      {
         title: t('common.actions'),
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  <ActionButton
                     editButtonClick={() => {
                        handleGetOffer(record.id)
                     }}
                     viewButtonClick={() => {
                        handleGetOffersFromOfferGroup(record.id)
                     }}
                     deleteButtonClick={() => {
                        //@ts-ignore
                        // Modal.confirm({
                        //    title: t('messages.delete_confirm'),
                        //    okText: t('buttons.delete'),
                        //    cancelText: t('buttons.cancel'),
                        //    onOk: () => {
                        //       //@ts-ignore
                        //       deleteReferralsMutation(REFERRALS_DELETE, {
                        //          endPoint: REFERRALS_UPDATE_URL + '/' + id
                        //       })
                        //    }
                        // })
                     }}
                  />
               </div>
            )
         }
      }
   ]

   useEffect(() => {
      //@ts-ignore
      onFilter()
   }, [page])

   const handleGetlastId = () => {
      //@ts-ignore
      getOfferLastIdQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: '/offers/getLastOfferId'
      })
   }

   const handleGetOffer = (id: number) => {
      //@ts-ignore
      getOffer(OFFER_MANAGEMENT_SHOW, {
         endPoint: OFFER_MANAGEMENT_SHOW_URL(id)
      })
   }

   const handlePreviewOffer = (item: any) => {
      setPreviewItem(item)
      setPreviewDrawer(true)
   }

   const handleGetOffersFromOfferGroup = (id: number) => {
      //@ts-ignore
      getOffersFromOfferGroupQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: '/offers/' + id + '/edit'
      })
   }

   useEffect(() => {
      if (lastIdData) {
         navigate(routes.offerManagement.add)
      }
   }, [lastIdData])

   useEffect(() => {
      dispatch(
         setLoading(
            (offerLoading as boolean) ||
               (offersFromOfferGroupLoading as boolean) ||
               (convertOfferToOrderLoading as boolean)
         )
      )
   }, [offerLoading, offersFromOfferGroupLoading, convertOfferToOrderLoading])

   useEffect(() => {
      if (offerData) {
         const { data } = offerData
         dispatch(setOfferNumber(data?.no))
         dispatch(setContactId(data?.customer_contact_id))

         const products = data?.products.map((product: any, index: number) => {
            const description = JSON.parse(product.product_json)
            return {
               id: product.product_id,
               name: description.name,
               price: description.price,
               stock: product.product_qty,
               product_qty: description.stock,
               delivery_date: product.product_delivery_date,
               commission: product.product_markup,
               commission_type: product.product_markup_type,
               description: product.product_description,
               services: product.services.map((service: any) => {
                  return {
                     ...service.service,
                     commission: service.service_markup,
                     commission_type: service.service_markup_type,
                     description: service.service_description
                  }
               })
            }
         })
         // console.log(products)
         dispatch(setProduct(products))
         navigate(routes.offerManagement.view(data?.offer_group_id))
      }
   }, [offerData])

   useEffect(() => {
      if (offersFromOfferGroupData) {
         setOfferListModal(true)
      }
   }, [offersFromOfferGroupData])

   useEffect(() => {
      if (convertOfferToOrderData) {
         message.success('Siparişe dönüştürüldü!')
         setOfferListModal(false)
         onFilter()
         setSuccessModal(true)
      }
   }, [convertOfferToOrderData])

   return (
      <>
         <Drawer
            title="Teklif Önizleme"
            placement="bottom"
            height={window.innerHeight}
            onClose={() => setPreviewDrawer(false)}
            open={previewDrawer}
         >
            {previewItem && <Preview item={previewItem} />}
         </Drawer>
         <SuccessModal setOpen={setSuccessModal} open={successModal === true} />
         <Modal
            footer={null}
            title={
               <div className="flex items-center gap-3">
                  <IconPdf />
                  Teklifleri Görüntüle
               </div>
            }
            width={window.innerWidth / 1.5}
            onCancel={() => setOfferListModal(false)}
            open={offerListModal}
         >
            {offersFromOfferGroupData && (
               <div className="">
                  {offersFromOfferGroupData?.data?.offers
                     ?.filter((item: any) => item.is_offer)
                     .map((item: any, index: number) => {
                        return (
                           <div
                              key={item.id}
                              className="flex gap-2 items-center justify-between w-full cursor-pointer border-b border-gray-100"
                              style={{}}
                           >
                              <div className="flex flex-col space-y-2">
                                 <span className="font-bold">
                                    {offersFromOfferGroupData?.data?.no + '/' + (index + 1)}
                                 </span>
                                 <span>Revize Tarihi : {moment(item.updated_at).format('LLLL')}</span>
                                 <span>
                                    {item?.contact?.name} ({item?.contact?.customer?.company_name})
                                 </span>
                              </div>
                              <div
                                 className="gap-2 flex "
                                 style={{
                                    display: offersFromOfferGroupData?.data?.is_ordered === 0 ? 'flex' : 'none'
                                 }}
                              >
                                 <Button
                                    onClick={() => {
                                       handlePreviewOffer(item)
                                    }}
                                    color="primary"
                                    startContent={<IconPdf />}
                                    variant="shadow"
                                 >
                                    Önizle
                                 </Button>
                                 <Button
                                    onClick={() => {
                                       handleGetOffer(item.id)
                                    }}
                                    color="secondary"
                                    startContent={<IconEdit />}
                                    variant="shadow"
                                 >
                                    Düzenle
                                 </Button>
                                 {offersFromOfferGroupData?.data?.is_ordered === 0 && (
                                    <Button
                                       onClick={() => {
                                          Modal.confirm({
                                             title:
                                                'Siparişe Dönüştür #' +
                                                offersFromOfferGroupData?.data?.no +
                                                '/' +
                                                item.id,
                                             content:
                                                'Bu Teklif Siparişe dönüştürülüp stoktan düşecektir. Devam etmek istiyor musunuz?',
                                             okText: 'Onayla ve dönüştür',
                                             cancelText: 'İptal',
                                             onOk: () => {
                                                //@ts-ignore
                                                convertOfferToOrderMutation(OFFER_MANAGEMENT_STORE, {
                                                   endPoint: '/offers/convert-order',
                                                   body: {
                                                      offer_id: item.id
                                                   }
                                                })
                                             }
                                          })
                                       }}
                                       color="success"
                                       startContent={<IconFileInvoice />}
                                       variant="bordered"
                                    >
                                       Siparişe Dönüştür
                                    </Button>
                                 )}
                              </div>
                              <div>
                                 {item.is_ordered === 1 && (
                                    <Chip variant="bordered" color="success">
                                       Siparişe Dönüştürüldü
                                    </Chip>
                                 )}
                              </div>
                           </div>
                        )
                     })}
               </div>
            )}
         </Modal>
         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            <div className="mt-4 flex items-center gap-3 @lg:mt-0"></div>
         </PageHeader>

         <div className="@container">
            <Card title="Filter" className="mb-5">
               <CardHeader>
                  <h3 className="text-lg font-semibold">{t('common.filterTable')}</h3>
               </CardHeader>
               <CardBody className="grid grid-cols-4 gap-2 items-center">
                  <Input
                     variant="bordered"
                     classNames={{
                        input: 'ring-0 outline-none focus:ring-0 border-0'
                     }}
                     size="sm"
                     placeholder="Arama"
                     onChange={(value: any) => {
                        setSearch(value.target.value)
                     }}
                  />
                  {/* <Select

                     size="sm"
                     variant="bordered"
                     label="Teklif Tipi"
                     onChange={(value: any) => {
                        setOfferType(value.target.value)
                     }}
                     placeholder="Teklif Tipi"


                  >
                     <SelectItem key={1} value="1">Teklif</SelectItem>
                     <SelectItem key={0} value={0}>Revize</SelectItem>
                     <SelectItem key={3} value={""}>Hepsi</SelectItem>

                  </Select> */}
               </CardBody>
            </Card>
            <Card title={'Teklif Yönetimi'}>
               <TableComponents
                  columns={columns}
                  data={data?.data?.data || []}
                  pagination={{
                     total: data?.data?.total,
                     current: page,
                     pageSize: 10,
                     onChange: (page: any) => {
                        setPage(page)
                     }
                  }}
                  loading={loading}
               />
            </Card>
         </div>
      </>
   )
}
