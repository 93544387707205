'use client'

import { useEffect } from 'react'
import { PiArrowRightBold } from 'react-icons/pi'
import { Checkbox } from 'components/ui/checkbox'
import { Password } from 'components/ui/password'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { routes } from 'config/routes'
import { Form, message } from 'antd'
import { useTranslation } from 'react-i18next'
import useFetch from 'hooks/use-fetch'
import { LOGIN, LOGIN_URL } from 'api/auth'
import { Alert } from 'rizzui'
import { Link } from 'react-router-dom'

export default function SignInForm({ logo, mail }: any) {
   const { t } = useTranslation()
   //TODO: why we need to reset it here
   const [loginMutation, { data: loginData, loading: loginLoading, error: loginError }] = useFetch<any>()

   const onSubmit = (data: any) => {
      //@ts-ignore
      loginMutation(LOGIN, {
         endPoint: LOGIN_URL,
         body: {
            email: data.email,
            password: data.password
         }
      })
   }

   useEffect(() => {
      if (loginError) {
         console.log(loginError)
         message.error("E-posta veya şifre hatalı")
      }
   }, [loginError])

   useEffect(() => {
      if (loginData) {
         console.log(loginData)
         if (loginData?.status) {
            message.success("Giriş başarılı")
            localStorage.setItem('token', loginData.access_token)
            localStorage.setItem('user', JSON.stringify(loginData.user))
            window.location.href = routes.index
         }
      }
   }, [loginData])

   const [form] = Form.useForm()

   return (
      <>
         <Form form={form} onFinish={onSubmit}>
            {loginError && (
               <Alert color="danger" className="mb-5">
                  <div className="text-left text-red-500">
                     E-posta veya şifre hatalı
                  </div>
               </Alert>
            )}
            <div className="space-y-5">
               <Form.Item
                  name="email"
                  rules={[
                     {
                        required: true,
                        message: "E-posta adresi gereklidir"
                     },
                     {
                        type: 'email',
                        message: "Geçerli bir e-posta adresi giriniz"
                     }
                  ]}
               >
                  <Input
                     type="email"
                     size="lg"
                     label="E-posta adresi"
                     color="info"
                     className="[&>label>span]:font-medium"
                     inputClassName="text-sm"
                  />
               </Form.Item>
               <Form.Item
                  name="password"
                  rules={[
                     {
                        required: true,
                        message: "Şifre gereklidir"
                     }
                  ]}
               >
                  <Password
                     label="Şifre"
                     size="lg"
                     className="[&>label>span]:font-medium"
                     inputClassName="text-sm"
                     color="info"
                  />
               </Form.Item>
               {/* <div className="flex items-center justify-between pb-2">
                  <Checkbox
                     label={t('auth.rememberMe')}
                     color="info"
                     variant="flat"
                     className="[&>label>span]:font-medium"
                  />
                
               </div> */}
               <Button isLoading={loginLoading} className="w-full" type="submit" size="lg" color="info">
                  <span>
                     Giriş
                  </span> <PiArrowRightBold className="ms-2 mt-0.5 h-5 w-5" />
               </Button>

            </div>
         </Form>
      </>
   )
}
