import axios from 'axios'
import { FetchProps } from './interface'

export const PRODUCT_LIST_URL = '/products'

export const PRODUCTS = async (props: FetchProps) => {
   const { endPoint } = props as any
   return await axios
      .get(endPoint)
      .then(({ data }) => {
         return { data }
      })
      .catch(error => {
         console.error('hata', error)
         return { error }
      })
}

export const PRODUCT_STORE = async (props: FetchProps) => {
   const { endPoint, data } = props as any
   return await axios
      .post(endPoint, data)
      .then(({ data }) => {
         return { data }
      })
      .catch(error => {
         console.error('hata', error)
         return { error }
      })
}
