import { Drawer } from "antd";

import React from "react";
import { Route, Routes } from "react-router-dom";
import List from "./List";
import AddSuplier from "./Add";
import EditCustomer from "./EditCustomer";


const SupplierManagementPage = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<List />} />     
                <Route path="/new" element={<AddSuplier />} />
                <Route path="/:id/view" element={<EditCustomer />} />          
            </Routes>
        </div>
    );
};

export default SupplierManagementPage;
