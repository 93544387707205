import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { add } from 'lodash'

export interface BasketCreateState {
   carts: any
}

const initialState: BasketCreateState = {
   carts: []
}

export const basketSlice = createSlice({
   name: 'basket',
   initialState,
   reducers: {
      setCart: (state, action: PayloadAction<any>) => {
         state.carts = action.payload
      },
      addCart: (state, action: PayloadAction<any>) => {
         state.carts.push(action.payload)
      },
      removeCart: (state, action: PayloadAction<number>) => {
         const index = action.payload
         state.carts.splice(index, 1)
      },
      emptyCart: state => {
         state.carts = []
      }
   }
})

export const { setCart, addCart, removeCart, emptyCart } = basketSlice.actions

// Action creators are generated for each case reducer function
// export const { setBasketCreate, setShouldUpdateProfile, setToken } = basketSlice.actions

export default basketSlice.reducer
