import axios from 'axios'
import { FetchProps } from './interface'

export const OFFER_MANAGEMENT_LIST_URL = (page: number, search?: any) => '/offers?page=' + page + '&search=' + search

export const USER_OFFER_MANAGEMENT_LIST_URL = '/user/offer-management?page=:page'

export const OFFER_MANAGEMENT_STORE_URL = '/offers'

export const OFFER_MANAGEMENT_UPDATE_URL = (id: any) => '/offers/' + id

export const OFFER_MANAGEMENT_SHOW_URL = (id: number) => '/offers/' + id

export const OFFER_DASHBOARD_GRAPHIC = '/offers/dashboard-graphic'

export const OFFER_MANAGEMENT_LIST = async (props: FetchProps) => {
   const { endPoint, body } = props as any
   return await axios
      .get(endPoint, body)
      .then(({ data }) => {
         return { data }
      })
      .catch(error => {
         return { error }
      })
}

export const OFFER_MANAGEMENT_STORE = async (props: FetchProps) => {
   const { endPoint, body } = props as any
   return await axios
      .post(endPoint, body)
      .then(({ data }) => {
         return { data }
      })
      .catch(error => {
         return { error }
      })
}

export const OFFER_MANAGEMENT_UPDATE = async (props: FetchProps) => {
   const { endPoint, body } = props as any
   return await axios
      .put(endPoint, body)
      .then(({ data }) => {
         return { data }
      })
      .catch(error => {
         return { error }
      })
}

export const OFFER_MANAGEMENT_DELETE = async (props: FetchProps) => {
   const { endPoint, body } = props as any
   return await axios
      .delete(endPoint, body)
      .then(({ data }) => {
         return { data }
      })
      .catch(error => {
         return { error }
      })
}

export const OFFER_MANAGEMENT_SHOW = async (props: FetchProps) => {
   const { endPoint, body } = props as any
   return await axios
      .get(endPoint, body)
      .then(({ data }) => {
         return { data }
      })
      .catch(error => {
         return { error }
      })
}
