import { Button, Chip, Input, SelectItem } from '@nextui-org/react'
import { IconConePlus, IconCubePlus, IconPlus, IconSearch, IconTrash } from '@tabler/icons-react'
import { Card, Divider, Drawer, DrawerProps, Empty, Form, List, Modal, Select, Upload, message } from 'antd'
import { PRODUCTS } from 'api/product'
import { addProduct, setOtherSuppliers, setProduct } from 'features/user/offer'
import useFetch from 'hooks/use-fetch'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader, Input as InputRiz, Textarea, Alert, Switch } from 'rizzui'
import { Select as SelectUi } from '@nextui-org/react'
import { RootState } from 'store'
import _, { isDate } from 'lodash'
import { useDebounce } from 'use-debounce'
import { FetchProps } from 'api/interface'
import axios from 'axios'
import { MoneyFormat, MoneyFormat2, MoneyFormat3 } from 'hooks/currency'
import RenderSearch from './RenderSearch'
import { Button as ButtonUi } from 'antd'
import { apiUrl } from '../../../../api'

interface Props {
   drawerProps: DrawerProps
   handleNext: (products: any) => void
   handlePrev: () => void
   currentProducts?: any[]
}

export default function Step2(props: Props) {
   const { drawerProps, handleNext, handlePrev } = props
   const [productImage, setProductImage] = useState<any>(null)
   const [addProductModal, setAddProductModal] = useState(false)
   const [addProductForm] = Form.useForm()
   const [products, setProducts] = useState<any[]>(props.currentProducts || [])
   const [open, setOpen] = useState(false)
   const [searchQuery, { data, loading }] = useFetch<any>()
   const [serviceQuery, { data: serviceData, loading: serviceLoading }] = useFetch<any>()
   const [search, setSearch] = useState('')
   const offer = useSelector((state: RootState) => state.offer)
   const dispatch = useDispatch()
   const bodyRef = React.useRef<HTMLDivElement>(null)
   const drawerFormRef = React.useRef<any>(null)
   const [productForm] = Form.useForm()
   const [selectedIndex, setSelectedIndex] = useState<any>(null)
   const [servicesDrawer, setServicesDrawer] = useState(false)
   const [serviceSearchModal, setServiceSearchModal] = useState(false)
   const [errors, setErrors] = useState<any>([])
   const [searchs, setSearchs] = useState([])

   const serviceButtonRef = React.useRef<any>(null)

   const [supplierModal, setSupplierModal] = useState(false)
   const [supplierForm] = Form.useForm()
   const [suppliers, setSuppliers] = useState<any[]>([])

   const getSuppliers = () => {
      const response = fetch(apiUrl + '/api/supplier-list', {
         headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
         }
      })
         .then(res => res.json())
         .then(data => {
            console.log('data', data)
            let suppliers = data.data.map((item: any) => {
               return {
                  supplier: item.supplier
               }
            })

            console.log('suppliers', suppliers)
            setSuppliers(suppliers)
         })
   }

   const searchProduct = async () => {
      const response = await fetch(apiUrl + '/api/search-product?search=' + search, {
         headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
         }
      })

      const data = await response.json()

      setSearchs(data.data)
   }

   useEffect(() => {
      if (search.length >= 3) {
         const url = 'https://test.gimel.com.tr/index.php?route=api/crm/searchProduct&search=' + search
         searchProduct()
      }
   }, [search])

   useEffect(() => {
      getSuppliers()
   }, [])

   const addProductToOffer = (product: any) => {
      const findProduct = products.find(item => item.id === product.product_id)
      if (findProduct) {
         console.log('findProduct', findProduct, 'product', product)
         message.error('Bu ürün zaten ekli')
         return
      }
      let price = product.price.split(' ')[0]
      price = price.replace(',', '.')
      price = parseFloat(price).toFixed(2)
      let productArray = {
         id: product.id,
         name: product.name,
         model: product.model,
         price: price,
         stock: product.quantity,
         product_qty: product.quantity,
         delivery_date: '',
         commission: 0,
         commission_type: '3',
         //description: product.description,
         services: [],
         isReserve: false,
         reserveEndDate: '',
         supplier: product.supplier
      }

      console.log(productArray)

      setProducts([...products, productArray])
   }

   const handleAddServiceToProduct = (item: any) => {
      let newProducts = _.cloneDeep(products)
      const findService = newProducts[selectedIndex].services.find((service: any) => service.id === item.id)

      if (findService) {
         message.error('Bu servis zaten ekli')
         return
      }

      newProducts[selectedIndex].services.push({
         ...item,
         commission: 0,
         commission_type: '0',
         description: ''
      })

      newProducts[selectedIndex].services.map((item: any, index: number) => {
         const product_id = newProducts[selectedIndex].id
         serviceForm.setFieldsValue({
            [`price_${product_id}_${index}`]: item.price,
            [`commission_${product_id}_${index}`]: item.commission,
            [`commission_type_${product_id}_${index}`]: item.commission_type,
            [`description_${product_id}_${index}`]: item.description
         })
      })

      setProducts(newProducts)
      setServiceSearchModal(false)
      dispatch(setProduct(products))
   }

   const onChangeProductInput = (productIndex: any, field: any, value: any) => {
      // let newProducts = [...products];
      // newProducts[productIndex][field] = value;
      // setProducts(newProducts);
      // dispatch(addProduct(products))

      const newProducts = _.cloneDeep(products)

      if (field == 'isReserve') {
         newProducts[productIndex][field] = value == 'on' ? true : false
      } else {
         newProducts[productIndex][field] = value
      }
      setProducts(newProducts)

      // dispatch(setProduct(newProducts))
   }

   const handleCheckProductValidation = () => {
      const newErrors: any = []
      products.forEach((item, index) => {
         console.log(item.commission_type)
         let validated = true
         let productErrors: any = {}
         if (item.price === '' || item.price === 0) {
            // productErrors.price = 'Lütfen bir fiyat giriniz';
            newErrors[index].price = 'Lütfen bir fiyat giriniz'
            validated = false
         }
         if (item.stock === '' || item.stock === 0) {
            productErrors.stock = 'Lütfen bir stok miktarı giriniz'
            validated = false
         }
         // if (item.stock !== "" && item.stock > item.product_qty && item.supplier !== "gimel") {
         //     productErrors.stock = 'Stok miktarı ürün miktarından fazla olamaz';
         //     validated = false;

         // }
         if (item.delivery_date === '' || isDate(item.delivery_date)) {
            productErrors.delivery_date = 'Lütfen bir teslim tarihi giriniz veya tarih formatını kontrol ediniz'
            validated = false
         }
         if (new Date(item.delivery_date) < new Date()) {
            productErrors.delivery_date = 'Teslim tarihi bugünden önce olamaz'
            validated = false
            // newErrors[index].delivery_date = 'Teslim tarihi bugünden önce olamaz';
         }

         if (item.commission_type == '3' || item.commission_type == null) {
            productErrors.commission_type = 'Lütfen bir komisyon tipi seçiniz'
            validated = false
         }

         if (item.commission_type === 1 && item.commission > 100) {
            productErrors.commission_type = "Kâr yüzde tipinde 100'den büyük olamaz"
            validated = false
            //newErrors[index].commission_type = 'Kâr yüzde tipinde 100\'den büyük olamaz';
         }

         if (!validated) {
            newErrors[index] = productErrors
         }
      })

      setErrors(newErrors)

      if (newErrors.length > 0) {
         return false
      } else {
         return true
      }
   }

   const RenderValidatioError = (productIndex: any, field: any) => {
      if (errors[productIndex]?.[field]) {
         return <span className="text-red-500 text-sm">{errors[productIndex]?.[field]}</span>
      }
      return null
   }

   const [serviceForm] = Form.useForm()

   const handleScroll = (e: any) => {
      //fixed service ref button on scroll

      if (drawerFormRef.current) {
         if (e.target.scrollTop > drawerFormRef.current.offsetTop) {
            serviceButtonRef.current?.classList.add('fixed', 'bottom-0', 'left-0', 'right-0', 'z-50')
         } else {
            serviceButtonRef.current?.classList.remove('fixed', 'bottom-0', 'left-0', 'right-0', 'z-50')
         }
      } else {
      }
   }

   const handleServiceSave = (e: any) => {
      const productServices = products[selectedIndex].services.map((item: any, index: number) => {
         const product_id = products[selectedIndex].id
         return {
            ...item,
            price: e[`price_${product_id}_${index}`],
            commission: e[`commission_${product_id}_${index}`],
            commission_type: e[`commission_type_${product_id}_${index}`],
            description: e[`description_${product_id}_${index}`]
         }
      })

      console.log('productServices', productServices)

      let newProducts = _.cloneDeep(products)

      newProducts[selectedIndex].services = productServices

      console.log('newProducts', newProducts)

      setProducts(newProducts)

      setServicesDrawer(false)

      message.success('Servisler kaydedildi')
   }

   const findIndexProductId = (id: any) => {
      const selectedIndex = products.findIndex(item => item.id === id)
      setSelectedIndex(selectedIndex)
      if (products[selectedIndex].services.length > 0) {
         const product_id = products[selectedIndex].id
         console.log('product_id', product_id)
         products[selectedIndex].services.map((item: any, index: number) => {
            console.log({
               [`price_${product_id}_${index}`]: item.price,
               [`commission_${product_id}_${index}`]: item.commission,
               [`commission_type_${product_id}_${index}`]: item.commission_type,
               [`description_${product_id}_${index}`]: item.description
            })
            serviceForm.setFieldsValue({
               [`price_${product_id}_${index}`]: item.price,
               [`commission_${product_id}_${index}`]: item.commission,
               [`commission_type_${product_id}_${index}`]: item.commission_type,
               [`description_${product_id}_${index}`]: item.description
            })
         })

         console.log('clone', serviceForm.getFieldsValue())
      }
   }

   const CalculateComission = (item: any) => {
      let price = 0
      if (item.commission_type == 0) {
         // Kâr sabit fiyat + komisyon
         price = parseFloat(item.price) * parseFloat(item.stock) + parseFloat(item.commission)
      } else {
         // Kâr yüzde olarak fiyat * komisyon / 100 + komisyon
         price = parseFloat(item.price) * parseInt(item.stock)

         price = (price * parseFloat(item.commission)) / 100 + price
      }
      console.log('price', item.price)
      return MoneyFormat(price, 'TRY')
   }

   const CalculateCommisionExplain = (item: any) => {
      let calculateDescription
      if (item.commission_type == 0) {
         // Kâr tipi 0 ise, komisyon = Ürün Fiyatı * Ürün Miktarı + Kâr
         calculateDescription = `Ürün Fiyatı (${item.price}) * Ürün Miktarı (${item.stock}) + Kâr (${
            item.commission
         }) = ${CalculateComission(item)}`
      } else {
         // Diğer durumda, komisyon = (Ürün Fiyatı * Kâr / 100) * Ürün Miktarı + Ürün Fiyatı
         calculateDescription = `Ürün Fiyatı (${item.price}) * Stok (${item.stock}) * Kâr (${
            item.commission
         }) / 100 + Ürün Fiyatı (${item.price}) = ${CalculateComission(item)}`
      }

      return calculateDescription
   }

   const CalculateServiceComission = (index: any) => {
      const price = serviceForm.getFieldValue(`price_${products[selectedIndex].id}_${index}`)
      const commission = serviceForm.getFieldValue(`commission_${products[selectedIndex].id}_${index}`)
      const commission_type = serviceForm.getFieldValue(`commission_type_${products[selectedIndex].id}_${index}`)

      let calculateDescription

      if (commission_type == 0) {
         calculateDescription = `Servis Fiyatı (${price}) + Kâr (${commission}) = ${
            parseFloat(price) + parseFloat(commission)
         }`
      } else {
         calculateDescription = `Servis Fiyatı (${price}) * Kâr (${commission}) / 100 + Servis Fiyatı (${price}) = ${
            (parseFloat(price) * parseFloat(commission)) / 100 + parseFloat(price)
         }`
      }

      return calculateDescription
   }

   const groupByXmlFirm = () => {
      //only supplier return
      setSuppliers([])
      dispatch(setOtherSuppliers({}))
      const xmlFirms = products.map(item => item.supplier)
      const uniqueXmlFirms = [...(new Set(xmlFirms) as any)]
      console.log('uniqueXmlFirms', uniqueXmlFirms)

      const groupedProducts = uniqueXmlFirms.map(xmlFirm => {
         return {
            supplier: xmlFirm,
            products: products.filter(item => item.supplier === xmlFirm)
         }
      })

      //groupedProducts listele tedarikçi 250k dan fazla olanları listele toplam tutarları

      console.log('groupedProducts', groupedProducts)

      const totalPrices = groupedProducts.map(item => {
         return item.products.reduce((acc, product) => {
            const price = acc + parseFloat(product.price) * product.stock
            console.log('pricesss', price)
            if (price > 250000) {
               return {
                  total: price,
                  supplier: item.supplier
               }
            }
         }, 0)
      })
      let offerSuppliers = offer.otherSuppliers
      console.log('offerSuppliers', offerSuppliers)

      if (totalPrices.length == 0) {
         dispatch(setProduct(products))
         handleNext(products)
      } else {
         if (totalPrices.length > 0) {
            if (totalPrices[0] !== undefined) {
               setSuppliers(totalPrices)
               setSupplierModal(true)
            } else {
               dispatch(setProduct(products))
               handleNext(products)
            }
         } else {
            dispatch(setProduct(products))
            handleNext(products)
         }
      }
   }

   useEffect(() => {
      console.log('products', products)
   }, [products])

   return (
      <Drawer
         placement="bottom"
         height={window.innerHeight}
         title="Yeni teklif oluştur - Adım 2"
         extra={
            <div className="flex gap-2">
               <Button
                  color="primary"
                  onClick={() => {
                     handlePrev()
                  }}
               >
                  Geri
               </Button>
               <Button
                  color="primary"
                  onClick={() => {
                     if (products.length === 0) {
                        message.error('Lütfen en az bir ürün ekleyiniz')
                        return
                     }
                     if (!handleCheckProductValidation()) {
                        return
                     }

                     groupByXmlFirm()

                     // dispatch(setProduct(products))
                     // handleNext(products);
                  }}
               >
                  İleri
               </Button>
            </div>
         }
         {...drawerProps}
      >
         <Modal
            width={1000}
            footer={null}
            title="Birim Maliyet Tekli Kontrolü"
            open={supplierModal}
            onCancel={() => setSupplierModal(false)}
         >
            <Form
               layout="vertical"
               form={supplierForm}
               onFinish={(values: any) => {
                  const supplierForms = suppliers.map(item => {
                     const supplier = {
                        supplier: item.supplier,
                        total: item.total,
                        values: [
                           {
                              offer_price_1: values[`offer_price_1_${item.supplier}`],
                              offer_supplier_1: values[`offer_supplier_1_${item.supplier}`],
                              offer_price_2: values[`offer_price_2_${item.supplier}`],
                              offer_supplier_2: values[`offer_supplier_2_${item.supplier}`],
                              offer_price_3: values[`offer_price_3_${item.supplier}`],
                              offer_supplier_3: values[`offer_supplier_3_${item.supplier}`]
                           }
                        ]
                     }

                     return supplier
                  })

                  dispatch(setOtherSuppliers(supplierForms))
                  setSupplierModal(false)
                  message.success('Tedarikçi teklifleri kaydedildi')
                  dispatch(setProduct(products))
                  handleNext(products)
               }}
            >
               {suppliers?.map((item, index) => {
                  if (item?.supplier) {
                     return (
                        <div>
                           <Alert color="info" className="flex flex-col mb-5">
                              <span>{item?.supplier} </span>
                              <span>{MoneyFormat3(item?.total)} ₺</span>
                           </Alert>
                           <div className="grid grid-cols-2 gap-2 my-2">
                              {/* {JSON.stringify(item)} */}
                              <Form.Item label="1.Birim Maliyet Teklif" name={`offer_price_1_${item.supplier}`}>
                                 <InputRiz />
                              </Form.Item>
                              <Form.Item label="1.Tedarikçi Bilgisi" name={`offer_supplier_1_${item.supplier}`}>
                                 <InputRiz />
                              </Form.Item>
                              <Form.Item label="2.Birim Maliyet Teklif" name={`offer_price_2_${item.supplier}`}>
                                 <InputRiz />
                              </Form.Item>
                              <Form.Item label="2.Tedarikçi Bilgisi" name={`offer_supplier_2_${item.supplier}`}>
                                 <InputRiz />
                              </Form.Item>
                              <Form.Item label="3.Birim Maliyet Teklif" name={`offer_price_3_${item.supplier}`}>
                                 <InputRiz />
                              </Form.Item>
                              <Form.Item label="3.Tedarikçi Bilgisi" name={`offer_supplier_3_${item.supplier}`}>
                                 <InputRiz />
                              </Form.Item>
                           </div>
                           <Divider />
                        </div>
                     )
                  }
               })}
               <Button color="primary" type="submit">
                  Kaydet
               </Button>
            </Form>
         </Modal>
         <>
            <Form onFinish={handleServiceSave} className="w-full" layout="vertical">
               <Drawer
                  height="100%"
                  placement="bottom"
                  open={servicesDrawer}
                  onClose={() => {
                     setSelectedIndex(null)
                     serviceForm.resetFields()
                     setServicesDrawer(false)
                  }}
               >
                  <Modal
                     footer={null}
                     title="Servis Ekle"
                     open={serviceSearchModal}
                     closable
                     onCancel={() => setServiceSearchModal(false)}
                  >
                     <Input
                        startContent={!serviceLoading ? <IconSearch /> : <Loader />}
                        variant="bordered"
                        radius="sm"
                        placeholder="Servis ara"
                        size="lg"
                        classNames={{
                           input: 'ring-0 outline-none focus:ring-0 focus:outline-none  border-0'
                        }}
                        onChange={e => {
                           // @ts-ignore
                           serviceQuery(PRODUCTS, {
                              endPoint: `services?search=${e.target.value}`
                           })
                        }}
                     />
                     <div className="max-h-96 overflow-y-auto">
                        {serviceData?.data?.map((item: any) => {
                           return (
                              <div
                                 key={item.id}
                                 className="flex gap-2 items-center justify-between w-full cursor-pointer h-20 border-b border-gray-100"
                              >
                                 <span className="font-bold">{item.name}</span>
                                 <Button
                                    onClick={() => handleAddServiceToProduct(item)}
                                    color="primary"
                                    startContent={<IconPlus />}
                                    variant="shadow"
                                 >
                                    Ekle
                                 </Button>
                              </div>
                           )
                        })}
                     </div>
                  </Modal>
                  <div className="mt-5" ref={drawerFormRef} onScroll={handleScroll}>
                     {/* <Button className="w-full" onClick={() => setServiceSearchModal(true)} color="primary" variant="shadow">Servis Ekle</Button> */}
                     <div className="mt-5">
                        {products[selectedIndex]?.services.length === 0 && <Empty description="Servis bulunamadı" />}

                        <Form form={serviceForm} onFinish={handleServiceSave} className="w-full" layout="vertical">
                           {products[selectedIndex]?.services.length > 0 && (
                              <Button
                                 startContent={<IconConePlus />}
                                 size="lg"
                                 className="w-full"
                                 type="submit"
                                 color="secondary"
                                 variant="ghost"
                              >
                                 Kaydet
                              </Button>
                           )}
                           {products[selectedIndex]?.services?.map((item: any, index: number) => (
                              <Card
                                 className="mt-2"
                                 title={item.name}
                                 extra={
                                    <div className="flex gap-2">
                                       {/* <Button type="submit" color="primary" variant="bordered">Kaydet</Button> */}
                                       <Button
                                          onClick={() => {
                                             let newProducts = _.cloneDeep(products)
                                             newProducts[selectedIndex].services = newProducts[
                                                selectedIndex
                                             ].services.filter((service: any) => service.id !== item.id)
                                             setProducts(newProducts)
                                          }}
                                          color="danger"
                                          variant="bordered"
                                       >
                                          Sil
                                       </Button>
                                    </div>
                                 }
                              >
                                 <Alert color="info" className="flex flex-col mb-5">
                                    {/* {CalculateServiceComission(index)} */}
                                    {/* {JSON.stringify(serviceForm.getFieldsValue())} */}
                                    <Form.Item noStyle shouldUpdate>
                                       {() => {
                                          const price = serviceForm.getFieldValue(
                                             `price_${products[selectedIndex].id}_${index}`
                                          )
                                          const commission = serviceForm.getFieldValue(
                                             `commission_${products[selectedIndex].id}_${index}`
                                          )
                                          const commission_type = serviceForm.getFieldValue(
                                             `commission_type_${products[selectedIndex].id}_${index}`
                                          )

                                          let calculateDescription

                                          if (commission_type == 0) {
                                             calculateDescription = `Servis Fiyatı (${price}) + Kâr (${commission}) = ${
                                                parseFloat(price) + parseFloat(commission)
                                             }`
                                          } else {
                                             calculateDescription = `Servis Fiyatı (${price}) * Kâr (${commission}) / 100 + Servis Fiyatı (${price}) = ${
                                                (parseFloat(price) * parseFloat(commission)) / 100 + parseFloat(price)
                                             }`
                                          }

                                          return calculateDescription
                                       }}
                                    </Form.Item>
                                 </Alert>
                                 <div className="grid grid-cols-3 gap-2 my-2">
                                    <Form.Item
                                       label="Birim fiyat"
                                       name={`price_${products[selectedIndex].id}_${index}`}
                                       rules={[{ required: true, message: 'Lütfen bir fiyat giriniz' }]}
                                    >
                                       <InputRiz
                                          name={`price_${products[selectedIndex].id}_${index}`}
                                          onChange={e => {
                                             //onChangeServicesInput(selectedIndex, index, 'price', e.target.value);
                                          }}
                                          value={item.price}
                                          type="number"
                                       />
                                    </Form.Item>

                                    <Form.Item label="Kâr" name={`commission_${products[selectedIndex].id}_${index}`}>
                                       <InputRiz
                                          name={`commission_${products[selectedIndex].id}_${index}`}
                                          //label="Kâr"
                                          type="number"
                                       />
                                    </Form.Item>

                                    <Form.Item
                                       label="Kâr Tipi"
                                       name={`commission_type_${products[selectedIndex].id}_${index}`}
                                       rules={[{ required: true, message: 'Lütfen bir komisyon tipi seçiniz' }]}
                                    >
                                       {/* <SelectUi
                                                        name={`commission_type_${products[selectedIndex].id}_${index}`}
                                                        placeholder="Kâr Tipi"
                                                        size="sm"
                                                        variant="bordered"
                                                        label="Kâr Tipi"
                                                    >
                                                        <SelectItem key={0} value="1">Sabit</SelectItem>
                                                        <SelectItem key={1} value="0">Yüzde</SelectItem>
                                                    </SelectUi> */}
                                       <select
                                          className="w-full p-2 border border-gray-300 rounded-md"
                                          name={`commission_type_${products[selectedIndex].id}_${index}`}
                                          id=""
                                       >
                                          <option selected={item.commission_type == 3} value="3">
                                             Seçim Yapın
                                          </option>
                                          <option selected={item.commission_type == 0} value="0">
                                             Sabit
                                          </option>
                                          <option selected={item.commission_type == 1} value="1">
                                             Yüzde
                                          </option>
                                       </select>
                                    </Form.Item>
                                 </div>
                                 <Form.Item
                                    name={`description_${products[selectedIndex].id}_${index}`}
                                    label="Açıklama"
                                 >
                                    <Textarea
                                       name={`description_${products[selectedIndex].id}_${index}`}
                                       onChange={e => {
                                          //onChangeServicesInput(selectedIndex, index, 'description', e.target.value);
                                       }}
                                       value={item.description}
                                    />
                                 </Form.Item>
                              </Card>
                           ))}
                        </Form>
                     </div>
                  </div>
               </Drawer>
            </Form>
            <div>
               <Modal
                  width={1000}
                  footer={null}
                  title="Yeni Ürün Ekle"
                  open={open}
                  closable
                  onCancel={() => setOpen(false)}
               >
                  <Input
                     startContent={!loading ? <IconSearch /> : <Loader />}
                     variant="bordered"
                     radius="sm"
                     placeholder="Ürün ara"
                     size="lg"
                     classNames={{
                        input: 'ring-0 outline-none focus:ring-0 focus:outline-none  border-0'
                     }}
                     onChange={e => {
                        setSearch(e.target.value)
                     }}
                  />

                  {/* {data?.map((item: any) => {
                                return (
                                    <Card key={item.id} className="my-2 flex cursor-pointer items-center hover:bg-gray-100" onClick={() => {
                                        addProductToOffer(item);

                                        setOpen(false);
                                    }}>
                                        <div className="flex gap-2 items-center justify-between">
                                            <img src={item.thumb} alt="" className="w-10 h-10 object-cover rounded-md" />
                                            <div className="flex flex-col">
                                                <span>
                                                    {item.name}
                                                </span>
                                                <span>
                                                    {item.price} ₺
                                                </span>
                                            </div>
                                            <div className="flex-1">
                                                <Chip color="primary">{item.stock} Adet</Chip>
                                            </div>

                                        </div>
                                    </Card>
                                )
                            })} */}
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                     <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                           <tr>
                              <th scope="col" className="px-6 py-3">
                                 Ürün
                              </th>
                              <th scope="col" className="px-6 py-3">
                                 Stok Kodu
                              </th>
                              <th scope="col" className="px-6 py-3">
                                 Adet
                              </th>
                              <th scope="col" className="px-6 py-3">
                                 Xml Firması
                              </th>
                              <th scope="col" className="px-6 py-3">
                                 Birim Fiyat
                              </th>
                              <th scope="col" className="px-6 py-3">
                                 Ekle
                              </th>
                           </tr>
                        </thead>
                        <tbody
                           className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
                           style={{ maxHeight: '500px', overflow: 'scroll' }}
                        >
                           <tr>
                              <td colSpan={6} className="text-center p-2">
                                 <Button
                                    color="primary"
                                    className="w-full"
                                    variant="shadow"
                                    onClick={() => setAddProductModal(true)}
                                 >
                                    Yeni Ürün Ekle
                                 </Button>
                              </td>
                           </tr>
                           {searchs?.map((item: any) => (
                              <RenderSearch item={item} onAdd={addProductToOffer} setOpen={setOpen} />
                           ))}
                        </tbody>
                     </table>
                  </div>
               </Modal>
            </div>
            <Drawer
               open={addProductModal}
               onClose={() => setAddProductModal(false)}
               placement="bottom"
               height="100%"
               title="Yeni Ürün Ekle"
               extra={
                  <Button
                     onClick={() => {
                        addProductForm.submit()
                     }}
                     color="primary"
                  >
                     Kaydet
                  </Button>
               }
            >
               <Card>
                  <Form
                     layout="vertical"
                     form={addProductForm}
                     onFinish={(values: any) => {
                        const findProduct = products.find(item => item.id === values.id)
                        if (findProduct) {
                           message.error('Bu ürün zaten ekli')
                           return
                        }

                        let productArray = {
                           id: values.model,
                           name: values.name,
                           price: parseFloat(values.price).toFixed(2),
                           stock: values.stock,
                           product_qty: values.stock,
                           delivery_date: '',
                           commission: 0,
                           commission_type: '3',
                           description: values.description,
                           services: []
                        }

                        setProducts([...products, productArray])
                        setOpen(false)
                        setAddProductModal(false)
                     }}
                  >
                     <Form.Item
                        label="Ürün Adı"
                        name="name"
                        rules={[{ required: true, message: 'Lütfen bir ürün adı giriniz' }]}
                     >
                        <InputRiz />
                     </Form.Item>
                     <Form.Item
                        label="Ürün Stok Kodu"
                        name="model"
                        rules={[{ required: true, message: 'Lütfen  stok kodu giriniz' }]}
                     >
                        <InputRiz />
                     </Form.Item>
                     <Form.Item
                        label="Ürün Fiyatı"
                        name="price"
                        rules={[{ required: true, message: 'Lütfen bir ürün fiyatı giriniz' }]}
                     >
                        <InputRiz />
                     </Form.Item>
                     <Form.Item
                        label="Stok"
                        name="stock"
                        rules={[{ required: true, message: 'Lütfen bir stok miktarı giriniz' }]}
                     >
                        <InputRiz />
                     </Form.Item>
                     <Form.Item label="Açıklama" name="description">
                        <Textarea />
                     </Form.Item>
                     <Form.Item label="Görsel" name="image">
                        <Upload
                           onChange={e => {
                              setProductImage(e.file)
                           }}
                           accept="image/*"
                           name="image"
                           listType="picture"
                           className="upload-list-inline"
                        >
                           <Button>Görsel Yükle</Button>
                        </Upload>
                     </Form.Item>
                  </Form>
               </Card>
            </Drawer>
            <div className="@container">
               <Card
                  title="Teklif Detayları"
                  extra={
                     <div className="gap-2">
                        <Button
                           onClick={() => {
                              setOpen(true)
                           }}
                           color="primary"
                           variant="shadow"
                        >
                           Ürün Ekle
                        </Button>
                     </div>
                  }
               ></Card>
               <Form form={productForm} layout="vertical" className="">
                  <div className="mt-5 space-y-2" ref={bodyRef}>
                     {products.map((item: any, index: number) => {
                        return (
                           <Card
                              title={item.name}
                              key={index}
                              extra={
                                 <div className="gap-2 flex">
                                    {/* <Button onClick={() => { findIndexProductId(item.id); setServicesDrawer(true) }} color="secondary" startContent={<IconCubePlus />} variant="bordered">Servis Ekle</Button> */}
                                    <Button
                                       onClick={() => {
                                          setProducts(products.filter((_, i) => i !== index))
                                          dispatch(setProduct(products.filter((_, i) => i !== index)))
                                       }}
                                       color="danger"
                                       startContent={<IconTrash />}
                                       variant="bordered"
                                    >
                                       Sil
                                    </Button>
                                 </div>
                              }
                           >
                              <div>
                                 <Alert color="info" className="flex flex-col mb-5">
                                    {CalculateCommisionExplain(item)}
                                 </Alert>
                              </div>
                              <div className="grid grid-cols-5 gap-5">
                                 <div>
                                    <InputRiz
                                       label="Baskı Dahil Birim Maliyet"
                                       onChange={e => {
                                          onChangeProductInput(index, 'price', e.target.value)
                                       }}
                                       value={item.price}
                                       type="number"
                                    />
                                    {RenderValidatioError(index, 'price')}
                                 </div>
                                 <div>
                                    {/* <Alert variant="flat" color="danger" className="text-center">
                                                        Bu ürünün stoğu {item.stock} {item.product_qty} <b>{item.supplier}</b> firmasına aittir Değişiklik yaparken bilgi alınız
                                                    </Alert> */}
                                    {item.supplier !== 'gimel' && parseInt(item.stock) > parseInt(item.product_qty) && (
                                       <Alert size="sm" variant="flat" color="danger" className="text-center">
                                          Bu ürünün stoğu <b>{item.supplier}</b> firmasına aittir Değişiklik yaparken
                                          bilgi alınız
                                       </Alert>
                                    )}
                                    <InputRiz
                                       label="Stok Talebi"
                                       onChange={e => {
                                          onChangeProductInput(index, 'stock', e.target.value)
                                       }}
                                       onBlur={(e: any) => {
                                          onChangeProductInput(index, 'stock', e.target.value)
                                       }}
                                       value={item.stock}
                                       type="number"
                                    />
                                    {RenderValidatioError(index, 'stock')}
                                 </div>
                                 <div>
                                    <InputRiz
                                       label="İş Günü"
                                       onChange={e => {
                                          onChangeProductInput(index, 'delivery_date', e.target.value)
                                       }}
                                       value={item.delivery_date}
                                       type="date"
                                    />
                                    {RenderValidatioError(index, 'delivery_date')}
                                 </div>
                                 <div>
                                    <InputRiz
                                       label="Kâr"
                                       type="number"
                                       onChange={e => {
                                          onChangeProductInput(index, 'commission', e.target.value)
                                       }}
                                       value={item.commission}
                                    />
                                    {RenderValidatioError(index, 'commission')}
                                 </div>
                                 <div>
                                    <label className="block text-sm font-medium text-gray-700">Kâr Tipi</label>
                                    {/* <Select
                                                        onChange={(e) => {

                                                            onChangeProductInput(index, 'commission_type', e);
                                                        }}
                                                        style={{
                                                            width: "100%"
                                                        }} options={[
                                                            {
                                                                value: 1,
                                                                label: "Yüzde"
                                                            },
                                                            {
                                                                value: 0,
                                                                label: "Sabit"
                                                            },
                                                            {
                                                                value: 3,
                                                                label: "Seçim Yapın"
                                                            }
                                                        ]} /> */}

                                    <select
                                       className="w-full p-2 border border-gray-300 rounded-md"
                                       name="commission_type"
                                       id=""
                                       onChange={e => {
                                          onChangeProductInput(index, 'commission_type', e.target.value)
                                       }}
                                    >
                                       <option selected={item.commission_type == 3} value="3">
                                          Seçim Yapın
                                       </option>
                                       <option selected={item.commission_type == 0} value="0">
                                          Sabit
                                       </option>
                                       <option selected={item.commission_type == 1} value="1">
                                          Yüzde
                                       </option>
                                    </select>

                                    {RenderValidatioError(index, 'commission_type')}
                                 </div>
                              </div>
                              <div className="grid grid-cols-1">
                                 <Form.Item label="Açıklama">
                                    <Textarea
                                       onChange={e => {
                                          onChangeProductInput(index, 'description', e.target.value)
                                       }}
                                       value={item.description}
                                    />
                                 </Form.Item>
                              </div>
                              {item.supplier == 'gimel' && (
                                 <div className="grid grid-cols-3">
                                    <Form.Item
                                       label="Stoğu Rezerve Et"
                                       valuePropName="checked"
                                       name={`isReserve_${item.id}`}
                                    >
                                       <Switch
                                          label="Stoğu Rezerve Et"
                                          onChange={e => {
                                             onChangeProductInput(index, 'isReserve', e.target.value)
                                          }}
                                       />
                                    </Form.Item>
                                    <Form.Item label="Rezerve Bitiş Tarihi" name={`reserveEndDate_${item.id}`}>
                                       <InputRiz
                                          type="date"
                                          onChange={e => {
                                             onChangeProductInput(index, 'reserveEndDate', e.target.value)
                                          }}
                                       />
                                    </Form.Item>
                                 </div>
                              )}
                           </Card>
                        )
                     })}
                  </div>
               </Form>
            </div>
         </>
      </Drawer>
   )
}
