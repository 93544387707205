import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface AppState {
   data: {}
   requestModal: boolean
   loading: boolean
}

const initialState: AppState = {
   data: {},
   loading: false,
   requestModal: false
}

export const appSlice = createSlice({
   name: 'app',
   initialState,
   reducers: {
      setApp: (state, action: PayloadAction<AppState>) => {
         state.data = action.payload
      },
      setLoading: (state, action: PayloadAction<boolean>) => {
         state.loading = action.payload
      },
      setRequestModal: (state, action: PayloadAction<boolean>) => {
         state.requestModal = action.payload
      }
   }
})

// Action creators are generated for each case reducer function
export const { setApp, setLoading, setRequestModal } = appSlice.actions

export default appSlice.reducer
