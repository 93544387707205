import { Button } from '@nextui-org/react'
import { Form } from 'antd'
import UserFilter from 'components/FilterContainer/User'
import React from 'react'
import { Input } from 'rizzui'

interface Props {
  form: any
}

function ContactForm(props: Props) {
  return (
    <div>
      <Form.Item label="Ad Soyad" name="name" rules={[
        {
          required: true,
          message: 'Lütfen Ad Soyad giriniz'
        }
      ]}>
        <Input />
      </Form.Item>
      <Form.Item label="E-mail" name="email" rules={[
        {
          required: true,
          message: 'Lütfen E-mail giriniz'
        },
        {
          type: 'email',
          message: 'Lütfen geçerli bir E-mail giriniz'
        }
      ]}>
        <Input />
      </Form.Item>
      <Form.Item label="Telefon" name="phone" rules={[
        {
          required: true,
          message: 'Lütfen Telefon giriniz'
        },
      ]}>
        <Input />
      </Form.Item>
      <Form.Item label="Atanacak Kullanıcı" name="user_id" rules={[
        {
          required: true,
          message: 'Lütfen Atanacak Kullanıcıyı Seçiniz'
        },
      ]}>
        <UserFilter
          getAll={true}
          onChange={(user: any) => {
            console.log(user);
            props.form.setFieldsValue({
              user_id: user.value
            })
          }}

          value={props.form.getFieldValue('user_id')}
        />
        <input type="hidden" name="user_id" value={props.form.getFieldValue('user_id')} />
      </Form.Item>

    </div>
  )
}

export default ContactForm
