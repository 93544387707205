import React, { useEffect } from 'react'

import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button, cn } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'
import { IconAnalyze, IconEdit, IconEye, IconHistory, IconPdf, IconTrash, IconUserPlus } from '@tabler/icons-react'
import { Drawer, Form, Modal, Popconfirm, Select, notification, Empty } from 'antd'
import useFetch from 'hooks/use-fetch'
import { OFFER_MANAGEMENT_STORE } from 'api/offer-management'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setContactId, setOfferNumber, setOtherSuppliers, setProduct } from 'features/user/offer'
import { routes } from 'config/routes'
import moment from 'moment'
import axios from 'axios'

interface ActionButtonProps {
   deleteButtonClick: any
   editButtonClick: any
   viewButtonClick: any
   users?: any
   row?: any
   setHide: any
   isAssigned: boolean
}

function ActionButton(props: ActionButtonProps) {
   const row = props.row
   const navigate = useNavigate()
   const dispatch = useDispatch()
   const user = JSON.parse(localStorage.getItem('user') || '{}')
   const iconClasses = 'text-xl text-default-500 pointer-events-none flex-shrink-0'
   const [modal, setModal] = React.useState(false)
   const [makeAnAppointmentMutation, { data, loading, error }] = useFetch<any>()
   const [createOfferMutation, { data: createOfferData, loading: createOfferLoading, error: createOfferError }] =
      useFetch<any>()
   const [stockHistoryOpen, setStockHistoryOpen] = React.useState(false)
   const [histories, setHistories] = React.useState<any>([])

   const [form] = Form.useForm()

   console.log(props.users)

   const { t } = useTranslation()

   const onFinish = (values: any) => {
      console.log(props)
      //@ts-ignore
      makeAnAppointmentMutation(OFFER_MANAGEMENT_STORE, {
         endPoint: 'offers/assign',
         body: {
            offer_id: props.row.id,
            user_id: values.user
         }
      })
   }

   const createOffer = () => {
      //@ts-ignore

      createOfferMutation(OFFER_MANAGEMENT_STORE, {
         endPoint: 'offers/convertRequestToOffer',
         body: {
            offer_id: props.row.id
         }
      })
   }

   const fetchHistory = async () => {
      const { data } = await axios.get('offers/' + row.id + '/history')
      setHistories([])
      setHistories(data)

      setStockHistoryOpen(true)
   }

   useEffect(() => {
      if (createOfferData) {
         const { data } = createOfferData
         dispatch(setOfferNumber(data?.no))
         dispatch(setContactId(data?.customer_contact_id))
         dispatch(setOtherSuppliers([]))

         const products = data?.products.map((product: any, index: number) => {
            const description = JSON.parse(product.product_json)
            return {
               id: product.product_id,
               name: description.name,
               price: description.price,
               stock: product.product_qty,
               product_qty: description.stock,
               delivery_date: product.product_delivery_date,
               commission: product.product_markup,
               commission_type: product.product_markup_type,
               description: product.product_description,
               supplier: description.supplier,
               services: product.services.map((service: any) => {
                  return {
                     ...service.service,
                     commission: service.service_markup,
                     commission_type: service.service_markup_type,
                     description: service.service_description
                  }
               })
            }
         })
         console.log(products)
         dispatch(setProduct(products))
         navigate(routes.offerManagement.view(data?.offer_group_id))
      }
   }, [createOfferData])

   useEffect(() => {
      if (data) {
         setModal(false)
         notification.success({
            message: 'Başarılı',
            description: 'Atama başarılı bir şekilde yapıldı.'
         })
         props.setHide(props.row.id)
      }
   }, [data])

   if (user.role !== 'admin') {
      return (
         <Button variant="faded" color="primary" isLoading={createOfferLoading} onClick={() => createOffer()}>
            Teklife Dönüştür
         </Button>
      )
   }

   return (
      <div>
         <Drawer
            title="Talep/Teklif Durumu"
            closable
            open={stockHistoryOpen}
            onClose={() => setStockHistoryOpen(false)}
         >
            {histories?.data?.length === 0 && (
               <div className="place-content-center">
                  <Empty description="Talep/Teklif geçmişi bulunamadı." />
               </div>
            )}
            <ol className="relative border-s border-gray-200 light:border-gray-700">
               {histories?.data?.map((item: any) => (
                  <li className="mb-10 ms-4">
                     <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white light:border-gray-900 light:bg-gray-700" />
                     <time className="mb-1 text-sm font-normal leading-none text-gray-400 light:text-gray-500">
                        {moment(item.created_at).format('DD MMMM YYYY')}
                     </time>
                     <h3 className="text-lg font-semibold text-gray-900 light:text-white">
                        {item.user?.name} - {item.action}
                     </h3>
                  </li>
               ))}
            </ol>
         </Drawer>
         <Modal title="Atama Yap" open={modal} footer={null} closable onCancel={() => setModal(false)}>
            <Form form={form} layout="vertical" name="form_in_modal" onFinish={onFinish}>
               <Form.Item name="user" label="Kullanıcı" rules={[{ required: true, message: 'Kullanıcı seçiniz!' }]}>
                  <Select placeholder="Seçim">
                     {props.users?.result?.map((user: any) => (
                        <Select.Option key={user.id} value={user.id}>
                           {user.name}
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>
               <div className="gap-2 flex">
                  <Button type="submit" color="primary" isLoading={loading} onClick={form.submit}>
                     Atama Yap
                  </Button>
                  <Button color="danger" onClick={() => setModal(false)}>
                     İptal
                  </Button>
               </div>
            </Form>
         </Modal>
         <Dropdown>
            <DropdownTrigger>
               <Button variant="faded">
                  {t('common.actions')} <i className="ml-2 icon-arrow-down" />
               </Button>
            </DropdownTrigger>
            <DropdownMenu variant="faded" aria-label="Dropdown menu with icons">
               <DropdownItem
                  className={props?.isAssigned ? 'hidden' : 'hidden'}
                  onClick={() => setModal(true)}
                  key="view"
                  startContent={<IconUserPlus className={iconClasses} />}
               >
                  Atama Yap
               </DropdownItem>
               <DropdownItem
                  onClick={() => fetchHistory()}
                  key="view"
                  startContent={<IconHistory className={iconClasses} />}
               >
                  Talep Durumları
               </DropdownItem>

               <DropdownItem
                  onClick={props.deleteButtonClick}
                  key="delete"
                  className="text-danger"
                  color="danger"
                  startContent={<IconTrash className={cn(iconClasses, 'text-danger')} />}
               >
                  {t('buttons.delete')}
               </DropdownItem>
            </DropdownMenu>
         </Dropdown>
      </div>
   )
}

export default ActionButton
