import { Button, Card, CardBody, CardHeader, Select, SelectItem } from '@nextui-org/react'
import { DatePicker, Divider, Form, Modal } from 'antd'
import { OFFER_MANAGEMENT_SHOW, OFFER_MANAGEMENT_STORE } from 'api/offer-management'
import MetricCard from 'components/cards/metric-card'
import CircleProgressBar from 'components/charts/circle-progressbar'
import { MoneyFormatTR } from 'hooks/currency'
import useFetch from 'hooks/use-fetch'
import React, { useEffect, useState } from 'react'
import { Text } from 'rizzui'
import cn from 'utils/class-names'

function SummaryWidget() {
  const [type, setType] = useState('day') // ['day', 'week', 'month']

  const [form] = Form.useForm()
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("weekly");
  const [startDate, setStartDate] = useState<Date>(new Date());
  const { RangePicker } = DatePicker;
  const [data, setData] = useState([
    {
      id: 1,
      title: "Sistem Teklifleri",
      metric: 5,
      chart: <CircleProgressBar
        percentage={20}
        size={80}
        stroke="#D7E3FE"
        strokeWidth={7}
        progressColor={'#298dbd'}
        useParentResponsive={true}
        label={
          <Text
            as="span"
            className="font-lexend text-base font-medium text-gray-700"
          >
            20%
          </Text>
        }
        strokeClassName="dark:stroke-gray-300"
      />,
      stat: {
        value: 20,
        increased: true
      }
    },
    {
      id: 2,
      title: "Toplam Bekleyen Talepler",
      metric: 5,
      chart: <CircleProgressBar
        percentage={20}
        size={80}
        stroke="#D7E3FE"
        strokeWidth={7}
        progressColor={'#298dbd'}
        useParentResponsive={true}
        label={
          <Text
            as="span"
            className="font-lexend text-base font-medium text-gray-700"
          >
            20%
          </Text>
        }
        strokeClassName="dark:stroke-gray-300"
      />,
      stat: {
        value: 20,
        increased: true
      }
    },
    {
      id: 3,
      title: "Toplam Sipariş (Web)",
      metric: 5,
      chart: <CircleProgressBar
        percentage={20}
        size={80}
        stroke="#D7E3FE"
        strokeWidth={7}
        progressColor={'#298dbd'}
        useParentResponsive={true}
        label={
          <Text
            as="span"
            className="font-lexend text-base font-medium text-gray-700"
          >
            20%
          </Text>
        }
        strokeClassName="dark:stroke-gray-300"
      />,
      stat: {
        value: 20,
        increased: true
      }
    },
    {
      id: 4,
      title: "Toplam Ciro",
      metric: 5,
      chart: <CircleProgressBar
        percentage={20}
        size={80}
        stroke="#D7E3FE"
        strokeWidth={7}
        progressColor={'#298dbd'}
        useParentResponsive={true}
        label={
          <Text
            as="span"
            className="font-lexend text-base font-medium text-gray-700"
          >
            20%
          </Text>
        }
        strokeClassName="dark:stroke-gray-300"
      />,
      stat: {
        value: 20,
        increased: true
      }
    },
    {
      id: 5,
      title: "Toplam Kar",
      metric: 5,
      chart: <CircleProgressBar
        percentage={20}
        size={80}
        stroke="#D7E3FE"
        strokeWidth={7}
        progressColor={'#298dbd'}
        useParentResponsive={true}
        label={
          <Text
            as="span"
            className="font-lexend text-base font-medium text-gray-700"
          >
            20%
          </Text>
        }
        strokeClassName="dark:stroke-gray-300"
      />,
      stat: {
        value: 20,
        increased: true
      }
    },
    {
      id: 6,
      title: "Siparişe Dönüşme Oranı",
      metric: 5,
      chart: <CircleProgressBar
        percentage={20}
        size={80}
        stroke="#D7E3FE"
        strokeWidth={7}
        progressColor={'#298dbd'}
        useParentResponsive={true}
        label={
          <Text
            as="span"
            className="font-lexend text-base font-medium text-gray-700"
          >
            20%
          </Text>
        }
        strokeClassName="dark:stroke-gray-300"
      />,
      stat: {
        value: 20,
        increased: true
      }
    }

  ]);

  const [fetchDashboard, { data: dashboardData, loading }] = useFetch<any>();

  React.useEffect(() => {
    //@ts-ignore
    fetchDashboard(OFFER_MANAGEMENT_SHOW, {
      endPoint: "dashboard"
    })
  }, [])

  useEffect(() => {
    if (dashboardData) {
      setData([
        {
          id: 1,
          title: "Giden Teklifler",
          metric: dashboardData?.system_requests,
          chart: <div>

          </div>,
          stat: {
            value: 20,
            increased: true
          }
        },
        {
          id: 2,
          title: "Atanmayı Bekleyen Talepler",
          metric: dashboardData?.waiting_requests.length,
          chart: (
            <div>

            </div>
          ),
          stat: {
            value: 20,
            increased: true
          }
        },
        {
          id: 3,
          title: "Teklif Gönderilmemiş Talepler",
          metric: dashboardData?.web_requests,
          chart: <div>

          </div>,
          stat: {
            value: 20,
            increased: true
          }
        },
        {
          id: 4,
          title: "Toplam Sipariş Tutarı",
          metric: MoneyFormatTR(dashboardData?.totalRevenue),
          chart: <div>

          </div>,
          stat: {
            value: 20,
            increased: true
          }
        },
        {
          id: 5,
          title: "Toplam Kâr (Vadesiz)",
          metric: MoneyFormatTR(dashboardData?.totalProfit),
          chart: <div>

          </div>,
          stat: {
            value: 20,
            increased: true
          }
        },
        {
          id: 6,
          title: "Siparişe Dönüşme Oranı",
          metric: dashboardData?.totalToOrderedRate.toFixed(2) + "%",
          chart: <CircleProgressBar
            percentage={dashboardData?.totalToOrderedRate}
            size={80}
            stroke="#D7E3FE"
            strokeWidth={7}
            progressColor={'#298dbd'}
            useParentResponsive={true}
            label={
              <Text
                as="span"
                className="font-lexend text-base font-medium text-gray-700"
              >
                {dashboardData?.totalToOrderedRate.toFixed(2)}%
              </Text>
            }
            strokeClassName="dark:stroke-gray-300"
          />,
          stat: {
            value: 20,
            increased: true
          }
        }
      ])
    }
  }, [dashboardData])


  const handleFilter = (values: any) => {
    console.log(values)
    //@ts-ignore
    fetchDashboard(OFFER_MANAGEMENT_STORE, {
      endPoint: "dashboard",
      body: values
    })
    setFilterOpen(false)
  }

  return (
    <Card>
      <CardHeader>
        <h3>
          Genel Özet
        </h3>
      </CardHeader>
      <Divider />
      <CardBody>
        <div>
          <Modal title="Filtrele" open={filterOpen} footer={null} onCancel={() => setFilterOpen(false)}>
            <Form form={form} layout="vertical" onFinish={handleFilter}>
              <Form.Item name="date">
                <Select
                  label="Tarih"
                  variant="bordered"
                  size="sm" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
                  <SelectItem key={0} value="weekly">Haftalık</SelectItem>
                  <SelectItem key={1} value="monthly">Aylık</SelectItem>
                  <SelectItem key={2} value="yearly">Yıllık</SelectItem>
                  <SelectItem key={3} value="custom">Özel Tarih</SelectItem>
                </Select>

              </Form.Item>
              {selectedDate == "3" && (
                <Form.Item name="range" >
                  <RangePicker className="w-full" />
                </Form.Item>
              )}
              <Button color="primary" variant="bordered" size="lg" type="submit">Filtrele</Button>
            </Form>
          </Modal>
          <div className="mb-5 flex gap-2 items-center">
            <Button color="primary" onClick={() => setFilterOpen(true)}>Filtrele</Button>
            {selectedDate == "3" && (
              <Button color="default" variant="shadow">
                {form.getFieldValue("range")?.[0].format("DD.MM.YYYY")} - {form.getFieldValue("range")?.[1].format("DD.MM.YYYY")}
              </Button>
            )}
            {selectedDate != "3" && (
              <Button color="default" variant="shadow">
                {selectedDate == "weekly" ? "Haftalık" : selectedDate == "monthly" ? "Aylık" : "Yıllık"}
              </Button>
            )}
          </div>
          <div className='grid grid-cols-2 gap-5'>

            {
              data.map((item) => (
                <MetricCard
                  key={item.id}
                  title={item.title}
                  metric={item.metric}
                  metricClassName="3xl:text-[22px]"
                  className={cn('w-full max-w-full justify-between')}
                  chart={item.chart}
                >
                  <Text className="mt-3 flex items-center leading-none text-gray-500">
                    {/* <Text
          as="span"
          className={cn(
            'me-2 inline-flex items-center font-medium',
            item.stat.increased ? 'text-green' : 'text-red'
          )}
        >
          {item.stat.increased ? (
            <TrendingUpIcon className="me-1 h-4 w-4" />
          ) : (
            <TrendingDownIcon className="me-1 h-4 w-4" />
          )}
          {item.stat.value}%
        </Text>
        last month */}
                  </Text>
                </MetricCard>
              ))
            }
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default SummaryWidget
