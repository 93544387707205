import { Button, Card, CardBody, CardHeader, Chip } from '@nextui-org/react'
import { IconFilePlus } from '@tabler/icons-react'
import { TableColumnProps } from 'antd'
import PageHeader from 'components/page-header'
import { routes } from 'config/routes'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ActionButton from './ActionButton'
import useFetch from 'hooks/use-fetch'
import { SUPPLIER_MANAGEMENT_LIST, SUPPLIER_MANAGEMENT_LIST_URL } from 'api/supplier-management'
import TableComponents from 'components/Table'
import SearchInput from 'components/Input'
import { Badge } from 'rizzui'

function SupplierManagementList() {
   const navigate = useNavigate()
   const { t } = useTranslation()

   const [page, setPage] = React.useState(1) // [1,2,3,4,5]
   const [search, setSearch] = React.useState('') // [1,2,3,4,5

   const [suppliers, { data, loading, error }] = useFetch<any>()

   React.useEffect(() => {
      //@ts-ignore
      suppliers(SUPPLIER_MANAGEMENT_LIST, {
         endPoint: SUPPLIER_MANAGEMENT_LIST_URL(page, search)
      })
   }, [page])

   const onFilter = () => {
      //@ts-ignore
      suppliers(SUPPLIER_MANAGEMENT_LIST, {
         endPoint: SUPPLIER_MANAGEMENT_LIST_URL(page, search)
      })
   }

   const pageHeader = {
      title: 'Tedarikçi Yönetimi',
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.supplierManagement.list,
            name: 'Tedarikçi Yönetimi'
         }
      ]
   }

   //  <th>Firma Adı</th>
   //                              <th>İrtibatlı</th>
   //                              <th>İrtibat Email</th>
   //                              <th>İrtibat Telefon</th>
   //                              <th>Eklenen Tarih</th>
   //                              <th>Son Güncelleme</th>
   //                              <th>Durumu</th>
   //                              <th>İşlemler</th>

   const columns: TableColumnProps<any>[] = [
      {
         title: 'Tedarikçi Adı',
         dataIndex: 'name',
         key: 'name',
         sorter: (a, b) => a.name.localeCompare(b.name),
         render: (text, record) => (
            <>
               <span>{record.name}</span>
            </>
         )
      },
      {
         title: 'İndirim Oranı',
         dataIndex: 'discount',
         key: 'discount',
         sorter: (a, b) => a.name.localeCompare(b.discount),
         render: (text, record) => (
            <>
               <Badge color="info">{record.discount}%</Badge>
            </>
         )
      },
      {
         title: 'İrtibatlı',
         dataIndex: 'contact_name',
         key: 'contact_name',
         sorter: (a, b) => a.contact_name.localeCompare(b.contact_name)
      },
      {
         title: 'İrtibat Email',
         dataIndex: 'contact_email',
         key: 'contact_email',
         sorter: (a, b) => a.contact_email.localeCompare(b.contact_email)
      },
      {
         title: 'İrtibat Telefon',
         dataIndex: 'contact_phone',
         key: 'contact_phone',
         sorter: (a, b) => a.contact_phone.localeCompare(b.contact_phone)
      },
      {
         title: 'Eklenen Tarih',
         dataIndex: 'created_at',
         key: 'created_at',
         sorter: (a, b) => a.created_at.localeCompare(b.created_at),
         render: (text, record) => (
            <>
               <span>{moment(record.created_at).format('DD.MM.YYYY')}</span>
            </>
         )
      },
      {
         title: 'Son Güncelleme',
         dataIndex: 'updated_at',
         key: 'updated_at',
         sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
         render: (text, record) => (
            <>
               <span>{moment(record.updated_at).format('DD.MM.YYYY')}</span>
            </>
         )
      },
      {
         title: 'Durumu',
         dataIndex: 'status',
         key: 'status',
         sorter: (a, b) => a.status.localeCompare(b.status),
         render: (text, record) => (
            <>
               <Chip color={record.status === 1 ? 'primary' : 'danger'}>{record.status === 1 ? 'Aktif' : 'Pasif'}</Chip>
            </>
         )
      },
      {
         title: 'İşlemler',
         dataIndex: 'actions',
         key: 'actions',
         render: (text, record) => (
            <>
               <ActionButton
                  editButtonClick={() => navigate(routes.supplierManagement.view(record.id))}
                  deleteButtonClick={() => navigate(routes.supplierManagement.view(record.id))}
               />
            </>
         )
      }
   ]

   return (
      <div>
         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            <Button
               startContent={<IconFilePlus />}
               onClick={() => navigate(routes.supplierManagement.add)}
               color="primary"
            >
               Tedarikçi Ekle
            </Button>
         </PageHeader>

         <div className="@container">
            <Card title="Filter" className="mb-5">
               <CardHeader>
                  <h3 className="text-lg font-semibold">{t('common.filterTable')}</h3>
               </CardHeader>
               <CardBody className="grid grid-cols-4 gap-2 items-center">
                  <SearchInput onChange={(e: any) => setSearch(e.target.value)} value={search} />

                  <Button color="primary" variant="solid" onClick={() => onFilter()} className="mt-5 w-20">
                     {t('common.filter')}
                  </Button>
               </CardBody>
            </Card>
            <Card title={'Teklif Yönetimi'}>
               <TableComponents
                  columns={columns}
                  data={data?.data?.data || []}
                  pagination={{
                     total: data?.data?.total,
                     current: page,
                     pageSize: 10,
                     onChange: (page: any) => {
                        setPage(page)
                     }
                  }}
                  loading={loading}
               />
            </Card>
         </div>
      </div>
   )
}

export default SupplierManagementList
