import { Card, Table } from 'antd'
import { OFFER_MANAGEMENT_SHOW } from 'api/offer-management'
import useFetch from 'hooks/use-fetch'
import React from 'react'

export default function PersonalDashboard() {
   const [fetchDashboard, { data: dashboardData, loading }] = useFetch<any>()

   React.useEffect(() => {
      //@ts-ignore
      fetchDashboard(OFFER_MANAGEMENT_SHOW, {
         endPoint: 'personal-dashboard'
      })
   }, [])

   // Columns for the "Teklif bekleyen talepler" table
   const waitingColumns = [
      {
         title: 'Teklif No',
         dataIndex: ['offer', 'no'],
         key: 'offer_no',
         align: 'center',
         className: 'font-bold'
      },
      {
         title: 'Müşteri',
         dataIndex: ['offer', 'contact', 'customer', 'company_name'],
         key: 'customer_name',
         align: 'center'
      },
      {
         title: 'Durum',
         key: 'status',
         align: 'center',
         render: () => <span className="text-yellow-600">Bekliyor</span>
      }
   ]

   // Columns for the "Teklif gönderilen talepler" table
   const sentColumns = [
      {
         title: 'Teklif No',
         dataIndex: ['offer', 'no'],
         key: 'offer_no',
         align: 'center',
         className: 'font-bold'
      },
      {
         title: 'Müşteri',
         dataIndex: ['offer', 'contact', 'customer', 'company_name'],
         key: 'customer_name',
         align: 'center'
      },
      {
         title: 'Durum',
         key: 'status',
         align: 'center',
         render: () => <span className="text-green-600">Gönderildi</span>
      }
   ]

   // Columns for the "Son Siparişler" table
   const completedColumns = [
      {
         title: 'Sipariş No',
         dataIndex: ['offer', 'no'],
         key: 'offer_no',
         align: 'center',
         className: 'font-bold'
      },
      {
         title: 'Müşteri',
         dataIndex: ['offer', 'contact', 'customer', 'company_name'],
         key: 'customer_name',
         align: 'center'
      },
      {
         title: 'Durum',
         key: 'status',
         align: 'center',
         render: () => <span className="text-blue-600">Tamamlandı</span>
      }
   ]

   return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6  ">
         {/* Teklif bekleyen talepler */}
         <Card
            loading={loading}
            title="Teklif Bekleyen Talepler"
            className="shadow-lg rounded-lg bg-white p-0"
            rootClassName="p-0"
         >
            <Table
               columns={waitingColumns as any}
               dataSource={dashboardData?.waiting_requests?.data || []}
               pagination={false}
               rowKey={record => record.id}
               className="w-full"
            />
         </Card>

         {/* Teklif gönderilen talepler */}
         <Card
            loading={loading}
            title="Teklif Gönderilen Talepler"
            className="shadow-lg rounded-lg bg-white p-0"
            rootClassName="p-0"
         >
            <Table
               columns={sentColumns as any}
               dataSource={dashboardData?.sent_requests?.data || []}
               pagination={false}
               rowKey={record => record.id}
               className="w-full"
            />
         </Card>

         {/* Son Siparişler */}
         <Card
            loading={loading}
            title="Son Siparişler"
            className="shadow-lg rounded-lg bg-white p-0 col-span-1 md:col-span-2"
            rootClassName="p-0"
         >
            <Table
               columns={completedColumns as any}
               dataSource={dashboardData?.completed_orders?.data || []}
               pagination={false}
               rowKey={record => record.id}
               className="w-full"
            />
         </Card>
      </div>
   )
}
