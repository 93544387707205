import { Route, Routes } from 'react-router-dom'
import UsersTable from './page'
import EditUser from './Edit'
import CreateUser from './Create'

const UsersPage = () => {
   return (
      <div>
         <Routes>
            <Route path="/" element={<UsersTable />} />
            <Route path="new" element={<CreateUser />} />
            <Route path=":id/view" element={<EditUser />} />
         </Routes>
      </div>
   )
}

export default UsersPage
