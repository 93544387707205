export default (content: string) => `
<!DOCTYPE html>
<html lang="tr">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Mail Template</title>
  <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap" rel="stylesheet">
  <style>
    body {
      font-family: 'Poppins', sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f6f6f6;
    }
    
    .container {
      max-width: 800px;
      margin: 0 auto;
      padding: 20px;
    }
    
    .header {
      background-color: white;
      color: #fff;
      padding: 20px;
      text-align: center;
    }
    
    .logo {
      display: block;
      margin: 0 auto;
      max-width: 200px;
    }
    
    .content {
      padding: 20px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    }
    
    .title {
      font-size: 24px;
      margin-bottom: 10px;
      color: #333;
    }
    
    .paragraph {
      margin-bottom: 15px;
      color: #666;
    }
    
    .button {
      background-color: #008080;
      color: #fff;
      padding: 10px 20px;
      border: 1px solid #008080;
      border-radius: 5px;
      cursor: pointer;
      text-decoration: none;
      font-weight: 500;
    }
    
    .footer {
      background-color: #ccc;
      color: #000;
      padding: 10px 20px;
      text-align: center;
    }
  </style>
</head>
<body>
  <div class="container">
    <div class="header">
      <img src="https://www.gimel.com.tr/image/catalog/header/logo_gimel.png" alt="Gimel Logo" class="logo">
    </div>
    <div class="content">
      <h1 class="title">Merhaba {name}</h1>
      <p class="paragraph">
        Teklif Formunuza aşağıdaki linkten ulaşabilirsiniz.
        
      </p>
      <div style="min-height:150px;">
        ${content}
      </div>
      <a href="#" class="button">Daha Fazla Bilgi</a>
    </div>
    <div class="footer">
      Gimel 
    </div>
  </div>
</body>
</html>

`
