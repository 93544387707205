import { Image, Modal } from 'antd'
import { OFFER_MANAGEMENT_SHOW, OFFER_MANAGEMENT_SHOW_URL, OFFER_MANAGEMENT_STORE } from 'api/offer-management'
import useFetch from 'hooks/use-fetch'
import React, { useEffect } from 'react'
import { Button } from '@nextui-org/react'

interface RenderSearchProps {
   item: any
   onAdd: any
   setOpen: any
}

function RenderSearch(props: RenderSearchProps) {
   const { item, onAdd, setOpen } = props

   const [checkIsReserve, { data: reserveData, loading: reserveLoading, error: reserveError }] = useFetch<any>()

   const handleReserve = () => {
      //@ts-ignore
      checkIsReserve(OFFER_MANAGEMENT_STORE, {
         endPoint: '/offers/check-reserve',
         body: {
            product_model: item.model
         }
      })
   }

   useEffect(() => {
      if (reserveData) {
         if (reserveData?.status) {
            Modal.confirm({
               title: 'Dikkat',
               content: `Bu ürün daha önce ${reserveData?.user} tarafından rezerve edilmiştir. Rezerve eden kişinin haber verildiğinden emin olunuz.`,
               onOk() {
                  onAdd(item)
                  setOpen(false)
               },
               onCancel() {},
               okText: 'Ekle',
               cancelText: 'İptal'
            })
         } else {
            onAdd(item)
            setOpen(false)
         }
      }
   }, [reserveData])

   return (
      <tr key={item.id} className="text-gray-700 dark:text-gray-400">
         <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
               <div className="flex-shrink-0 w-10 h-10">
                  <Image preview className="w-10 h-10 rounded-full" src={item.image} alt="" />
               </div>
               <div className="ml-4 max-w-xs text-wrap">
                  <div className="text-sm font-medium text-gray-900 dark:text-gray-200">{item.name}</div>
               </div>
            </div>
         </td>
         <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 dark:text-gray-200">{item.model}</div>
         </td>
         <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 dark:text-gray-200">{item.quantity}</div>
         </td>
         <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 dark:text-gray-200">{item.supplier}</div>
         </td>
         <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 dark:text-gray-200">{item.price}</div>
         </td>
         <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
            <Button
               isLoading={reserveLoading}
               onClick={() => {
                  // onAdd(item);
                  // setOpen(false);
                  handleReserve()
               }}
               color="primary"
               variant="flat"
            >
               Ekle
            </Button>
         </td>
      </tr>
   )
}

export default RenderSearch
