'use client'

import { Fragment, useEffect, useState } from 'react'
import { Title } from 'components/ui/text'
import { Collapse } from 'components/ui/collapse'
import cn from 'utils/class-names'
import { PiCaretDownBold } from 'react-icons/pi'
import SimpleBar from 'components/ui/simplebar'
import { Drawer } from 'antd'
import { IconChevronLeft } from '@tabler/icons-react'
import { menuItems, userMenuItems } from './menu-items'
import { Link, useLocation } from 'react-router-dom'
import Logo from './Logo'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { apiUrl, logoUrl } from 'api'

export default function Sidebar({ className }: { className?: string }) {
   const { t } = useTranslation()
   const [visible, setVisible] = useState(false)
   const app = useSelector((state: any) => state.app)
   const location = useLocation()
   // const pathname = window.location.pathname;
   const [pathname, setPathname] = useState(window.location.pathname)
   const [menus, setMenus] = useState(menuItems)

   useEffect(() => {
      setPathname(location.pathname)
   }, [location])

   useEffect(() => {
      const user = JSON.parse(localStorage.getItem('user') || '{}')

      if (user?.role === 'user') {
         setMenus(userMenuItems)
      } else {
         setMenus(menuItems)
      }
   }, [])

   return (
      <aside
         className={cn(
            'fixed bottom-0 start-0 z-50 h-full w-[270px] border-e-2 border-gray-100 bg-white dark:bg-gray-100/50 2xl:w-72',
            className
         )}
      >
         <div className="sticky top-0 z-40 bg-gray-0/10 px-6 pb-5 pt-5 dark:bg-gray-100/5 2xl:px-8 2xl:pt-6">
            <Link aria-label="Site Logo" to="/" className={'flex items-center'}>
               <img src={'https://www.gimel.com.tr/image/catalog/header/logo_gimel.png'} alt="Gimel Crm" />
            </Link>
         </div>

         <SimpleBar className="h-[calc(100%-80px)]">
            <div className="mt-4 pb-3 3xl:mt-6">
               {menus.map((item: any, index) => {
                  const isActive = pathname === (item?.href as string)
                  const pathnameExistInDropdowns: any = item?.dropdownItems?.filter((dropdownItem: any) => {
                     return dropdownItem.href === pathname
                  })
                  const isDropdownOpen = Boolean(pathnameExistInDropdowns?.length)

                  return (
                     <Fragment key={t(item.name) + '-' + index}>
                        {item?.href ? (
                           <>
                              {item?.dropdownItems && item?.dropdownItems.length > 0 ? (
                                 <Collapse
                                    defaultOpen={isDropdownOpen}
                                    header={({ open, toggle }) => (
                                       <div
                                          onClick={toggle}
                                          className={cn(
                                             'group relative mx-3 flex cursor-pointer items-center justify-between rounded-md px-3 py-2 font-medium lg:my-1 2xl:mx-5 2xl:my-2',
                                             isDropdownOpen
                                                ? 'before:top-2/5 text-primary before:absolute before:-start-3 before:block before:h-4/5 before:w-1 before:rounded-ee-md before:rounded-se-md before:bg-primary 2xl:before:-start-5'
                                                : 'text-gray-700 transition-colors duration-200 hover:bg-gray-100 dark:text-gray-700/90 dark:hover:text-gray-700'
                                          )}
                                       >
                                          <span className="flex items-center">
                                             {item?.icon && (
                                                <span
                                                   className={cn(
                                                      'me-2 inline-flex h-5 w-5 items-center justify-center rounded-md [&>svg]:h-[19px] [&>svg]:w-[19px]',
                                                      isDropdownOpen
                                                         ? 'text-primary'
                                                         : 'text-gray-800 dark:text-gray-500 dark:group-hover:text-gray-700'
                                                   )}
                                                >
                                                   {item?.icon}
                                                </span>
                                             )}
                                             {t(item.name)}
                                          </span>

                                          <PiCaretDownBold
                                             strokeWidth={3}
                                             className={cn(
                                                'h-3.5 w-3.5 -rotate-90 text-gray-500 transition-transform duration-200 rtl:rotate-90',
                                                open && 'rotate-0 rtl:rotate-0'
                                             )}
                                          />
                                       </div>
                                    )}
                                 >
                                    {item?.dropdownItems?.map((dropdownItem: any, index: any) => {
                                       const isChildActive = pathname === (dropdownItem?.href as string)

                                       return (
                                          <Link
                                             to={dropdownItem?.href}
                                             key={t(dropdownItem?.name) + index}
                                             className={cn(
                                                'mx-3.5 mb-0.5 flex items-center rounded-md px-3.5 py-2 font-medium capitalize last-of-type:mb-1 lg:last-of-type:mb-2 2xl:mx-5',
                                                isChildActive
                                                   ? 'text-gray-900'
                                                   : 'text-gray-500 transition-colors duration-200 hover:bg-gray-100 hover:text-gray-900'
                                             )}
                                          >
                                             <span
                                                className={cn(
                                                   'me-[18px] ms-1 inline-flex h-1 w-1 rounded-full bg-current transition-all duration-200',
                                                   isChildActive ? 'bg-primary ring-[1px] ring-primary' : 'opacity-40'
                                                )}
                                             />{' '}
                                             {t(dropdownItem?.name)}
                                          </Link>
                                       )
                                    })}
                                 </Collapse>
                              ) : (
                                 <Link
                                    to={item?.href}
                                    className={cn(
                                       'group relative mx-3 my-0.5 flex items-center rounded-md px-3 py-2 font-medium capitalize lg:my-1 2xl:mx-5 2xl:my-2',
                                       isActive
                                          ? 'before:top-2/5 text-primary before:absolute before:-start-3 before:block before:h-4/5 before:w-1 before:rounded-ee-md before:rounded-se-md before:bg-primary 2xl:before:-start-5'
                                          : 'text-gray-700 transition-colors duration-200 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-700/90'
                                    )}
                                 >
                                    {item?.icon && (
                                       <span
                                          className={cn(
                                             'me-2 inline-flex h-5 w-5 items-center justify-center rounded-md [&>svg]:h-[19px] [&>svg]:w-[19px]',
                                             isActive
                                                ? 'text-primary'
                                                : 'text-gray-800 dark:text-gray-500 dark:group-hover:text-gray-700'
                                          )}
                                       >
                                          {item?.icon}
                                       </span>
                                    )}
                                    {t(item.name)}
                                 </Link>
                              )}
                           </>
                        ) : (
                           <Title
                              as="h6"
                              className={cn(
                                 'mb-2 truncate px-6 text-[11px] font-medium uppercase tracking-widest text-gray-500 dark:text-gray-500 2xl:px-8',
                                 index !== 0 && 'mt-6 3xl:mt-7'
                              )}
                           >
                              {t(item.name)}
                           </Title>
                        )}
                     </Fragment>
                  )
               })}
            </div>
         </SimpleBar>
      </aside>
   )
}
