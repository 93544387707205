import { Button } from '@nextui-org/react'
import { Card, Drawer, Image } from 'antd'
import Table from 'components/Table'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Badge, Text, Title } from 'rizzui'
import { QRCodeSVG } from 'qrcode.react'
import moment from 'moment'
import { IconPrinter } from '@tabler/icons-react'
import ReactToPrint from 'react-to-print'
import { formatPrice } from 'hooks/use-price'
import { MoneyFormatTR } from 'hooks/currency'

interface PreviewProps {
   item: any
}

function Preview(props: PreviewProps) {
   const { item } = props

   const invoiceItems = [
      {
         id: '1',
         product: {
            title: 'ChawkBazar Laravel Flutter Mobile App',
            description:
               'Along With Wordpress Themes & Plugins, We always try to use latest trending techs like React, Next Js, Gatsby Js, GraphQl, Shopify etc to make our products special.'
         },
         quantity: 2,
         unitPrice: 100,
         total: 200
      },
      {
         id: '2',
         product: {
            title: 'Borobazar React Next Grocery Template',
            description:
               'Our rich tech choice will help you to build high performance applications. We are also known to provide great customer supports to our customers.'
         },
         quantity: 2,
         unitPrice: 100,
         total: 200
      },
      {
         id: '3',
         product: {
            title: 'Superprops React Modern Landing Page Template',
            description:
               'Our rich tech choice will help you to build high performance applications. We are also known to provide great customer supports to our customers.'
         },
         quantity: 3,
         unitPrice: 100,
         total: 300
      }
   ]

   const columns = [
      {
         title: '#',
         dataIndex: 'id',
         key: 'sku',
         width: 50
      },
      {
         title: 'Item',
         dataIndex: 'product',
         key: 'product',
         width: 250,
         render: (product: any) => (
            <>
               <Title as="h6" className="mb-0.5 text-sm font-medium">
                  {product?.product?.name}
               </Title>
               <Text as="p" className=" max-w-[250px] overflow-hidden truncate text-sm text-gray-500">
                  {product.description}
               </Text>
            </>
         )
      },

      {
         title: 'Adet',
         dataIndex: 'product_qty',
         key: 'product_qty',
         width: 200
      },
      {
         title: 'Birim Fiyat',
         dataIndex: 'product.price',
         key: 'product.price',
         width: 200,
         render: (value: any, record: any) => <Text className="font-medium">{record.product.price} ₺</Text>
      },
      {
         title: 'Komisyon',
         dataIndex: 'product_markup',
         key: 'product_markup',
         width: 200,
         render: (product_markup: any) => <Text className="font-medium">{product_markup}</Text>
      },
      {
         title: 'Komisyon Tipi',
         dataIndex: 'product_markup_type',
         key: 'product_markup_type',
         width: 200,
         render: (product_markup_type: any) => (
            <Text className="font-medium">{product_markup_type?.product_markup_type == 0 ? 'Sabit' : 'Yüzde'}</Text>
         )
      }
   ]

   const items = []

   function InvoiceDetailsListTable() {
      return (
         <div className="flex flex-col">
            {item?.products?.map((item: any, index: number) => {
               const productParse = JSON.parse(item.product_json)
               console.log(productParse)
               let priceWithCommission
               let priceXProductQtyWithCommission
               // const commission = item.commission_type == 0 ? (parseFloat(item.price) * item.stock) + parseFloat(item.commission) :
               //     (parseFloat(item.price) * parseFloat(item.commission) / 100) * item.stock + parseFloat(item.price);
               if (item?.product_markup_type == 0) {
                  priceWithCommission = parseFloat(productParse?.price) + parseFloat(item?.product_markup)
               } else {
                  priceWithCommission =
                     parseFloat(productParse?.price) +
                     (parseFloat(item?.product_price) * parseFloat(item?.product_markup)) / 100
               }
               priceXProductQtyWithCommission = priceWithCommission * item?.product_qty

               return (
                  <Card
                     key={index}
                     title={` Ürün #${productParse?.name}`}
                     rootClassName="border-[20px] border-gray-100"
                  >
                     <div className="bg-white rounded-md my-[5px] shadow-md border">
                        <div className="grid grid-cols-4">
                           <span className="font-bold text-md p-2 border">Ürün Görseli</span>
                           <span className="font-bold text-md p-2 border">Adet</span>
                           <span className="font-bold text-md p-2 border">Birim Fiyat</span>
                           <span className="font-bold text-md p-2 border">Genel Toplam</span>
                           {/* <span className='font-bold text-md p-2 border'>
                                            Komisyon
                                        </span>
                                        <span className='font-bold text-md p-2 border'>
                                            Komisyon Tipi
                                        </span> */}
                        </div>
                        <div className="grid grid-cols-4 border-b">
                           <span className="text-md border-r text-left p-1 px-2">
                              <Image preview src={productParse?.thumb} width={100} height={100} />
                           </span>
                           <span className="text-md border-r text-left p-1 px-2">{item?.product_qty}</span>
                           <span className="text-md border-r text-left p-1 px-2">
                              {/* {item?.product_price} */}
                              {productParse?.price}
                           </span>
                           <span className="text-md border-r text-left p-1 px-2">
                              {/* {item?.product_price * item?.product_qty} */}
                              {MoneyFormatTR(productParse?.price * item?.product_qty)}
                           </span>
                        </div>

                        {item?.services?.map((item: any, index: number) => {
                           let priceWithCommission
                           let priceXProductQtyWithCommission

                           if (item?.service_markup_type?.service_markup_type == 0) {
                              priceWithCommission = parseFloat(item?.service?.price) + parseFloat(item?.service_markup)
                           } else {
                              priceWithCommission =
                                 parseFloat(item?.service?.price) +
                                 (parseFloat(item?.service?.price) * parseFloat(item?.service_markup)) / 100
                           }

                           return (
                              <div key={index} className="grid grid-cols-2">
                                 <span className="text-md border-r text-left p-1 px-2">{item?.service.name}</span>
                                 <span className="text-md border-r text-left p-1 px-2">
                                    {MoneyFormatTR(priceWithCommission)}
                                 </span>
                              </div>
                           )
                        })}
                     </div>
                  </Card>
               )
            })}
         </div>
      )
   }

   const printRef = React.useRef<any>(null)

   const productTotal = item.products?.map((item: any) => {
      let priceWithCommission
      let priceXProductQtyWithCommission
      // const commission = item.commission_type == 0 ? (parseFloat(item.price) * item.stock) + parseFloat(item.commission) :
      //     (parseFloat(item.price) * parseFloat(item.commission) / 100) * item.stock + parseFloat(item.price);
      if (item?.product_markup_type == 0) {
         priceWithCommission = parseFloat(item?.product_price) + parseFloat(item?.product_markup)
      } else {
         priceWithCommission =
            parseFloat(item?.product_price) + (parseFloat(item?.product_price) * parseFloat(item?.product_markup)) / 100
      }

      priceXProductQtyWithCommission = priceWithCommission * item?.product_qty

      return priceXProductQtyWithCommission
   })

   const serviceTotal = item.products?.map((item: any) => {
      let priceWithCommission
      let priceXProductQtyWithCommission

      item.services?.map((item: any) => {
         if (item?.service_markup_type?.service_markup_type == 0) {
            priceWithCommission = parseFloat(item?.service?.price) + parseFloat(item?.service_markup)
         } else {
            priceWithCommission =
               parseFloat(item?.service?.price) +
               (parseFloat(item?.service?.price) * parseFloat(item?.service_markup)) / 100
         }

         priceXProductQtyWithCommission = priceWithCommission * item?.product_qty
      })

      return priceWithCommission
   })

   return (
      <Card
         title="Teklif Detayları"
         bordered={false}
         extra={
            <ReactToPrint
               trigger={() => (
                  <Button color="primary" variant="bordered" startContent={<IconPrinter />}>
                     Yazdır
                  </Button>
               )}
               content={() => printRef.current}
            />
         }
      >
         <div className="w-full rounded-xl border border-muted p-5 text-sm sm:p-6 lg:p-8 2xl:p-10" ref={printRef}>
            <div className="mb-12 flex flex-col-reverse items-start justify-between md:mb-16 md:flex-row">
               <img width={200} src={'https://www.gimel.com.tr/image/catalog/header/logo_gimel.png'} alt="Gimel Crm" />
               <div className="mb-4 md:mb-0">
                  <Badge
                     variant="flat"
                     color={item.is_ordered ? 'success' : 'warning'}
                     rounded="md"
                     className="mb-3 md:mb-2"
                  >
                     {item.is_ordered ? 'Sipariş' : 'Teklif'}
                  </Badge>
                  <Title as="h6">
                     NO - #{item.no} / {item.id}
                  </Title>
                  <Text className="mt-0.5 text-gray-500">Teklif No</Text>
               </div>
            </div>

            <div className="mb-12 grid gap-4 xs:grid-cols-2 sm:grid-cols-2 sm:grid-rows-1">
               <div className="mt-4 xs:mt-0">
                  <Title as="h6" className="mb-3.5 font-semibold">
                     Kime
                  </Title>
                  <Text className="mb-1.5 text-sm font-semibold uppercase">{item.contact?.name}</Text>
                  <Text className="mb-1.5">{item.contact?.customer?.company_name}</Text>
                  <Text className="mb-1.5">{item.contact?.customer?.email}</Text>
                  <Text className="mb-4 sm:mb-6 md:mb-8">{item.contact?.customer?.phone}</Text>
                  <div>
                     <Text className="mb-2 text-sm font-semibold">Teklif Tarihi</Text>
                     <Text>{moment(item.created_at).format('DD.MM.YYYY')}</Text>
                  </div>
               </div>
            </div>

            <InvoiceDetailsListTable />
            <div className="flex flex-col-reverse items-start justify-between border-t border-muted pb-4 pt-8 xs:flex-row">
               <div className="mt-6 max-w-md pe-4 xs:mt-0"></div>
               <div className=" w-full max-w-sm">
                  <Text className="flex items-center justify-between border-b border-muted pb-3.5 lg:pb-5">
                     Ürün Toplamı
                     <Text as="span" className="font-semibold">
                        {MoneyFormatTR(item.total)}
                     </Text>
                  </Text>

                  <Text className="flex items-center justify-between border-b border-muted py-3.5 lg:py-5">
                     Genel Toplam
                     <Text as="span" className="font-semibold">
                        {MoneyFormatTR(item.total)}
                     </Text>
                  </Text>
               </div>
            </div>
         </div>
      </Card>
   )
}

export default Preview
