import { Card, CardBody, CardFooter, CardHeader } from '@nextui-org/react'
import { IconSearch } from '@tabler/icons-react'
import { Divider, Drawer, Empty, Form, List, Modal, Select, TreeSelect, message, notification } from 'antd'
import { PRODUCT_LIST_URL, PRODUCT_STORE, PRODUCTS } from 'api/product'
import axios from 'axios'
import Search from 'components/FilterContainer/Search'
import PageHeader from 'components/page-header'
import { routes } from 'config/routes'
import { addCart } from 'features/user/basket'
import useFetch from 'hooks/use-fetch'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Loader } from 'rizzui'
import { RootState } from 'store'
import { apiUrl } from 'api/index'
import CreateProduct from './CreateProduct'

function Products() {
   const [iframeLink, setIframeLink] = React.useState<string | undefined>('')
   const [iframeOpen, setIframeOpen] = React.useState(false)
   const user = JSON.parse(localStorage.getItem('user') || '{}')
   const [searchQuery, { data, loading }] = useFetch<any>()
   const [updateStockMutation, { data: updateStockData, loading: updateStockLoading }] = useFetch<any>()

   const [categories, setCategories] = React.useState<any[]>([])
   const [suppliers, setSuppliers] = React.useState<any[]>([])

   const [products, setProducts] = React.useState<any[]>([])
   const [stockHistoryOpen, setStockHistoryOpen] = React.useState(false)

   const basket = useSelector((state: RootState) => state.basket.carts)
   const [histories, setHistories] = React.useState<any>([])
   const dispatch = useDispatch()
   const [selectedId, setSelectedId] = React.useState<string | undefined>(undefined)
   const [updateModal, setUpdateModal] = React.useState(false)

   const [updateForm] = Form.useForm()

   const [createProductForm] = Form.useForm()

   const [createProductDrawer, setCreateProductDrawer] = React.useState(false)

   const pageHeader = {
      title: 'Ürünler & Stok Kontrolü',
      breadcrumb: [
         {
            href: routes.dashboard,
            name: 'Anasayfa'
         },
         {
            href: routes.products,
            name: 'Ürünler & Stok Kontrolü'
         }
      ]
   }

   const [form] = Form.useForm()

   const onSearch = (value: string) => {
      if (value.length > 3) {
         const url = 'https://test.gimel.com.tr/index.php?route=api/crm/searchProduct&search=' + value
         // @ts-ignore
         searchQuery(PRODUCTS, {
            endPoint: url
         })
      }
   }

   const fetchCategories = async () => {
      const response = await fetch(apiUrl + '/api/category-list', {
         headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
         }
      })
      const data = await response.json()
      setCategories(data.data)
   }

   const fetchSuppliers = async () => {
      const response = await fetch(apiUrl + '/api/supplier-list', {
         headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
         }
      })
      const data = await response.json()
      setSuppliers(data.data)
   }

   const handleFilter = async (values: any) => {
      const object: any = {
         category_id: values.category == 'undefined' ? '' : values.category,
         supplier: values.supplier === 'undefined' ? '' : values.supplier,
         search: values.query == 'undefined' ? '' : values.query,
         order: values.order == 'undefined' ? '' : values.order
      }

      const url = apiUrl + '/api/search-product?' + new URLSearchParams(object).toString().replace('undefined', '')

      const response = await fetch(url, {
         headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
         }
      })

      const data = await response.json()

      if (values?.supplier !== 'undefined' && values?.supplier?.length > 0) {
         setProducts(data?.data.filter((item: any) => item.supplier == values.supplier))
      } else {
         setProducts(data?.data)
      }
   }

   const handleAddBasket = async (product_id: string) => {
      const findProduct = products.find((item: any) => item.id == product_id)
      const findBasket = basket.find((item: any) => item.id == product_id)
      if (findBasket) {
         message.error('Ürün sepetinizde bulunmaktadır.')
      } else {
         dispatch(
            addCart({
               product_id: product_id,
               name: findProduct.name,
               model: findProduct.model,
               stock: findProduct.quantity,
               supplier: findProduct.supplier,
               thumb: findProduct.image,
               price: findProduct.price
            })
         )
         message.success('Ürün sepetinize eklendi.')
      }
   }

   React.useEffect(() => {
      fetchCategories()
      fetchSuppliers()
   }, [])

   const fetchHistory = async (product_id: string) => {
      const { data } = await axios.get('products/history/' + product_id)
      setHistories([])
      setHistories(data)

      setStockHistoryOpen(true)
   }

   const updateStock = (values: any) => {
      //@ts-ignore
      updateStockMutation(PRODUCT_STORE, {
         endPoint: 'update-product-stock',

         data: {
            product_id: selectedId,
            quantity: values.stock,
            stock_type: values.type
         }
      })
   }

   useEffect(() => {
      if (updateStockData) {
         setUpdateModal(false)
         message.success('Stok güncellendi.')
         //@ts-ignore
         handleFilter(form.getFieldsValue())
         updateForm.resetFields()
      }
   }, [updateStockData])

   return (
      <div>
         <Drawer
            title="Ürün Ekle"
            closable
            open={createProductDrawer}
            onClose={() => setCreateProductDrawer(false)}
            placement="right"
            width={window.innerWidth > 768 ? '50%' : '100%'}
         >
            <Card style={{ padding: 10 }}>
               <CreateProduct />
            </Card>
         </Drawer>
         <Drawer
            extra={
               <Button onClick={() => setIframeOpen(false)} color="primary" variant="outline">
                  Kapat
               </Button>
            }
            placement="bottom"
            height={window.innerHeight - 100}
            title="Ürün Detayı"
            closable
            open={iframeOpen}
            onClose={() => setIframeOpen(false)}
         >
            <iframe src={iframeLink} loading="lazy" className="w-full h-[80vh]" />
         </Drawer>
         <Drawer title="Stok Geçmişi" closable open={stockHistoryOpen} onClose={() => setStockHistoryOpen(false)}>
            {histories?.data?.length === 0 && (
               <div className="place-content-center">
                  <Empty description="Stok geçmişi bulunamadı." />
               </div>
            )}
            <ol className="relative border-s border-gray-200 light:border-gray-700">
               {histories?.data?.map((item: any) => (
                  <li className="mb-10 ms-4">
                     <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white light:border-gray-900 light:bg-gray-700" />
                     <time className="mb-1 text-sm font-normal leading-none text-gray-400 light:text-gray-500">
                        {moment(item.created_at).format('DD MMMM YYYY')}
                     </time>
                     <h3 className="text-lg font-semibold text-gray-900 light:text-white">
                        {item.user?.name} - {item.type == 'in' ? 'stok girişi yaptı' : 'Stok çıkışı yaptı'}
                     </h3>
                     <p className="mb-4 text-base font-normal text-gray-500 light:text-gray-400">
                        Önceki Stok : {item.before_stock} - Yeni Stok : {item.after_stock}
                     </p>
                     <p className="mb-4 text-base font-normal text-gray-500 light:text-gray-400">{item.description}</p>
                  </li>
               ))}
            </ol>
         </Drawer>
         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            <Button color="primary" variant="solid" onClick={() => setCreateProductDrawer(true)}>
               Yeni Ürün Ekle
            </Button>
         </PageHeader>
         <Card className="p-2" radius="none">
            <Form form={form} layout="vertical" onFinish={handleFilter}>
               <div className="grid lg:grid-cols-5 gap-2">
                  <div className="">
                     <Form.Item name="query" label="Ürün adı ile arama">
                        <Input
                           suffix={loading && <Loader size="lg" />}
                           placeholder="Ürün Ara"
                           prefix={<IconSearch />}
                        />
                     </Form.Item>
                  </div>
                  <div>
                     <Form.Item name="supplier" label="Tedarikçi ile arama">
                        <Select
                           showSearch
                           placeholder="Tedarikçi Seç"
                           optionFilterProp="children"
                           filterOption={(input, option: any) =>
                              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           }
                        >
                           <Select.Option value="undefined">Tüm Tedarikçiler</Select.Option>
                           {suppliers?.map((supplier: any) => (
                              <Select.Option value={supplier.supplier}>{supplier.supplier}</Select.Option>
                           ))}
                        </Select>
                     </Form.Item>
                  </div>
                  <div>
                     <Form.Item name="category" label="Kategori">
                        {/* <Select
                                showSearch
                                placeholder="Kategori Seç"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Select.Option value="1">Kategori 1</Select.Option>
                                <Select.Option value="2">Kategori 2</Select.Option>
                                <Select.Option value="3">Kategori 3</Select.Option>
                            </Select> */}
                        <TreeSelect
                           style={{ width: '100%' }}
                           value={form.getFieldValue('category')}
                           dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                           treeData={categories}
                           placeholder="Kategori"
                           treeDefaultExpandAll
                           onChange={value => form.setFieldsValue({ category: value })}
                           showSearch
                           filterTreeNode={(input, option: any) =>
                              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           }
                        />
                     </Form.Item>
                  </div>
                  <div>
                     <Form.Item name="order" label="Sıralama">
                        <Select
                           showSearch
                           placeholder="Sıralama"
                           optionFilterProp="children"
                           filterOption={(input, option: any) =>
                              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           }
                        >
                           <Select.Option value="undefined">Sırala</Select.Option>
                           <Select.Option value="price_asc">Fiyat (En yüksekten en düşüğe)</Select.Option>
                           <Select.Option value="price_desc">Fiyat (En düşükten en yükseğe)</Select.Option>
                           <Select.Option value="quantity_asc">Adet (En azdan en çoğa)</Select.Option>
                           <Select.Option value="quantity_desc">Adet (En çoktan en aza)</Select.Option>
                        </Select>
                     </Form.Item>
                  </div>

                  <div>
                     <Button type="submit" className="lg:mt-8" color="primary" variant="solid">
                        Filtrele
                     </Button>
                  </div>
               </div>
            </Form>
         </Card>
         <Modal
            footer={null}
            okButtonProps={{
               onClick: () => {
                  updateForm.submit()
               }
            }}
            title="Stok Güncelle"
            open={updateModal}
            onCancel={() => setUpdateModal(false)}
            onOk={() => setUpdateModal(false)}
         >
            <Form form={updateForm} layout="vertical" onFinish={updateStock}>
               <Form.Item label="Stok Miktarı" name="stock">
                  <Input />
               </Form.Item>
               <Form.Item label="Stok Tipi" name="type">
                  <Select>
                     <Select.Option value="in">Stok Girişi</Select.Option>
                     <Select.Option value="out">Stok Çıkışı</Select.Option>
                  </Select>
               </Form.Item>
               <Button isLoading={updateStockLoading} type="submit" color="primary" variant="solid">
                  Kaydet
               </Button>
            </Form>
         </Modal>
         <div className="grid lg:grid-cols-4 gap-10 mt-2">
            {products?.map((item: any, index: any) => (
               <div className="bg-white border border-gray-300 p-5">
                  <div className="relative">
                     <div className="relative mx-auto aspect-[6/5.06] w-full overflow-hidden rounded-lg bg-gray-100">
                        <img
                           src={item.supplier == 'gimel' ? item.image : item.image}
                           alt="product"
                           className="object-cover object-center w-full h-full transition-transform duration-200 transform hover:scale-105"
                        />
                     </div>
                     <button
                        className="rizzui-button inline-flex font-medium items-center justify-center active:enabled:translate-y-px focus:outline-none focus-visible:ring-[1.8px] focus-visible:ring-offset-2 ring-offset-background transition-colors duration-200 text-sm h-10 rounded-md hover:text-primary focus-visible:ring-primary-lighter w-10 p-0 absolute end-3 top-3"
                        type="button"
                        aria-label="wishlist"
                     ></button>
                  </div>
                  <div className="pt-3">
                     <a href="/ecommerce/products/casio-watch">
                        <h6 className="rizzui-title-h6 text-base mb-1 truncate font-semibold transition-colors hover:text-primary">
                           {item.name}
                        </h6>
                     </a>
                     <p className="rizzui-text-p font-normal truncate">Fiyat : {item.price}</p>
                     <p className="rizzui-text-p font-normal truncate">Stok Kodu : {item.model}</p>
                     <p className="rizzui-text-p font-normal truncate">Adet : {item.quantity}</p>
                     <p className="rizzui-text-p font-normal truncate">Xml Firması : {item.supplier}</p>
                     <div className="flex gap-1 items-center">
                        <Button
                           onClick={() => fetchHistory(item.id)}
                           className="mt-2"
                           size="sm"
                           color="danger"
                           variant="outline"
                        >
                           Stok Geçmişi
                        </Button>
                        <Button
                           className="mt-2"
                           size="sm"
                           color="primary"
                           variant="outline"
                           onClick={() => {
                              setIframeLink(item.href)
                              setIframeOpen(true)
                           }}
                        >
                           Ürün Detayı
                        </Button>
                        <Button
                           className="mt-2"
                           size="sm"
                           color="primary"
                           variant="outline"
                           onClick={() => handleAddBasket(item.id)}
                        >
                           Sepete Ekle
                        </Button>
                     </div>
                     {user?.role == 'admin' && item.supplier == 'gimel' && (
                        <Button
                           className="mt-2 w-full"
                           size="sm"
                           color="primary"
                           variant="outline"
                           onClick={() => {
                              setSelectedId(item.product_id)
                              setUpdateModal(true)
                           }}
                        >
                           Stok Güncelle
                        </Button>
                     )}
                  </div>
               </div>
            ))}
         </div>
      </div>
   )
}

export default Products
