export const getCurrentCurrency = () => {
   return JSON.parse(localStorage.getItem('currency') || '[]')
}

export const PriceFormat = (price: any, currency: string) => {
   const currencyStorage = JSON.parse(localStorage.getItem('currency') || '[]')

   if (currency == 'EUR') {
      return price
   } else {
      const currencyRate = currencyStorage.find((item: any) => item.from === currency && item.to == 'EUR')
      if (!currencyRate) {
         return 0
      } else {
         return (price * currencyRate.amount).toFixed(2)
      }
   }
}

export const priceFormat = (price: any, currency: string) => {
   const currencyStorage = JSON.parse(localStorage.getItem('currency') || '[]')
   if (currency == 'EUR') {
      return price
   } else {
      const currencyRate = currencyStorage.find((item: any) => item.from == currency && item.to == 'EUR')
      return (price * currencyRate.amount).toFixed(2)
   }
}

export const NumberFormat = (price: any) => {
   return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export const MoneyFormat = (price: any, currency = 'EUR') => {
   let format = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
   })

   return format.format(price)
}

export const MoneyFormatTR = (price: any, currency = 'TRY') => {
   let format = new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: currency
   })

   return format.format(price)
}

export const MoneyFormat2 = (price: any, currency = 'EUR') => {
   let format = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
   })

   return parseFloat(price).toFixed(2) + ' ' + currency
}

export const MoneyFormat3 = (price: any, currency = 'EUR') => {
   let format = new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: currency
   })

   let currencyConvert = ''

   return (
      parseFloat(price)
         .toFixed(2)
         .replace(/\d(?=(\d{3})+\.)/g, '$&,') +
      ' ' +
      currencyConvert
   )
}
