import { Button, Card, CardBody, CardHeader, Chip } from '@nextui-org/react'
import { IconDownload, IconFilePlus } from '@tabler/icons-react'
import { message, Modal, TableColumnProps, UploadFile, UploadProps } from 'antd'
import PageHeader from 'components/page-header'
import { routes } from 'config/routes'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useFetch from 'hooks/use-fetch'
import { SUPPLIER_MANAGEMENT_LIST, SUPPLIER_MANAGEMENT_LIST_URL } from 'api/supplier-management'
import TableComponents from 'components/Table'
import SearchInput from 'components/Input'
import axios from 'axios'
import Dragger from 'antd/es/upload/Dragger'
import { RiFileExcel2Line } from 'react-icons/ri'
import { apiUrl } from 'api'

function MailList() {
   const navigate = useNavigate()
   const { t } = useTranslation()

   const [page, setPage] = React.useState(1) // [1,2,3,4,5]
   const [search, setSearch] = React.useState('') // [1,2,3,4,5
   const [excelModal, setExcelModal] = React.useState(false)

   const [suppliers, { data, loading, error }] = useFetch<any>()
   const [fileList, setFileList] = useState<UploadFile[]>([])

   React.useEffect(() => {
      //@ts-ignore
      suppliers(SUPPLIER_MANAGEMENT_LIST, {
         endPoint: 'mail-list?page=' + page + '&search=' + search
      })
   }, [page])

   const onFilter = () => {
      //@ts-ignore
      suppliers(SUPPLIER_MANAGEMENT_LIST, {
         endPoint: 'mail-list?page=' + page + '&search=' + search
      })
   }

   const pageHeader = {
      title: 'Mail Listesi',
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.myMail,
            name: 'Mail Listesi'
         }
      ]
   }

   //  <th>Firma Adı</th>
   //                              <th>İrtibatlı</th>
   //                              <th>İrtibat Email</th>
   //                              <th>İrtibat Telefon</th>
   //                              <th>Eklenen Tarih</th>
   //                              <th>Son Güncelleme</th>
   //                              <th>Durumu</th>
   //                              <th>İşlemler</th>

   const columns: TableColumnProps<any>[] = [
      {
         title: 'Mail',
         dataIndex: 'mail',
         key: 'mail',
         sorter: (a, b) => a.name.localeCompare(b.name),
         render: (text, record) => (
            <>
               <span>{record.mail}</span>
            </>
         )
      },

      {
         title: 'Eklenen Tarih',
         dataIndex: 'created_at',
         key: 'created_at',
         sorter: (a, b) => a.created_at.localeCompare(b.created_at),
         render: (text, record) => (
            <>
               <span>{moment(record.created_at).format('DD.MM.YYYY')}</span>
            </>
         )
      }
   ]

   const handleUpload = async () => {
      try {
         const formData = new FormData()
         fileList.forEach(file => {
            formData.append('files[]', file as any)
         })

         formData.append('type', 'mail')

         const response = await axios.post('/uploadFileTask', formData, {
            headers: {
               'Content-Type': 'multipart/form-data',
               Authorization: 'Bearer ' + localStorage.getItem('token') || ''
            }
         })

         Modal.success({
            title: 'Başarılı',
            content: 'İşlem tamamlanıca mail adresinize bilgilendirme yapılacaktır',
            onCancel: () => {
               setExcelModal(false)
            },
            onOk: () => {
               setExcelModal(false)
            },
            okText: 'Tamam',
            cancelText: 'Kapat'
         })
      } catch (error) {
         message.error('Dosya yüklenirken bir hata oluştu')
      }
   }

   const props: UploadProps = {
      onRemove: file => {
         const index = fileList.indexOf(file)
         const newFileList = fileList.slice()
         newFileList.splice(index, 1)
         setFileList(newFileList)
      },
      maxCount: 1,
      beforeUpload: file => {
         setFileList([...fileList, file])

         return false
      },
      fileList
   }

   return (
      <div>
         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            <div className="flex items-center gap-2">
               <Button startContent={<IconFilePlus />} onClick={() => setExcelModal(true)} color="primary">
                  Yeni Mail Listesi Yükle
               </Button>
               <Button
                  startContent={<IconDownload />}
                  onClick={() => {
                     window.open(apiUrl + '/api/mail-list/export?token=' + localStorage.getItem('token'))
                  }}
                  color="primary"
               >
                  Mail Listesi İndir
               </Button>
            </div>
         </PageHeader>
         <Modal footer={null} title="Excel İle Yükle" open={excelModal} closable onCancel={() => setExcelModal(false)}>
            <Dragger {...props} multiple={false} accept=".xlsx, .xls">
               <p className="ant-upload-drag-icon text-center w-full flex items-center justify-center">
                  <RiFileExcel2Line size={30} />
               </p>
               <p className="ant-upload-text">
                  Dosyayı sürükleyip bırakın ya da tıklayın Örnek dosyayı indirmek için <a href="#">buraya tıklayın</a>
               </p>
               <p className="ant-upload-hint">Dosyayı yüklemek için buraya sürükleyin</p>
            </Dragger>
            <Button onClick={handleUpload} color="primary" variant="solid" className="mt-5 w-20">
               Yükle
            </Button>
         </Modal>
         <div className="@container">
            <Card title="Filter" className="mb-5">
               <CardHeader>
                  <h3 className="text-lg font-semibold">{t('common.filterTable')}</h3>
               </CardHeader>
               <CardBody className="grid grid-cols-4 gap-2 items-center">
                  <SearchInput onChange={(e: any) => setSearch(e.target.value)} value={search} />

                  <Button color="primary" variant="solid" onClick={() => onFilter()} className="mt-5 w-20">
                     {t('common.filter')}
                  </Button>
               </CardBody>
            </Card>
            <Card title={'Mail Listesi'}>
               <TableComponents
                  columns={columns}
                  data={data?.data || []}
                  pagination={{
                     total: data?.data?.total,
                     current: page,
                     pageSize: 10,
                     onChange: (page: any) => {
                        setPage(page)
                     }
                  }}
                  loading={loading}
               />
            </Card>
         </div>
      </div>
   )
}

export default MailList
