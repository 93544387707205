import axios from "axios";
import { FetchProps } from "./interface";

export const SUPPLIER_MANAGEMENT_LIST_URL = (page:number,search?:any) =>  {
    if(typeof search!='undefined'){
        return '/suppliers?page='+page+'&search='+search;
    }
    else {
        return '/suppliers?page='+page;
    }
};


export const SUPPLIER_MANAGEMENT_STORE_URL = '/suppliers';

export const SUPPLIER_MANAGEMENT_UPDATE_URL = (customerId:any) =>  '/suppliers/'+customerId;

export const SUPPLIER_SHOW_URL = (customerId:any) =>  '/suppliers/'+customerId;

export const ADD_CONTACT_URL = (customerId:any) =>  '/suppliers/'+customerId+'/contact';

export const SUPPLIER_MANAGEMENT_LIST = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .get(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
};

export const SUPPLIER_MANAGEMENT_STORE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .post(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const SUPPLIER_MANAGEMENT_UPDATE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .put(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const SUPPLIER_MANAGEMENT_DELETE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .delete(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const SUPPLIER_MANAGEMENT_SHOW = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .get(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const ADD_CONTACT = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .post(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}