import { Form, Switch } from 'antd'
import React from 'react'
import { Input } from 'rizzui'

function CustomerForm() {
   return (
      <div>
         <Form.Item name="name" label="Şirket Adı" rules={[{ required: true, message: 'Bu Alan Zorunludur' }]}>
            <Input />
         </Form.Item>
         <Form.Item
            name="contact_name"
            label="İrtibatlı Ad Soyad"
            rules={[{ required: true, message: 'Bu Alan Zorunludur' }]}
         >
            <Input />
         </Form.Item>
         <Form.Item
            name="contact_email"
            label="E-posta"
            rules={[
               { required: true, message: 'Bu Alan Zorunludur' },
               { type: 'email', message: 'Geçerli bir e-posta girin' }
            ]}
         >
            <Input />
         </Form.Item>
         <Form.Item name="contact_phone" label="Telefon" rules={[{ required: true, message: 'Bu Alan Zorunludur' }]}>
            <Input />
         </Form.Item>
         <Form.Item
            name="url"
            label="Web Sitesi"
            rules={[
               { required: true, message: 'Bu Alan Zorunludur' },
               { type: 'url', message: 'Geçerli bir url girin' }
            ]}
         >
            <Input type="url" />
         </Form.Item>
         <Form.Item
            name="xml_url"
            label="XML Url"
            rules={[
               { required: true, message: 'Bu Alan Zorunludur' },
               { type: 'url', message: 'Geçerli bir url girin' }
            ]}
         >
            <Input type="url" />
         </Form.Item>
         <Form.Item name="discount" label="İndirim Oranı" rules={[{ required: true, message: 'Bu Alan Zorunludur' }]}>
            <Input type="number" />
         </Form.Item>
         <Form.Item
            valuePropName="checked"
            name="status"
            label="Durum"
            rules={[{ required: false, message: 'Bu Alan Zorunludur' }]}
         >
            <Switch />
         </Form.Item>
      </div>
   )
}

export default CustomerForm
