import { Button, Select, SelectItem } from "@nextui-org/react";
import { DatePicker, Form, Modal } from "antd";
import { OFFER_DASHBOARD_GRAPHIC, OFFER_MANAGEMENT_LIST_URL, OFFER_MANAGEMENT_STORE } from "api/offer-management";
import { setLoading } from "features/user/app";
import useFetch from "hooks/use-fetch";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import locale from 'antd/es/date-picker/locale/tr_TR';
import SimpleBar from "simplebar-react";
import { IconFilter } from "@tabler/icons-react";
import { Area, Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Badge } from "rizzui";
import WidgetCard from "components/cards/widget-card";
import { CustomTooltip } from "components/charts/custom-tooltip";
import { RoundedBottomBar } from "components/charts/rounded-bottombar";
import { RoundedTopBar } from "components/charts/rounded-topbar";
import { CustomYAxisTick } from "components/charts/custom-yaxis-tick";
const TotalAppointment = () => {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const [graphData, setGraphData] = useState<any>([])
  const [graphFilter, { data: graphFilterData, loading: graphFilterLoading, error: graphFilterError }] = useFetch<any>();

  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("weekly");
  const [startDate, setStartDate] = useState<Date>(new Date());

  const [form] = Form.useForm();

  const handleFilter = (values: any) => {
    //@ts-ignore
    graphFilter(OFFER_MANAGEMENT_STORE, ({
      endPoint: OFFER_DASHBOARD_GRAPHIC,
      body: values
    }))
  }

  const initGraphFilter = () => {
    //@ts-ignore
    graphFilter(OFFER_MANAGEMENT_STORE, ({
      endPoint: OFFER_DASHBOARD_GRAPHIC,
      body: {
        date: "0"
      }
    }))
    //@ts-ignore

  }



  useEffect(() => {
    initGraphFilter();
  }, []);

  useEffect(() => {
    dispatch(setLoading(graphFilterLoading as boolean))
  }, [graphFilterLoading])

  useEffect(() => {
    if (graphFilterData) {
      setGraphData(graphFilterData.data)
    }
  }, [graphFilterData])

  return (
    <>
      <Modal title="Filtrele" open={filterOpen} footer={null} onCancel={() => setFilterOpen(false)}>
        <Form form={form} layout="vertical" onFinish={handleFilter}>
          <Form.Item name="date">
            <Select
              label="Tarih"
              variant="bordered"
              size="sm" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
              <SelectItem key={0} value="weekly">Haftalık</SelectItem>
              <SelectItem key={1} value="monthly">Aylık</SelectItem>
              <SelectItem key={2} value="yearly">Yıllık</SelectItem>
              <SelectItem key={3} value="custom">Özel Tarih</SelectItem>
            </Select>

          </Form.Item>
          {selectedDate == "3" && (
            <Form.Item name="range" >
              <RangePicker className="w-full" locale={locale} />
            </Form.Item>
          )}
          <Button color="primary" variant="bordered" size="lg" type="submit">Filtrele</Button>
        </Form>
      </Modal>

      <WidgetCard
        title={'Talep/Sipariş Raporu'}
        description={
          <>
            <Badge renderAsDot className="me-0.5 bg-[#282ECA]" /> Bekleniyor
            <Badge
              renderAsDot
              className="me-0.5 ms-4 bg-[#B8C3E9] dark:bg-[#7c88b2]"
            />{' '}
            Siparişe Dönüştürüldü
          </>
        }
        descriptionClassName="text-gray-500 mt-1.5"
        action={
          <>
            <Button color="primary" startContent={<IconFilter />} size="md" onClick={() => setFilterOpen(true)}>
              Filtrele
            </Button>
          </>
        }
      >
        <SimpleBar>
          <div className="h-96 w-full pt-9">
            <ResponsiveContainer
              width="100%"
              height="100%"
              {...(true && { minWidth: '700px' })}
            >
              <ComposedChart
                data={graphData}
                barSize={true ? 20 : 24}
                className="[&_.recharts-tooltip-cursor]:fill-opacity-20 dark:[&_.recharts-tooltip-cursor]:fill-opacity-10 [&_.recharts-cartesian-axis-tick-value]:fill-gray-500 [&_.recharts-cartesian-axis.yAxis]:-translate-y-3 rtl:[&_.recharts-cartesian-axis.yAxis]:-translate-x-12 [&_.recharts-cartesian-grid-vertical]:opacity-0"
              >
                <defs>
                  <linearGradient id="salesReport" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor="#F0F1FF"
                      className=" [stop-opacity:0.1]"
                    />
                    <stop offset="95%" stopColor="#8200E9" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="8 10" strokeOpacity={0.435} />
                <XAxis dataKey="title" axisLine={false} tickLine={false} />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tick={<CustomYAxisTick prefix={'₺'} />}
                />
                <Tooltip
                  content={
                    <CustomTooltip className="[&_.chart-tooltip-item:last-child]:hidden" />
                  }
                />
                <Bar
                  label="Dönüştürüldü"
                  dataKey="siparis"
                  fill="#282ECA"
                  stackId="a"
                  shape={<RoundedBottomBar />}
                />
                <Bar
                  dataKey="bekleniyor"
                  stackId="a"
                  fill="#B8C3E9"
                  fillOpacity={0.9}
                  label="Sipariş"
                  shape={
                    <RoundedTopBar className="fill-[#B8C3E9] dark:fill-[#7c88b2]" />
                  }
                />
                <Area
                  label={true ? 'Dönüştürüldü' : undefined}
                  type="bump"
                  dataKey="bekleniyor"
                  stroke="#8200E9"
                  strokeWidth={2}
                  fillOpacity={1}
                  fill="url(#salesReport)"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </SimpleBar>
      </WidgetCard>
    </>
  )
}

export default TotalAppointment;