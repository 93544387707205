import React from 'react'
import { Card, CardHeader, CardBody, CardFooter, Divider, Link, Button, Chip } from '@nextui-org/react'
import { IconEdit } from '@tabler/icons-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { useNavigate } from 'react-router-dom'
import { routes } from 'config/routes'
import { Modal } from 'antd'
import { setContactId, setProduct } from 'features/user/offer'

export default function ContactCard({ item, handleEditClick, handleSeePast }: any) {
   const carts = useSelector((state: RootState) => state.basket.carts)
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const handleCreateOffer = () => {
      if (carts.length === 0) {
         dispatch(setContactId(item.id))
         setTimeout(() => {
            navigate(routes.offerManagement.add)
         }, 100)
      } else {
         Modal.confirm({
            title: 'Sepetinizde ürünler bulunmaktadır.',
            content: 'Ürünlerinizi teklife eklemek istiyor musunuz?',
            onOk() {
               dispatch(setContactId(item.id))
               dispatch(setProduct(carts))
               navigate(routes.offerManagement.add)
            },
            onCancel() {
               dispatch(setContactId(item.id))
               navigate(routes.offerManagement.add)
            }
         })
      }
   }

   return (
      <Card className="max-w-[400px]">
         <CardHeader className="flex gap-3">
            <Button color="primary" isIconOnly variant="ghost" onClick={handleEditClick}>
               <IconEdit />
            </Button>
            <div className="flex flex-col">
               <p className="text-md">{item.name}</p>
               <Chip color="primary" radius="sm">
                  {item.phone}
               </Chip>
            </div>
         </CardHeader>
         <Divider />
         <CardBody>
            {/* <small class="mb-0 text-muted">Atanan Kullanıcı: <span class="badge bg-{{ $contact->user != NULL ? 'success' : 'danger' }}">{{ $contact->user != NULL ? $contact->user->name : 'Atama Yapılmamış' }}</span></small> */}
            <small className="mb-0 text-muted">
               Atanan Kullanıcı:{' '}
               <Chip color={item.user != null ? 'success' : 'danger'}>
                  {item.user != null ? item.user.name : 'Atama Yapılmamış'}
               </Chip>
            </small>
         </CardBody>
         <Divider />
         <CardFooter className="gap-2">
            <Button color="primary" variant="ghost" onClick={handleCreateOffer}>
               Teklif Yarat
            </Button>
            <Button color="primary" variant="shadow" onClick={handleSeePast}>
               Geçmiş Teklifleri Gör
            </Button>
         </CardFooter>
      </Card>
   )
}
