import { Drawer, Form, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CustomerForm from './Form';
import { Button, Card, CardBody } from '@nextui-org/react';
import useFetch from 'hooks/use-fetch';
import { CUSTOMER_MANAGEMENT_STORE, CUSTOMER_MANAGEMENT_STORE_URL } from 'api/customer-management';

function AddCustomer() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [addCustomerMutation, { data, loading, error }] = useFetch<any>();

    const onFinish = (values: any) => {
        //@ts-ignore
        addCustomerMutation(CUSTOMER_MANAGEMENT_STORE, ({
            endPoint:CUSTOMER_MANAGEMENT_STORE_URL,
            body: {
                ...values,
                status:values.status ?? false
            },
        }))
    }

    useEffect(() => {
        if(data) {
            message.success("Müşteri başarıyla eklendi");
            navigate(-1);
        }
    }, [data])

    return (
        <div>
            <Drawer placement="bottom" height={"100%"} onClose={() => {
                setOpen(false);
                navigate(-1);
            }}
                extra={(

                    <div className='flex gap-2'>
                        <Button isLoading={loading} color="primary" onClick={() => {
                            form.submit();
                        }}>Kaydet</Button>
                        <Button color="danger" variant="bordered" onClick={() => {
                            setOpen(false);
                            navigate(-1);
                        }}>Vazgeç</Button>
                    </div>
                )}
                open={true} title="Yeni müşteri ekle">
                <Form layout='vertical' form={form} onFinish={onFinish}>
                    <div className='container mx-auto max-w-2xl'>
                        <Card radius="none">
                            <CardBody>
                                <CustomerForm />
                            </CardBody>
                        </Card>
                    </div>
                </Form>
            </Drawer>
        </div >
    )
}

export default AddCustomer
