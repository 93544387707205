import { routes } from 'config/routes'
import { setUser } from 'features/user/user'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function ClientLogin() {
   const dispatch = useDispatch()
   const navigate = useNavigate()
   useEffect(() => {
      if (!localStorage.getItem('token')) {

         window.location.href = routes.auth.login
      } else {
         dispatch(setUser(localStorage.getItem('user') as any))
         navigate(routes.index)

      }
   }, [])
   return null
}
