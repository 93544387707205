import { configureStore } from '@reduxjs/toolkit'
import userReducer from './features/user/user'
import appReducer from './features/user/app'
import offerReducer from './features/user/offer'
import basketReducer from './features/user/basket'
export const store = configureStore({
   reducer: {
      user: userReducer,
      app: appReducer,
      offer: offerReducer,
      basket: basketReducer
   }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
