import React from 'react'
import Lottie from 'react-lottie'
function Empty() {
    return (
        <div>
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: require('assets/animation/empty.json'),
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                }}
                height={200}
                width={200}
            />
        </div>
    )
}

export default Empty
