import React, { useEffect } from 'react'
import { Card, Drawer, Form, message, Popconfirm } from 'antd'
import { Button } from '@nextui-org/react'
import { IconTrash } from '@tabler/icons-react'
import { useNavigate, useParams } from 'react-router-dom'
import UserForm from './Form'
import { USER_STORE } from 'api/user'
import { useTranslation } from 'react-i18next'
import { u } from 'uploadthing/dist/types-e8f81bbc'
import useFetch from 'hooks/use-fetch'

export const CreateUser = () => {
   const { t } = useTranslation()
   const [open, setOpen] = React.useState(true)
   const navigate = useNavigate()

   const [form] = Form.useForm()

   const [addUserMutation, { data: addUserData, loading: addUserLoading }] = useFetch<any>()

   const onFinish = (values: any) => {
      //@ts-ignore
      addUserMutation(USER_STORE, {
         endPoint: '/users',
         body: values
      })
   }

   useEffect(() => {
      if (addUserData) {
         message.success({
            content: t('messages.add_success')
         })
         setOpen(false)
         navigate(-1)
      }
   }, [addUserData])

   return (
      <>
         <Drawer
            extra={
               <div className="flex gap-2">
                  <Button
                     onClick={() => {
                        setOpen(false)
                        navigate(-1)
                     }}
                     size="lg"
                     variant="faded"
                     className="w-20"
                     color="danger"
                  >
                     {t('buttons.cancel')}
                  </Button>

                  <Button
                     onPress={() => form.submit()}
                     size="lg"
                     variant="shadow"
                     className="w-20"
                     color="primary"
                     isLoading={false}
                  >
                     {t('buttons.save')}
                  </Button>
               </div>
            }
            footer={null}
            closable
            onClose={() => {
               setOpen(false)
               navigate(-1)
            }}
            placement="bottom"
            height="100%"
            open={open}
            title={t('buttons.add')}
            classNames={{
               body: '!bg-white'
            }}
         >
            <Card>
               <Form form={form} layout="vertical" onFinish={onFinish}>
                  <UserForm form={form} initialValues={{}} onFinish={onFinish} />
               </Form>
            </Card>
         </Drawer>
      </>
   )
}

export default CreateUser
