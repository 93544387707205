import React from 'react'
import { Tabs, Tab, Card, CardBody, CardHeader } from '@nextui-org/react'
import OfferList from '../../offers-management/List'
import RequestList from '../../requests/List'

interface Props {
   id: string | number
}

const CustomerProfile = (props: Props) => {
   let tabs = [
      {
         id: '1',
         label: 'Giden Teklifler',
         content: <OfferList user_id={props.id} />
      },
      {
         id: '2',
         label: 'Bekleyen Talepler',
         content: <RequestList user_id={props.id} />
      }
   ]

   return (
      <div className="flex w-full flex-col">
         <Tabs aria-label="Dynamic tabs" items={tabs}>
            {item => (
               <Tab key={item.id} title={item.label}>
                  <Card>
                     <CardBody>{item.content}</CardBody>
                  </Card>
               </Tab>
            )}
         </Tabs>
      </div>
   )
}

export default CustomerProfile
