import React, { useEffect } from 'react'
import { Alert } from 'rizzui'
import Lottie from 'react-lottie'
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react'
function SuccessModal({ open, setOpen }: any) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: require('assets/animation/success.json'),
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        if (open) {
            onOpen()
        }
    }, [open])


    return (
        <div>
            <Modal
                onBlur={() => setOpen(false)}
                title="Başarılı"
                isOpen={isOpen}
                backdrop='blur'
                onClose={onClose}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                Başarılı
                            </ModalHeader>
                            <ModalBody>

                                <Alert
                                    variant="flat"
                                    color='success'
                                >
                                    Teklif Siparişe Dönüştürüldü
                                </Alert>
                                <Lottie
                                    options={defaultOptions}
                                    height={200}
                                    width={200}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Kapat
                                </Button>

                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
}

export default SuccessModal
