'use client'
import { RefObject, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import cn from 'utils/class-names'
import { Title, Text } from 'components/ui/text'
import { Avatar } from 'components/ui/avatar'
import { Badge } from 'components/ui/badge'
import { routes } from 'config/routes'
import { useMedia } from 'hooks/use-media'
import SimpleBar from 'components/ui/simplebar'
import { PiCheck } from 'react-icons/pi'
import { Link } from 'react-router-dom'
import { Button, Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react'
import { IconBell, IconChecklist, IconError404Off, IconInfoCircle } from '@tabler/icons-react'
import useFetch from 'hooks/use-fetch'
import { Loader } from 'rizzui'
import { USER_SHOW } from 'api/user'

dayjs.extend(relativeTime)

const data = [
   {
      id: 1,
      message: `It is nice to be chatting with you. Omnis,
        quidem non. Sint inventore quasi temporibus architecto eaque,
        natus aspernatur minus?`,
      avatar: ['https://isomorphic-furyroad.s3.amazonaws.com/public/avatars-blur/avatar-01.webp'],
      name: 'Wade Warren',
      unRead: true,
      sendTime: '2023-06-01T09:35:31.820Z'
   }
]

function MessagesList({ setIsOpen, data }: { setIsOpen: React.Dispatch<React.SetStateAction<boolean>>; data?: any }) {
   return (
      <div className="w-[320px] text-left rtl:text-right sm:w-[360px] 2xl:w-[420px]">
         <div className="mb-2 flex items-center justify-between ps-6">
            <Title as="h5">Bildirimler</Title>
            <Link to={routes.home} onClick={() => setIsOpen(false)} className="hover:underline">
               Tümünü Gör
            </Link>
         </div>
         <SimpleBar className="max-h-[406px]">
            <div className="grid grid-cols-1 ps-4">
               {data.map((item: any) => (
                  <div
                     key={item.name + item.id}
                     className="group grid cursor-pointer grid-cols-[auto_minmax(0,1fr)] gap-2.5 rounded-md px-2 py-2.5 pe-3 transition-colors hover:bg-gray-100 dark:hover:bg-gray-50"
                  >
                     <div className={cn('relative', item.avatar.length > 1 && 'me-1')}>
                        {/* <Avatar
                           src={item.avatar[0]}
                           name={item.name}
                           className={cn(item.avatar.length > 1 && 'relative -end-1 -top-0.5 !h-9 !w-9')}
                        /> */}
                        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 border-gray-0 dark:border-gray-100">
                           {item.type == 'INFO' && <IconInfoCircle size={20} className="text-primary" />}
                           {item.type == 'WARNING' && <IconError404Off size={20} className="text-primary" />}
                           {item.type == 'SUCCESS' && <IconChecklist size={20} className="text-primary" />}
                        </div>
                     </div>
                     <div className="grid grid-cols-[minmax(0,1fr)_auto] items-center">
                        <div className="w-full">
                           <Title as="h6" className="mb-0.5 text-sm font-semibold">
                              {item.title}
                           </Title>
                           <p className="text-xs text-gray-500">{item.description}</p>

                           <div className="flex">
                              <Text className="w-10/12 truncate pe-7 text-xs text-gray-500">{item.message}</Text>
                              <span className="ms-auto whitespace-nowrap pe-8 text-xs text-gray-500">
                                 {dayjs(item.sendTime).fromNow(true)}
                              </span>
                           </div>
                        </div>
                        <div className="ms-auto flex-shrink-0">
                           {item.unRead ? (
                              <Badge renderAsDot size="lg" color="primary" className="scale-90" />
                           ) : (
                              <span className="inline-block rounded-full bg-gray-100 p-0.5 dark:bg-gray-50">
                                 <PiCheck className="h-auto w-[9px]" />
                              </span>
                           )}
                        </div>
                     </div>
                  </div>
               ))}
            </div>
         </SimpleBar>
      </div>
   )
}

export default function MessagesDropdown({ children }: { children?: JSX.Element & { ref?: RefObject<any> } }) {
   const isMobile = useMedia('(max-width: 480px)', false)
   const [isOpen, setIsOpen] = useState(false)

   const [getNotificationsQuery, { data, error, loading }] = useFetch<any>()

   useEffect(() => {
      if (isOpen) {
         //@ts-ignore
         getNotificationsQuery(USER_SHOW, {
            endPoint: 'auth/notifications'
         })
      }
   }, [isOpen])

   return (
      <div className="flex flex-col gap-2">
         <Popover isOpen={isOpen} onOpenChange={open => setIsOpen(open)}>
            <PopoverTrigger>
               <Button color="primary" startContent={<IconBell />}>
                  Bildirimler
               </Button>
            </PopoverTrigger>
            <PopoverContent>
               {loading && <Loader />}
               {data && (
                  <MessagesList
                     data={data.map((item: any) => ({
                        id: item.id,
                        title: item.title,
                        description: item.description,
                        avatar: ['https://isomorphic-furyroad.s3.amazonaws.com/public/avatars-blur/avatar-01.webp'],
                        name: item.title,
                        unRead: item.is_read,
                        sendTime: item.created_at,
                        type: item.type
                     }))}
                     setIsOpen={setIsOpen}
                  />
               )}
            </PopoverContent>
         </Popover>
      </div>
   )
}
