import { Card, Drawer, Form, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import CustomerForm from './Form';
import { Button } from '@nextui-org/react';
import useFetch from 'hooks/use-fetch';
import { CUSTOMER_MANAGEMENT_SHOW, CUSTOMER_MANAGEMENT_STORE, CUSTOMER_MANAGEMENT_STORE_URL, CUSTOMER_MANAGEMENT_UPDATE, CUSTOMER_MANAGEMENT_UPDATE_URL, CUSTOMER_SHOW_URL } from 'api/customer-management';

function EditCustomer() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const { id } = useParams<any>();
    const [editCustomerMutation, { data, loading, error }] = useFetch<any>();
    const [customer, { data: customerData, loading: customerLoading, error: customerError }] = useFetch<any>();

    const onFinish = (values: any) => {
        //@ts-ignore
        editCustomerMutation(CUSTOMER_MANAGEMENT_UPDATE, ({
            endPoint: CUSTOMER_MANAGEMENT_UPDATE_URL(id),
            body: {
                ...values,
                status: values.status ?? false
            },
        }))
    }

    useEffect(() => {
        if (id) {
            //@ts-ignore
            customer(CUSTOMER_MANAGEMENT_SHOW, ({
                endPoint: CUSTOMER_SHOW_URL(id),
            }))
        }
    }, [id])

    useEffect(() => {
        if (data) {
            message.success("Müşteri başarıyla güncellendi");
            navigate(-1);
        }
    }, [data])

    return (
        <div>
            <Drawer placement="bottom" height={"100%"} onClose={() => {
                setOpen(false);
                navigate(-1);
            }}
                extra={(

                    <div className='flex gap-2'>
                        <Button isLoading={loading} color="primary" onClick={() => {
                            form.submit();
                        }}>Kaydet</Button>
                        <Button color="danger" variant="bordered" onClick={() => {
                            setOpen(false);
                            navigate(-1);
                        }}>Vazgeç</Button>
                    </div>
                )}
                open={true} title="Müşteri Düzenle">

                <div className='container mx-auto max-w-2xl'>
                    <Card loading={customerLoading}>
                        <Form layout='vertical' form={form} onFinish={onFinish} initialValues={customerData?.data}>
                            <CustomerForm />
                        </Form>

                    </Card>
                </div>
            </Drawer>
        </div >
    )
}

export default EditCustomer
