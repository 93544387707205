import React, { useEffect } from 'react'
import type { SelectProps } from 'antd'
import { Select, Spin, Typography } from 'antd'
import useFetch from 'hooks/use-fetch'
import { USER_LIST } from 'api/user'
import { useDebounce } from 'use-debounce'
import { useTranslation } from 'react-i18next'

interface Props {
   onChange?: any
   value?: string
   getAll?: boolean
}

const UserFilter = (props: Props) => {
   const { t } = useTranslation()

   const [rows, setRows] = React.useState<any[]>([])

   const [search, setSearch] = React.useState<string>('')

   const [searchDebounced] = useDebounce(search, 600)

   const [fetchUserQuery, { data: users, loading, error }] = useFetch<any>()

   const handleChange = (value: string[]) => {
      props.onChange(value)
   }

   const onSearch = (value: string) => {
      //@ts-ignore
      fetchUserQuery(USER_LIST, {
         endPoint: `users-search?search=${value}&all=${props.getAll ? 1 : 0}`
      })
   }

   useEffect(() => {
      if (props.getAll) {
         onSearch('')
      }
   }, [])

   useEffect(() => {
      if (searchDebounced) {
         onSearch(searchDebounced)
      }
   }, [searchDebounced])

   let notFoundContent: any = "Kullanıcı Ara"
   if (loading || search !== searchDebounced) {
      notFoundContent = <Spin size="small" style={{ margin: 'auto', width: '100%' }} />
   } else if (searchDebounced) {
      notFoundContent = <Typography.Text>Kullanıcı Bulunamadı</Typography.Text>
   }

   let options = users?.result?.map((user: any) => {
      return {
         label: user.name,
         value: user.id
      }
   })

   const handleBlur = (event: any) => {
      setSearch('')
   }


   return (
      <>
         {options && (
            <Select
               filterOption={false}
               onSearch={setSearch}
               loading={loading}
               notFoundContent={notFoundContent}
               options={options}
               onBlur={handleBlur}
               onChange={handleChange}
               showSearch
               labelInValue
               allowClear
               autoClearSearchValue
               defaultValue={props.value as any}
            />
         )}
      </>
   )
}

export default UserFilter
