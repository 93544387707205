import { Button } from '@nextui-org/react'
import { Card, Drawer, Form, Select, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Step2 from './Steps/Step2'
import useFetch from 'hooks/use-fetch'
import { IconChevronDown } from '@tabler/icons-react'
import { Alert, Input } from 'rizzui'
import { CUSTOMER_MANAGEMENT_LIST } from 'api/customer-management'
import { useDispatch, useSelector } from 'react-redux'
import { setContactDetail, setContactId, setOfferNumber, setTermTime } from 'features/user/offer'
import { RootState } from 'store'
import Step3 from './Steps/Step3'
import {
   OFFER_MANAGEMENT_STORE,
   OFFER_MANAGEMENT_STORE_URL,
   OFFER_MANAGEMENT_UPDATE,
   OFFER_MANAGEMENT_UPDATE_URL
} from 'api/offer-management'

function Edit() {
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const { id } = useParams<any>()
   const offer = useSelector((state: RootState) => state.offer)
   const [step1DrawerOpen, setStep1DrawerOpen] = useState(true)
   const [step2DrawerOpen, setStep2DrawerOpen] = useState(false)
   const [step3DrawerOpen, setStep3DrawerOpen] = useState(false)
   const [contactsQuery, { data: contactsData, loading: contactsLoading, error: contactsError }] = useFetch<any>()
   const [offerEditMutation, { data: offerEditData, loading: offerEditLoading, error: offerEditError }] =
      useFetch<any>()

   const windowHeight = window.innerHeight

   const step2DrawerHeight = windowHeight - 0

   const step3DrawerHeight = step2DrawerHeight - 0

   const [form] = Form.useForm()

   const handleStep1 = (values: any) => {
      dispatch(setOfferNumber(values.offer_no))
      dispatch(setContactId(values.contact_id))
      dispatch(setTermTime(values.term_time))
      setStep1DrawerOpen(false)
      setStep2DrawerOpen(true)
   }

   const handleStep2 = (products: any) => {
      console.log(products)
      setStep2DrawerOpen(false)
      setStep3DrawerOpen(true)
   }

   const saveOffer = () => {
      //@ts-ignore
      offerEditMutation(OFFER_MANAGEMENT_UPDATE, {
         endPoint: OFFER_MANAGEMENT_UPDATE_URL(id),
         body: {
            ...offer,
            products: offer.products
         }
      })
   }

   useEffect(() => {
      if (offerEditData) {
         message.success('Teklif başarıyla oluşturuldu!')
         navigate(-1)
      }
   }, [offerEditData])

   useEffect(() => {
      //@ts-ignore
      contactsQuery(CUSTOMER_MANAGEMENT_LIST, {
         endPoint: '/customer-with-contacts'
      })
   }, [])

   return (
      <div>
         <Drawer
            placement="bottom"
            height={windowHeight}
            onClose={() => {
               navigate(-1)
            }}
            open={step1DrawerOpen}
            title="Teklifi Düzenle - Adım 1"
            extra={
               <Button type="button" onClick={() => form.submit()} color="primary">
                  İleri
               </Button>
            }
         >
            <div className="max-w-3xl container mx-auto">
               <Form
                  onFinish={handleStep1}
                  initialValues={{
                     offer_no: offer.offerNumber,
                     contact_id: offer.contactId,
                     term_time: offer?.term_time ?? 0
                  }}
                  layout="vertical"
                  form={form}
               >
                  <Card title="Genel Bilgiler" bordered={false}>
                     <div className="">
                        <Form.Item
                           label="Müşteri seçiniz"
                           name="contact_id"
                           rules={[{ required: true, message: 'Lütfen bir müşteri seçiniz!' }]}
                        >
                           <Select
                              onChange={(value: any) => {
                                 const find = contactsData?.data?.find((item: any) =>
                                    item.contacts.some((contact: any) => contact.id === value)
                                 )
                                 if (find) {
                                    const contactDetail = find.contacts.find((contact: any) => contact.id === value)
                                    dispatch(setContactDetail(contactDetail))
                                 }
                              }}
                              options={contactsData?.data
                                 .filter((item: any) => item.contacts.length > 0)
                                 .map((item: any) => {
                                    return {
                                       label: item.company_name,
                                       options: item.contacts.map((contact: any) => {
                                          return {
                                             label: contact.name,
                                             value: contact.id
                                          }
                                       })
                                    }
                                 })}
                              filterOption={(input: any, option: any) =>
                                 option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                 option?.options?.some(
                                    (item: any) => item.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                 )
                              }
                              showSearch
                              placeholder="müşteri seçiniz"
                              suffixIcon={<IconChevronDown />}
                           ></Select>
                        </Form.Item>
                        <Form.Item
                           label="Teklif No"
                           name="offer_no"
                           rules={[{ required: true, message: 'Lütfen bir teklif no giriniz!' }]}
                        >
                           <Input placeholder="Teklif no giriniz" />
                        </Form.Item>
                        <Alert color="danger">Lütfen vade sürenizi gün olarak giriniz örn: 60</Alert>
                        <Form.Item
                           label="Ödeme Vadesi"
                           name="term_time"
                           rules={[{ required: true, message: 'Vade Süresi!' }]}
                        >
                           <Input value={offer.term_time} placeholder="Vade Süresi" />
                        </Form.Item>
                     </div>
                  </Card>
               </Form>
            </div>
         </Drawer>
         <Step2
            currentProducts={offer.products}
            drawerProps={{
               onClose: () => {
                  setStep2DrawerOpen(false)
               },
               open: step2DrawerOpen
            }}
            handleNext={handleStep2}
            handlePrev={() => {
               setStep2DrawerOpen(false)
               setStep1DrawerOpen(true)
            }}
         />
         <Drawer
            placement="bottom"
            height={step3DrawerHeight}
            onClose={() => {
               setStep3DrawerOpen(false)
            }}
            open={step3DrawerOpen}
            title="Teklifi Önizle - Adım 3"
            extra={
               <div className="flex gap-2">
                  <Button
                     color="primary"
                     onClick={() => {
                        setStep2DrawerOpen(true)
                        setStep3DrawerOpen(false)
                     }}
                  >
                     Geri
                  </Button>
                  <Button
                     isLoading={offerEditLoading}
                     color="primary"
                     onClick={() => {
                        saveOffer()
                     }}
                  >
                     Kaydet
                  </Button>
               </div>
            }
         >
            <Step3 />
         </Drawer>
      </div>
   )
}

export default Edit
