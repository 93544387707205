import { routes } from 'config/routes'
import { List, TableColumnProps, Modal, Drawer, message } from 'antd'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/page-header'
import { Button, CardBody, CardHeader, Chip, Card } from '@nextui-org/react'
import { Link, useNavigate } from 'react-router-dom'
import useFetch from 'hooks/use-fetch'
import { useEffect, useState } from 'react'

import moment from 'moment'
import {
   OFFER_MANAGEMENT_LIST,
   OFFER_MANAGEMENT_LIST_URL,
   OFFER_MANAGEMENT_SHOW,
   OFFER_MANAGEMENT_SHOW_URL,
   OFFER_MANAGEMENT_STORE
} from 'api/offer-management'
import SearchInput from 'components/Input'
import TableComponents from 'components/Table'
import { IconEdit, IconFileInvoice, IconFilePlus, IconPdf, IconReload } from '@tabler/icons-react'
import { useDispatch, useSelector } from 'react-redux'
import { setContactId, setOfferNumber, setProduct } from 'features/user/offer'
import { RootState } from 'store'
import { setLoading } from 'features/user/app'
import Preview from '../offers-management/Preview'
import ActionButton from '../offers-management/ActionButton'
import OrderStatusModalButton from './OrderStatusModalButton'
import { getOrderStatusWithClass, getOrderWithAnimation, orderStatusText } from 'utils/get-order-status'
import cn from 'utils/class-names'
import Lottie from 'react-lottie'
import { MoneyFormatTR } from 'hooks/currency'

export default function OrdersPage() {
   const { t } = useTranslation()
   const pageHeader = {
      title: 'Siparişler',
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.dashboard,
            name: 'Siparişler'
         }
      ]
   }

   const app = useSelector((state: RootState) => state.app)
   const navigate = useNavigate()
   const dispatch = useDispatch()
   const [offerManagementQuery, { data, loading }] = useFetch<any>()
   const [getOfferLastIdQuery, { data: lastIdData, loading: lastIdLoading }] = useFetch<any>()
   const [getOffer, { data: offerData, loading: offerLoading }] = useFetch<any>()
   const [getOffersFromOfferGroupQuery, { data: offersFromOfferGroupData, loading: offersFromOfferGroupLoading }] =
      useFetch<any>()
   const [convertOfferToOrderMutation, { data: convertOfferToOrderData, loading: convertOfferToOrderLoading }] =
      useFetch<any>()

   const [page, setPage] = useState<number>(1)
   const [search, setSearch] = useState<string>('')
   const [offerListModal, setOfferListModal] = useState<boolean>(false)
   const [previewItem, setPreviewItem] = useState<any>({})
   const [previewDrawer, setPreviewDrawer] = useState<boolean>(false)
   const [contacts, setContacts] = useState<any[]>([])

   const onFilter = () => {
      //@ts-ignore
      offerManagementQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: OFFER_MANAGEMENT_LIST_URL(page, search) + '&is_ordered=1'
      })
   }

   const columns: TableColumnProps<any>[] = [
      {
         title: 'Teklif Grup No',
         dataIndex: 'no',
         key: 'no',
         render: (no: any, record) => {
            return <div className="flex items-center gap-3">{record.offer_group.id}</div>
         }
      },
      {
         title: 'Müşteri seçiniz (Firma)',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {record.offer.contact.name} ({record.offer.contact.customer.company_name})
                  {/* {record?.offer?.contact.name} */}
               </div>
            )
         }
      },
      {
         title: 'Durumu',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const color = record.is_ordered === 1 ? 'text-green-500' : 'text-red-500'
            const background = record.is_ordered === 1 ? 'bg-green-500' : 'bg-red-100'
            return (
               <div
                  className={cn(
                     getOrderStatusWithClass(record.offer?.offer_status),
                     'flex justify-between items-center gap-2'
                  )}
               >
                  <Lottie
                     options={{
                        loop: true,
                        autoplay: true,
                        animationData: getOrderWithAnimation(record.offer?.offer_status)
                     }}
                     height={30}
                     width={30}
                  />
                  {orderStatusText[record.offer?.offer_status]}
               </div>
            )
         }
      },
      {
         title: 'Sipariş Tarihi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const color = record.offer.is_ordered === 1 ? 'text-green-500' : 'text-red-500'
            const background = record.offer.is_ordered === 1 ? 'bg-green-500' : 'bg-red-100'
            return (
               <div className="flex-col items-center gap-3">
                  {/* <Chip color={record.status === 1 ? 'primary' : 'danger'}>{record.status === 1 ? 'Aktif' : 'Pasif'}</Chip> */}
                  <div
                     className={`flex items-center gap-2 rounded-md px-2 py-1 ${background} ${color}`}
                     style={{
                        background: record.offer.is_ordered === 1 ? '#e6ffed' : '#fff5f5'
                     }}
                  >
                     {record.offer.is_ordered === 1 && <div>{moment(record.offer_group.ordered_at).format('LL')}</div>}
                  </div>
               </div>
            )
         }
      },
      {
         title: 'Eklenme Tarihi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.created_at).format('LL')} */}
                  {moment(record?.offers?.created_at).format('LL')}
               </div>
            )
         }
      },

      {
         title: t('common.actions'),
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  <Button
                     onClick={() => {
                        handleGetOffersFromOfferGroup(record.offer_group.id)
                     }}
                     color="primary"
                     startContent={<IconPdf />}
                     variant="shadow"
                  >
                     Önizle
                  </Button>

                  {/* <OrderStatusModalButton
                     offer={record.offer?.find((item: any) => item.is_ordered === 1)}
                     offer_id={record.offer_id}
                  /> */}
               </div>
            )
         }
      },
      {
         title: 'Toplam Ciro',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const products = record.offer?.products
            let total = 0
            products?.map((item: any) => {
               const parseProduct = JSON.parse(item.product_json)
               total += parseProduct.stock * parseProduct?.price
            })
            return <div className="flex items-center gap-3">{MoneyFormatTR(record.offer?.total)}</div>
         }
      },
      {
         title: 'Toplam Kâr',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return <div className="flex items-center gap-3">{MoneyFormatTR(record.offer?.profit)}</div>
         }
      }
   ]

   useEffect(() => {
      //@ts-ignore
      offerManagementQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: OFFER_MANAGEMENT_LIST_URL(page, '') + '&offerType=1&is_ordered=1'
      })
   }, [page])

   const handleGetlastId = () => {
      //@ts-ignore
      getOfferLastIdQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: '/offers/getLastOfferId'
      })
   }

   const handleGetOffer = (id: number) => {
      //@ts-ignore
      getOffer(OFFER_MANAGEMENT_SHOW, {
         endPoint: OFFER_MANAGEMENT_SHOW_URL(id)
      })
   }

   const handlePreviewOffer = (item: any) => {
      setPreviewItem(item)
      setPreviewDrawer(true)
   }

   const handleGetOffersFromOfferGroup = (id: number) => {
      //@ts-ignore
      getOffersFromOfferGroupQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: '/offers/' + id + '/edit'
      })
   }

   useEffect(() => {
      if (lastIdData) {
         dispatch(setOfferNumber(lastIdData.data))
         navigate(routes.offerManagement.add)
      }
   }, [lastIdData])

   useEffect(() => {
      dispatch(
         setLoading(
            (offerLoading as boolean) ||
               (offersFromOfferGroupLoading as boolean) ||
               (convertOfferToOrderLoading as boolean)
         )
      )
   }, [offerLoading, offersFromOfferGroupLoading, convertOfferToOrderLoading])

   useEffect(() => {
      if (offerData) {
         const { data } = offerData
         dispatch(setOfferNumber(data?.no))
         dispatch(setContactId(data?.customer_contact_id))

         const products = data?.products.map((product: any, index: number) => {
            const description = JSON.parse(product.product_json)
            return {
               id: product.product_id,
               name: description.name,
               price: description.price,
               stock: product.product_qty,
               product_qty: description.stock,
               delivery_date: product.product_delivery_date,
               commission: product.product_markup,
               commission_type: product.product_markup_type,
               description: product.product_description,
               services: product.services.map((service: any) => {
                  return {
                     ...service.service,
                     commission: service.service_markup,
                     commission_type: service.service_markup_type,
                     description: service.service_description
                  }
               })
            }
         })
         // console.log(products)
         dispatch(setProduct(products))
         navigate(routes.offerManagement.view(data?.offer_group_id))
      }
   }, [offerData])

   useEffect(() => {
      if (offersFromOfferGroupData) {
         setOfferListModal(true)
      }
   }, [offersFromOfferGroupData])

   useEffect(() => {
      if (convertOfferToOrderData) {
         message.success('Siparişe dönüştürüldü!')
         setOfferListModal(false)
         onFilter()
      }
   }, [convertOfferToOrderData])

   return (
      <>
         <Drawer
            title="Teklif Önizleme"
            placement="bottom"
            height={window.innerHeight}
            onClose={() => setPreviewDrawer(false)}
            open={previewDrawer}
         >
            {previewItem && <Preview item={previewItem} />}
         </Drawer>
         <Modal
            footer={null}
            title={
               <div className="flex items-center gap-3">
                  <IconPdf />
                  Teklifleri Görüntüle
               </div>
            }
            width={window.innerWidth / 1.5}
            onCancel={() => setOfferListModal(false)}
            open={offerListModal}
         >
            {offersFromOfferGroupData && (
               <div className="p-5">
                  {offersFromOfferGroupData?.data?.offers?.map((item: any, index: number) => {
                     return (
                        <div
                           key={item.id}
                           className="pb-5 flex gap-2 items-center justify-between w-full cursor-pointer border-b border-gray-100"
                        >
                           <div className="flex flex-col space-y-2">
                              <span className="font-bold">
                                 {offersFromOfferGroupData?.data?.no + '/' + (index + 1)}
                              </span>
                              <span>Revize Tarihi : {moment(item.updated_at).format('LL')}</span>
                              <span>
                                 {item.contact.name} ({item.contact.customer.company_name})
                              </span>
                           </div>
                           <div className="gap-2 flex ">
                              <Button
                                 onClick={() => {
                                    handlePreviewOffer(item)
                                 }}
                                 color="primary"
                                 startContent={<IconPdf />}
                                 variant="shadow"
                              >
                                 Önizle
                              </Button>
                              <Button
                                 onClick={() => {
                                    handleGetOffer(item.id)
                                 }}
                                 color="secondary"
                                 startContent={<IconEdit />}
                                 variant="shadow"
                              >
                                 Düzenle
                              </Button>
                              {offersFromOfferGroupData?.data?.is_ordered === 0 && (
                                 <Button
                                    onClick={() => {
                                       Modal.confirm({
                                          title:
                                             'Siparişe Dönüştür #' + offersFromOfferGroupData?.data?.no + '/' + item.id,
                                          content:
                                             'Bu Teklif Siparişe dönüştürülüp stoktan düşecektir. Devam etmek istiyor musunuz?',
                                          okText: 'Onayla ve dönüştür',
                                          cancelText: 'İptal',
                                          onOk: () => {
                                             //@ts-ignore
                                             convertOfferToOrderMutation(OFFER_MANAGEMENT_STORE, {
                                                endPoint: '/offers/convert-order',
                                                body: {
                                                   offer_id: item.id
                                                }
                                             })
                                          }
                                       })
                                    }}
                                    color="success"
                                    startContent={<IconFileInvoice />}
                                    variant="bordered"
                                 >
                                    Siparişe Dönüştür
                                 </Button>
                              )}
                           </div>
                        </div>
                     )
                  })}
               </div>
            )}
         </Modal>
         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}></PageHeader>

         <div className="@container">
            <Card title="Filter" className="mb-5">
               <CardHeader>
                  <h3 className="text-lg font-semibold">{t('common.filterTable')}</h3>
               </CardHeader>
               <CardBody className="grid grid-cols-4 gap-2 items-center">
                  <SearchInput onChange={(e: any) => setSearch(e.target.value)} value={search} />

                  <Button color="primary" variant="solid" onClick={() => onFilter()} className="mt-5 w-20">
                     {t('common.filter')}
                  </Button>
               </CardBody>
            </Card>
            <Card title={'Siparişler'}>
               <TableComponents
                  columns={columns}
                  data={data?.data?.data || []}
                  pagination={{
                     total: data?.data?.total,
                     current: page,
                     pageSize: 10,
                     onChange: (page: any) => {
                        setPage(page)
                     }
                  }}
                  loading={loading}
               />
            </Card>
         </div>
      </>
   )
}
