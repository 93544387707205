import { Drawer } from "antd";

import React from "react";
import { Route, Routes } from "react-router-dom";
import List from "./List";
import ViewOffer from "./View";
import Create from "./Create";
import Edit from "./Edit";


const OffersManagementPage = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<List />} />
                <Route path="/new" element={<Create />} />
                <Route path="/:id/view" element={<Edit />} />
            </Routes>
        </div>
    );
};

export default OffersManagementPage;
