import { Drawer } from "antd";

import React from "react";
import { Route, Routes } from "react-router-dom";
import List from "./List";
import ViewOffer from "./View";
import Create from "./Create";
import ListMail from "./ListMail";


const RequestsPage = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<List />} />
                <Route path="/mail" element={<ListMail />} />
                <Route path="/new" element={<Create />} />
            </Routes>
        </div>
    );
};

export default RequestsPage;
