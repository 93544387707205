export const offerAdminStatus = (row: any) => {
   let status = row

   let object: any = {}

   if (!status.is_sent_offer_mail && status.is_assigned) {
      object = {
         status: 'Atandı, Teklif gönderilmedi',
         color: 'secondary'
      }
   } else if (status.is_sent_offer_mail && status.is_assigned) {
      object = {
         status: 'Atandı, Teklif gönderildi',
         color: 'success'
      }
   } else if (!status.is_sent_offer_mail && !status.is_assigned) {
      object = {
         status: 'Kişi ataması bekleniyor',
         color: 'danger'
      }
   }

   return object
}
